import React from "react";
import Minus from "../../static/Product/minus.svg";
import Plus from "../../static/Product/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useState, useRef } from "react";
import { addTOCart } from "../../redux/cartSlice";
import { getLocalData } from "../../utils/localStorage";
import { addToCart, getCartItems } from "../../services/CartService";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/numberFormater";
function ProductListItem(props) {
  const { product, quantity, outletId, stockQuantity } = props;
  const { t } = useTranslation();
  const [pQuantity, setPQuantity] = useState(quantity);
  var throttle = require("lodash.throttle");
  const throttled = useRef(
    throttle((pQuantity) => updateCart(pQuantity), 1000)
  );

  const allAssociatedProducts = useSelector((state) => state.cart.cartItems);

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);

  //currency
  const currency = useSelector((state) => state.pharmacy.currencySymbol);

  // useEffect(() => throttled.current(pQuantity), [pQuantity])

  const updateCart = (tempQtyy) => {
    let arr = [];
    allAssociatedProducts.forEach((item) => {
      if (
        item.productId === parseInt(product.id) &&
        item.quantity !== tempQtyy &&
        item.shoppingCartTypeId === 1
      ) {
        let tempitem = {
          id: item.id,
          customerId: item.customerId,
          productId: item.productId,
          quantity: tempQtyy,
          shoppingCartTypeId: item.shoppingCartTypeId,
          createdOnUtc: item.createdOnUtc,
          product: item.product,
        };

        arr.push(tempitem);
      } else {
        arr.push(item);
      }
    });

    let tempArrForAddTOCart = [];

    arr.forEach((item) => {
      let tempItem = {
        productId: item.productId,
        quantity: item.quantity,
        shoppingCartTypeId: item.shoppingCartTypeId,
      };

      tempArrForAddTOCart.push(tempItem);
    });

    addToCart(
      userData.id,
      userData.outletId,
      getLocalData("user-info")?.userToken,
      tempArrForAddTOCart
    )
      .then((data) => {
        if (data === "Success") {
          console.log("yo4")
          getCartItems(getLocalData("user-info")?.userToken, getLocalData("user-info")?.userData?.id,  outletId ).then(
            (res) => {
              dispatch(addTOCart(res));
            },
            (failure) => {
              console.error(failure); //expected output: Oopsy...
            }
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const UpdatePQuantity = (qty) => {
   
    if(qty>0){
      setPQuantity(qty);
      throttled.current(qty);
    }
    
  };


  

  const removeFromCart = () => {
    let arr = [];
    allAssociatedProducts.forEach((item) => {
      if (
        item.productId === parseInt(product.id) &&
        item.shoppingCartTypeId === 1
      ) {
      } else {
        arr.push(item);
      }
    });

    let tempArrForAddTOCart = [];

    arr.forEach((item) => {
      let tempItem = {
        productId: item.productId,
        quantity: item.quantity,
        shoppingCartTypeId: item.shoppingCartTypeId,
      };

      tempArrForAddTOCart.push(tempItem);
    });

    addToCart(
      userData.id,
      userData.outletId,
      getLocalData("user-info")?.userToken,
      tempArrForAddTOCart
    )
      .then((data) => {
        if (data === "Success") {
         
          getCartItems(getLocalData("user-info")?.userToken, getLocalData("user-info")?.userData?.id,  outletId ).then(
            (res) => {
              dispatch(addTOCart(res));
            },
            (failure) => {
              console.error(failure); //expected output: Oopsy...
            }
          );
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <Row className="cart-details-item">
      <Col xs={3} md={3}>
        <Link to={`/productDetails/${product.id}`}>
          <img
            className="cart-details-img"
            alt={product.name}
            src={product.pictureUrl}
          />
        </Link>
      </Col>
      <Col xs={9} md={9} className="pl-pd-mobile">
        <Row>
          <Col md={12}>
            <Link
              to={`/productDetails/${product.id}`}
              className="cart-details-name"
            >
              {product.name}
            </Link>
          </Col>
          <Col md={12} className="cart-details-by">
            {
              product.manufacturerName !== "" && (
                <>{t("odcpl-t-1")} <span className="cart-details-manu">{product.manufacturerName}</span></>
                
              )
            }
            
          </Col>
          <Col md={12} className="cart-details-qty">
           
            {product.drugTypeValue === 'OverTheCounter' ? '': (<span className="otc-product">{t("odcpl-t-3")}</span>)}
          </Col>

          
          <Col md={12} className="cart-details-qty">
          {t("odcpl-t-2")}
          </Col>
          <Col md={9} xs={9} className="cart-details-qty">

          {
            stockQuantity > 0 ? (
              <>
              <div className="pd-cart-button-grp">
              <button
                className="btn btn-secondary"
                disabled={quantity === 1 ? "disabled" : ""}
                onClick={() => {
                  UpdatePQuantity(pQuantity - 1);
                }}
              >
                <img src={Minus} alt="minus" />
              </button>
              <span>{pQuantity}</span>
              <button
                className="btn btn-secondary"
                disabled={quantity === 100 ? "disabled" : ""}
                onClick={() => {
                  UpdatePQuantity(pQuantity + 1);
                }}
              >
                <img src={Plus} alt="plus" />
              </button>
            </div>
            <div className="pl-sp-qty">
              {currency}{" "}
              {
                formatNumber(product.price)
                  
              }
            </div>
              </>
            ): (<div>Out of stock</div>)
          }
           
          </Col>
          <Col md={3} xs={3} className="cart-details-rfc">
            <button
              className="btn btn-danger"
              onClick={() => {
                removeFromCart();
              }}
            >
              <img src={Plus} alt="plus" />
            </button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ProductListItem;

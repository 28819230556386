import React from 'react'
import {signInWithPopup, FacebookAuthProvider} from 'firebase/auth'
import {authentication} from '../../utils/firebase'
import { userLogin } from "../../services/UserService";
import { setLocalData } from "../../utils/localStorage";
import { updateUser } from "../../redux/userSlice";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

import Facebook from '../../static/facebook.png'

function FacebookLogin() {

    
    const dispatch = useDispatch();

  const navigate = useNavigate();

    const signInWithFacebook = () => { 
        const provider = new FacebookAuthProvider();
        signInWithPopup(authentication, provider)
        .then((res)=>{
          console.log(res)
            let tempUserObj = {
                name: res._tokenResponse.fullName,
                externalIdentifier: res._tokenResponse.localId,
                oAuthAccessToken: res._tokenResponse.idToken,
                loginType: "Facebook",
                language: "en",
                email: res._tokenResponse.email.length > 0 ? res._tokenResponse.email.length : null,
              };

              
        userLogin(tempUserObj)
        .then((res) => {
          

          if (res.status === "Success") {
            let userInfo = {
              userToken: res.response.token,
              userData: res.response.patientData,
            };
            navigate("/user");
            setLocalData("user-info", userInfo);
            dispatch(updateUser(res.patientData));
          } else if (res.status === "Fail") {
            swal({
              icon: "error",
              title: "Invalid Email or password",
            });
          }
        })
        .catch((err) => {
        
          swal({
            icon: "error",
            text: "there are some errors",
          });
        });
        })
        .catch((err)=>{
            console.log(err)
        })
     }
  return (
    <span className='login-btn' onClick={signInWithFacebook}>
        <img alt="fb" src={Facebook} />
    </span>
  )
}

export default FacebookLogin
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import "./App-responsive.css";
import "./app-extra.css";
import "./AppRTL.css";
import "./Styles/styles.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Router from "./Router/Router";
import Alert from "./components/Alert";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "./redux/userSlice";

import { getLocalData, setLocalData } from "./utils/localStorage";

import { getCartItems } from "./services/CartService";

import { addTOCart } from "./redux/cartSlice";
import { getPharmacySettingRedux } from "./redux/pharmacySlice";
import FocusedComp from "./components/FocusedSearch/FocusedComp";
import { getAllOutlets, getNearByOutlets } from "./services/OutletService";
import usePosition from "./utils/usePosition";
import { updateOutletArray, updateSelectedOutlet } from "./redux/outletSlice";
import ServiceExpired from "./components/serviceExpired/ServiceExpires";

function App() {
  const userData = useSelector((state) => state.user.userData);

  const alertMsg = useSelector((state) => state.alert.alertMsg);
  const alertState = useSelector((state) => state.alert.alertState);
  const alertIsEnabled = useSelector((state) => state.alert.isEnabled);
  const pharmacyName = useSelector(
    (state) => state.pharmacy.pharmacyDetails.pharmacy.pharmacyName
  );
  const pharmacy = useSelector(
    (state) => state.pharmacy.pharmacyDetails.pharmacy
  );

  const isRTLEnabled = useSelector((state) => state.pharmacy.isRTLModeEnabled);
  const SearchModal = useSelector((state) => state.searchModal);

  const dispatch = useDispatch();

  let loggedIn =
    getLocalData("user-info")?.userToken?.length > 0 ? true : false;

  useEffect(() => {
    if (loggedIn) {
      dispatch(updateUser(getLocalData("user-info")?.userData));
      getCartItems(
        getLocalData("user-info")?.userToken,
        getLocalData("user-info")?.userData?.id,
        getLocalData("outlet-id")
      ).then(
        (res) => {
          dispatch(addTOCart(res));
        },
        (failure) => {
          console.error(failure); //expected output: Oopsy...
        }
      );
    } else {
    }
  }, [dispatch, loggedIn]);

  useEffect(() => {
    dispatch(getPharmacySettingRedux());
  }, []);

  // load page title
  useEffect(() => {
    if (pharmacyName) {
      document.title = pharmacyName;
    }
  }, [pharmacyName]);

  const location = usePosition();

  //outlet magic at start
  let outLetID = getLocalData("outlet-id");

  const inaitialOutList = useSelector((state) => state.outlet.outArray);

  useEffect(() => {
    getAllOutlets(0, 0, 0)
      .then((data) => {
        dispatch(updateOutletArray(data));
        let defOutlet = data.filter((outlet) => outlet?.isDefault);
        setLocalData("outlet-id", defOutlet[0].id);

        let selectOutObj = {
          outletName: defOutlet[0]?.outletName,
          outletID: defOutlet[0]?.id,
          autoLoad: true,
        };

        dispatch(updateSelectedOutlet(selectOutObj));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (inaitialOutList.length > 0) {
      // console.log("test ", inaitialOutList)
      if (loggedIn) {
        let outletId = getLocalData("user-info")?.userData?.outletId;
        setLocalData("outlet-id", outletId);

        let tempFilter = inaitialOutList.filter(
          (outlet) => outlet.id === outletId
        );
        let selectOutObj = {
          outletName: tempFilter[0]?.outletName,
          outletID: outletId,
          autoLoad: false,
        };

        dispatch(updateSelectedOutlet(selectOutObj));
      } else {
        if (location.loaded) {
          if (location.coordinates) {
            if (
              location.coordinates.lat !== "" &&
              location.coordinates.lat !== ""
            ) {
              getNearByOutlets(
                0,
                location.coordinates.lat,
                location.coordinates.lng
              )
                .then((data) => {
                  if (data[0].id > 0 && data[0].id !== null) {
                    setLocalData("outlet-id", data[0].id);

                    let selectOutObj = {
                      outletName: data[0].outletName,
                      outletID: data[0].id,
                      autoLoad: false,
                    };

                    dispatch(updateSelectedOutlet(selectOutObj));
                  }
                })
                .catch((err) => console.log(err));
            }
          }
        }
      }
    }
  }, [inaitialOutList, loggedIn]);

  return (
    <div
      className={`${isRTLEnabled ? "rtl-main" : ""} ${
        SearchModal.isEnabled ? "no-scroll" : ""
      }`}
    >
      {alertIsEnabled && (
        <Alert msg={alertMsg} state={alertState} isEnabled={alertIsEnabled} />
      )}
      {!pharmacy.isBillingExpired ? <Router /> : <ServiceExpired />}
    </div>
  );
}

export default App;

export const StepLine = () => {
    return (
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 258" width="331" height="243">
            <title>Line 1-svg</title>
            <style>
                {`.s0 {fill: none; stroke: #263077; stroke-linecap: round; stroke-width: 4; stroke-dasharray: 34;}`}
            </style>
            <path id="Layer" fillRule="evenodd" className="s0" d="M352 255.5H208c-16.6 0-30-13.4-30-30v-12.5m0-211v220" />
        </svg>
    );
};

export const StepLineRevurs = () => {
    return (
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 258" width="331" height="243">
            <title>Line 1-svg</title>
            <style>
                {`.s0 {fill: none; stroke: #263077; stroke-linecap: round; stroke-width: 4; stroke-dasharray: 34;}`}
            </style>
            <path id="Layer" fillRule="evenodd" className="s0" d="M3 253.6h142.6c16.4 0 29.7-13.3 29.7-29.7v-12.4m0-208.9v217.8" />
        </svg>
    );
};

import React, { useCallback, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

//import mapStyles from "./MapStyles";
import "./map.css";
import { useState } from "react";
import { useRef } from "react";
import currentLocation from "./currentLoc.svg";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { useTranslation } from "react-i18next";

function MapOutlet(props) {
  const { t } = useTranslation();
  const [libraries] = useState(["places"]);
  const { inputValues, outlets, locateOut } = props;


  const mapContainerStyle = {
    width: "100%",
    height: "568px",
  };

  const initPoint = {
    lat: 17.347623643269607,
    lng: 78.49703099431717,
  };

  const [center, setCenter] = useState(initPoint);

  useEffect(() => {
    if (outlets.length > 0) {
      setCenter({
        lat: outlets[0]?.latitude,
        lng: outlets[0]?.longitude,
      });
    }
  }, [outlets]);


  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    setMarkers((current) => [
      {
        lat: inputValues.latitude,
        lng: inputValues.longitude,
      },
    ]);
  }, []);

  const panTOGeo2 = useCallback(async ({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(18);
  }, []);

  useEffect(() => {
    panTOGeo2({
      lat: locateOut.lat,
      lng: locateOut.lng,
    });
  }, [locateOut]);

  const allOtletArr = outlets.map((outlet) => {
    return {
      lat: outlet.latitude,
      lng: outlet.longitude,
    };
  });

  const [initalOutlet, setInitalOutlet] = useState(allOtletArr);

  const [markers, setMarkers] = useState([]);

  const options = {
    //styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_PLACES_API_KEY}`,
    libraries,
  });

  function handleCenter() {
    if (!mapRef.current) return;

    const newPos = mapRef.current.getCenter().toJSON();
    setCenter(newPos);
  }

  if (loadError) return t("mcmt-t-1");
  if (!isLoaded) return t("mcmt-t-2");

  return (
    <>
      <div className="map-parent">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={16}
          center={center}
          onDragEnd={handleCenter}
          options={options}
          onLoad={onMapLoad}
        >
          {initalOutlet.map((marker) => (
            <Marker
              key={marker.lat}
              position={{ lat: marker.lat, lng: marker.lng }}
              icon={{
                url: "/pointer.svg",
                scaledSize: new window.google.maps.Size(80, 80),
                origin: new window.google.maps.Point(0, 0),
                // anchor: new window.google.maps.Point(15, 15),
              }}
            />
          ))}
        </GoogleMap>
      </div>
    </>
  );
}

export default MapOutlet;

function Locate({ panTO }) {
  return (
    <button
      className="get-current-location"
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTO({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
      }}
    >
      <img src={currentLocation} />
    </button>
  );
}

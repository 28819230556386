import axios from "axios";


export const getAllCategory = async (  languageId, isParent ) =>{

  const promise = axios.post(`${process.env.REACT_APP_API}api/v1/GetAllCategory?pharmacyId=${process.env.REACT_APP_PHARMACY_ID}&languageId=${languageId}&isParent=${isParent}`);

  const dataPromise = promise.then((response) => response.data.response.categories);

  return dataPromise; 
      
}


export const getAllCategoryWithPagination = async (  languageId, pageIndex,  pageSize) =>{
  const promise = axios.post(`${process.env.REACT_APP_API}api/v1/GetAllCategoriesWithPagination` , {
    pageIndex,
    pageSize, 
    pharmacyId: process.env.REACT_APP_PHARMACY_ID,
    languageId: "en"
  });

  const dataPromise = promise.then((response) => response.data.response.data.categories);

  return dataPromise; 
      
}

export const getAllCategoryWithPagination2 = async (  languageId, pageIndex,  pageSize) =>{
  const promise = axios.post(`${process.env.REACT_APP_API}api/v1/GetAllCategoriesWithPagination` , {
    pageIndex,
    pageSize, 
    pharmacyId: process.env.REACT_APP_PHARMACY_ID,
    languageId
  });

  const dataPromise = promise.then((response) => response.data.response.data.categories);

  return dataPromise; 
      
}


export const GetAllCategoriesHierchyFormat = async (  categoryid, languageId) =>{
  const promise = axios.post(`${process.env.REACT_APP_API}api/v1/GetAllCategoriesHierchyFormat?pharmacyId=${process.env.REACT_APP_PHARMACY_ID}&parentCategoryId=${categoryid}&languageId=${languageId}`);

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise; 
      
}

export const GetAllCategoriesHierchyFormat2 = async (  ) =>{
  const promise = axios.post(`${process.env.REACT_APP_API}api/v1/GetAllCategoriesHierchyFormat?pharmacyId=${process.env.REACT_APP_PHARMACY_ID}`);

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise; 
      
}


import React from 'react'

function PlayIcon() {
  return (
    <svg width="27" height="33" viewBox="0 0 27 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.4031 14.8497L4.96805 1.01578L20.7894 11.0928L17.4031 14.8497ZM1.72266 0.203125C0.99019 0.628208 0.5 1.40336 0.5 2.40981V29.9964C0.5 31.0029 0.99019 31.778 1.72266 32.2031L16.1804 16.2L1.72266 0.203125ZM25.68 14.3059L22.3614 12.1742L18.6596 16.2063L22.3614 20.2383L25.7476 18.1066C26.7618 17.2127 26.7618 15.1998 25.68 14.3059ZM4.96805 31.3967L20.7894 21.3197L17.4031 17.5628L4.96805 31.3967Z" fill="white"/>
</svg>
  )
}

export default PlayIcon
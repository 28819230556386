import React, {useEffect} from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import ProductGridItem from "../ProductGridItem";

import {Link} from 'react-router-dom'
import { getLocalData } from "../../utils/localStorage";
import { getAllOrdersList } from "../../services/OrderService";
import {updateOrders} from '../../redux/orderSlice'
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OrderListItem from "../OrderDetails/OrderListItem";

import Pagination from "@mui/material/Pagination";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/numberFormater";


function Orders() {
  const { t } = useTranslation();

  const savedOrders = useSelector((state) => state.order.orders);
  const [orders, setOrders] = useState(savedOrders);

  const dispatch = useDispatch()
  const navigate = useNavigate();

//currency
const currency = useSelector((state) => state.pharmacy.currencySymbol);
  useEffect(() => {
    
    getAllOrdersList(getLocalData("user-info")?.userData.id,  getLocalData("user-info")?.userToken, 1, 10)
    .then(data => {
      calculateNoOfPages(data.response.count);
      setOrders(data.response.data)
    dispatch( updateOrders(data.response.data))
    })
    .catch(err => console.log(err))
  }, []);


  const [noOfPages, setNoOfpages] = useState(1);
  const calculateNoOfPages = (tp) => {
    let tempVar = Math.ceil(tp / 10);
    if (tempVar === 0) {
      tempVar = 1;
    }

    setNoOfpages(tempVar);
  };


  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
   
    setPage(value);
  };


  useEffect(() => {
    getAllOrdersList(getLocalData("user-info")?.userData.id,  getLocalData("user-info")?.userToken, page, 10)
    .then(data => {
      setOrders(data.response.data)
    })
    .catch(err => console.log(err))
  }, [page])
  
  
  return (
    <Row>
      <Col md={12}>
        <span className="header-1 ms-1"><span  onClick={()=>{navigate(-1)}}><ArrowBackIcon/></span>{t("uco-t-1")}</span>
      </Col>
      <Col md={12}>
        <div className="account-general">
          {orders.length > 0 ? (
            orders.map((order) => {
              return (
                <Row key={order.id}>
                  <Col className="order-parent" md={12}>
                    <div className="order-id">
                    {t("uco-t-2")} 
                      <span className="order-value">{order.id}</span>
                    </div>
                    <div className="order-date">
                    
                      <span className="order-value">
                        {moment(order.createdOnUtc).format("MMMM DD, YYYY")}
                      </span>
                    </div>
                    <div className="order-total">
                     
                      <span className="order-total-btn">
                      {t("uco-t-3")}  {" "}
                      <span>
                      {currency}
                        {
                          formatNumber(order.grandTotal)
                        }
                      </span>
                      
                      </span>
                    </div>
                    <div className="order-button">
                      <Link to={`/user/orderdetails/${order.id}`} className="btn ecom-solid">
                      {t("uco-t-4")} 
                      </Link>
                      
                    </div>
                    <div className="order-status">{order.orderStatus}</div>
                    {
                      order.orderItems.length > 0 && (
                        <div className="order-plist">
                      <Row className="mobile-grid"> 
                        {order.orderItems.length > 0
                          ? order.orderItems.map((item, index) => {
                            if(index < 2){
                              return (
                                <OrderListItem item={item}  index={index} key={item.id} />
                               );
                            }
                              return null; 
                            })
                          : ""}
                      </Row>
                      {order.orderItems.length > 2 && (
                      <Row>
                        <Col xs={12} className=" d-flex align-items-center justify-content-center bw-linwe">
                          
                              <span className="gray-badge-productlist">
                                +{order.orderItems.length - 2} {t("uco-t-5")}  {order.orderItems.length - 2 > 1 ?  t("uco-t-6") : t("uco-t-7")} 
                              </span>
                           
                        </Col>
                      </Row>
                      )}
                    </div>
                      )
                    }
                    
                    <div className="order-divider d-none">
                      <hr/>
                    </div>
                  </Col>
                </Row>
              );
            })
          ) : (
            <div>{t("uco-t-8")}</div>
          )}


            


        </div>
      </Col>
      {
              orders.length > 0 && (
                <Col md={12}>
                      <div className="pagination-parent">
                        <Pagination
                          count={noOfPages}
                          page={page}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
              )
            }
    </Row>
  );
}

export default Orders;

import React from "react";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import NoImage from "../static/noimage.svg";
import wish from "../static/heart.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Plus from "../static/Product/plus.svg";
import { showAlert, hideAlert } from "../redux/alertSlice";
import { addToCart, getCartItems } from "../services/CartService";
import { addTOCart } from "../redux/cartSlice";
import { getLocalData } from "../utils/localStorage";
import Wishlist from "./SvgComponents/Wishlist";
import { formatNumber } from "../utils/numberFormater";

function ProductGridItem(props) {
  const { name, brand, price, oldPrice, id, imgUrl, enableWishlist, outOfStock = true, type } = props;

  const wishListProducts = useSelector((state) => state.wishlist.wishList);

  const dispatch = useDispatch();

  //wishlist functionality
  const allAssociatedProducts = useSelector((state) => state.cart.cartItems);

  //userinfo
  const userData = useSelector((state) => state.user.userData);


  //currecy
  const currency = useSelector((state) => state.pharmacy.currencySymbol);

  const debounce = (callback, delay = 1000) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  };
  const updateDebounce = debounce((id, name) => {
    updateWishlist(id, name);
  });

  const handleClickOnWishlist = (id, name) => {
    updateDebounce(id, name);
  };


  const outletIdM = getLocalData('outlet-id')

  const updateWishlist = (id, name) => {
    if (JSON.stringify(userData) !== "{}") {
      const isProductPresentInWishList = allAssociatedProducts.find(
        (o) => o.productId === parseInt(id) && o.shoppingCartTypeId === 2
      );

      dispatch(hideAlert());

      let tempProduct = {
        productId: parseInt(id),
        quantity: 1,
        shoppingCartTypeId: 2,
      };

      if (!isProductPresentInWishList) {
        let tempArrForAddTOCart = [];




        const temp = allAssociatedProducts.map((item) => {
          let tempItem = {
            productId: item.productId,
            quantity: item.quantity,
            shoppingCartTypeId: item.shoppingCartTypeId,
          };

          tempArrForAddTOCart.push(tempItem);
        });

        tempArrForAddTOCart.push(tempProduct);

        addToCart(
          userData.id,
          outletIdM,
          getLocalData("user-info")?.userToken,
          tempArrForAddTOCart
        )
          .then((data) => {
            if (data === "Success") {
              console.log("yo2")
              getCartItems(getLocalData("user-info")?.userToken, getLocalData("user-info")?.userData?.id, getLocalData("outlet-id")).then(
                (res) => {
                  dispatch(addTOCart(res));
                },
                (failure) => {
                  console.error(failure); //expected output: Oopsy...
                }
              );
            }
          })
          .catch((err) => console.log(err));
        let alert = {
          state: "success",
          msg: `${name} Added to Wishlist`,
        };
        dispatch(showAlert(alert));
      } else {
        let alert = {
          state: "danger",
          msg: `${name} is already in Wishlist`,
        };
        dispatch(showAlert(alert));
      }
    } else if (JSON.stringify(userData) === "{}") {
      let alert = {
        state: "danger",
        msg: `Please Login/Sign-Up to Continue`,
      };
      dispatch(showAlert(alert));
    }
  };

  const removeFromWish = () => {
    let arr = [];
    allAssociatedProducts.map((item) => {
      if (item.productId === parseInt(id) && item.shoppingCartTypeId === 2) {
      } else {
        arr.push(item);
      }
      return item
    });

    let tempArrForAddTOCart = [];

    arr.map((item) => {
      let tempItem = {
        productId: item.productId,
        quantity: item.quantity,
        shoppingCartTypeId: item.shoppingCartTypeId,
      };

      tempArrForAddTOCart.push(tempItem);
      return item
    });

    addToCart(
      userData.id,
      outletIdM,
      getLocalData("user-info")?.userToken,
      tempArrForAddTOCart
    )
      .then((data) => {
        if (data === "Success") {
          console.log("yo3")
          getCartItems(getLocalData("user-info")?.userToken, getLocalData("user-info")?.userData?.id, getLocalData("outlet-id")).then(
            (res) => {
              dispatch(addTOCart(res));
            },
            (failure) => {
              console.error(failure); //expected output: Oopsy...
            }
          );
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={`pci ${!outOfStock ? 'oos' : ''}`}>
      {oldPrice === null ? (
        ""
      ) : (
        <span className="product-disc"> <span>{`${formatNumber(100 - (price / oldPrice) * 100)

          }%`}</span></span>
      )}

      {enableWishlist && (
        <span
          className=" wish-icon2"
          onClick={() => {
            handleClickOnWishlist(id, name);
          }}
        >
          <Wishlist />
        </span>
      )}
      <div className="upperContent">
        <Link to={`/productDetails/${id}`}>
          <img
            className="img-fluid-2"
            alt={name}
            src={imgUrl === "" ? NoImage : imgUrl}
          />
        </Link>



        {
          type === 2 && (
            <span className="rx-type">
              RX
            </span>
          )
        }

        {
          !outOfStock && (<span className="product-oos">OUT OF STOCK</span>)
        }

        {!enableWishlist && (
          <span className="remove-from-wish">
            <button
              className="btn btn-danger"
              onClick={() => {
                removeFromWish();
              }}
            >
              <img src={Plus} alt="plus" />
            </button>
          </span>
        )}
      </div>
      <div className="lowerContent">
        <Row className="product-n-a-b">
          <Col lg={12} md={12} className="product-name">
            <Link to={`/productDetails/${id}`}>{name}</Link>
          </Col>
          <Col lg={12} md={12} className="product-manu mt-1 mb-3">
            {
              brand !== '' && (<span className="pg-bn">{brand} </span>)
            }



          </Col>

        </Row>
        <Row className="mt-2">
          <Col lg={12} md={12} xs={12} className="p-temprice">
            <span className="product-dp">{`${currency}  ${formatNumber(price)}`}</span>
            <span className="product-op">
              {oldPrice === null ? "" : `${currency}  ${formatNumber(oldPrice)}`}{" "}
            </span>
          </Col>

        </Row>
      </div>
    </div>
  );
}

export default ProductGridItem;

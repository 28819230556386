import searchicn from '../../static/searchicn.svg'

function InfoBoxSearch(props) {
  

  const { options, selectProduct} = props;
 
  console.log(options)

  return (
    <div  className="info-box">
      

      {
        options.length > 0 ? (
          <>
         
        <div className='search-list out-list'>
        {
          options.map((product)=>{
            return (
              <div  key={product.id} onClick={()=> selectProduct(product.id)}>
              <img className="search-item-icon" src={product.pictureUrl} alt={product.name}></img>
              <span className="search-item-name">{product.name}</span>
            </div>
            )
           
          })
        }
        </div>
          
          </>
          
        ) : (
          <div className='empty-search'>
            <img alt="empty-search" src={searchicn} />
          </div>
        )
      }
    </div>
  );
}

export default InfoBoxSearch;

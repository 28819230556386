import React from "react";

function WishlistBlack() {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0677 0.395386C12.4557 0.395386 10.9086 1.1458 9.89872 2.33166C8.88892 1.1458 7.34176 0.395386 5.72975 0.395386C2.87629 0.395386 0.634277 2.63738 0.634277 5.49084C0.634277 8.99282 3.78419 11.8463 8.55541 16.1821L9.89872 17.3957L11.2421 16.1728C16.0133 11.8463 19.1632 8.99282 19.1632 5.49084C19.1632 2.63738 16.9212 0.395386 14.0677 0.395386ZM9.99142 14.8017L9.89872 14.8943L9.80612 14.8017C5.39621 10.8087 2.48718 8.16828 2.48718 5.49084C2.48718 3.63795 3.87685 2.24828 5.72975 2.24828C7.15647 2.24828 8.54614 3.16546 9.03716 4.43469H10.7696C11.2513 3.16546 12.641 2.24828 14.0677 2.24828C15.9206 2.24828 17.3103 3.63795 17.3103 5.49084C17.3103 8.16828 14.4013 10.8087 9.99142 14.8017Z"
        fill="url(#svg-gradb)"
      />
      <defs>
        <linearGradient
          id="svg-gradb"
          x1="0.634278"
          y1="17.3957"
          x2="18.4269"
          y2="-0.343182"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0854" className="start-colorb" />
          <stop offset="0.922" className="end-colorb" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default WishlistBlack;

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userData: {}
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  appleForDelete: false,
  reducers: {

    updateUser: (state, action) => {
      state.userData = action.payload
    },

    applyforDelete: (state, action) => {
      state.appleForDelete = action.payload;
    }

  },
})

// Action creators are generated for each case reducer function
export const { updateUser, applyforDelete } = userSlice.actions

export default userSlice.reducer
import React from "react";

function EmailSVG(props) {
  let color = "svg-grad-disabled";
  if (props.isActive) {
    color = "svg-grad";
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17" viewBox="0 0 21 17" fill="none">
      <path d="M2.01782 16.1147C1.46782 16.1147 0.996824 15.9187 0.604824 15.5267C0.212824 15.1347 0.0171573 14.6641 0.017824 14.1147V2.11475C0.017824 1.56475 0.213824 1.09375 0.605824 0.701748C0.997824 0.309748 1.46849 0.114081 2.01782 0.114748H18.0178C18.5678 0.114748 19.0388 0.310748 19.4308 0.702748C19.8228 1.09475 20.0185 1.56541 20.0178 2.11475V14.1147C20.0178 14.6647 19.8218 15.1357 19.4298 15.5277C19.0378 15.9197 18.5672 16.1154 18.0178 16.1147H2.01782ZM18.0178 4.11475L10.5428 8.78975C10.4595 8.83975 10.3718 8.87741 10.2798 8.90275C10.1878 8.92808 10.1005 8.94041 10.0178 8.93975C9.93449 8.93975 9.84682 8.92741 9.75482 8.90275C9.66282 8.87808 9.57549 8.84042 9.49282 8.78975L2.01782 4.11475V14.1147H18.0178V4.11475ZM10.0178 7.11475L18.0178 2.11475H2.01782L10.0178 7.11475ZM2.01782 4.36475V2.88975V2.91475V2.90275V4.36475Z"  fill="url(#svg-grad)"/>
      <defs>
      <linearGradient
          id="svg-grad"
          x1="0.584961"
          y1="15.5625"
          x2="15.885"
          y2="1.56197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0854" className="start-color" />
          <stop offset="0.922" className="end-color" />
        </linearGradient>
      </defs>
    </svg>
    
  );
}

export default EmailSVG;

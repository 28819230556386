import axios from "axios";

const pharmacyId = process.env.REACT_APP_PHARMACY_ID;


export const getAllOrdersList = async (patientId, token, pageNo, pageSize) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/GetCustomerOrdersWithPagination`,
    {
      pharmacyId,
      patientId,
      pageNo,
      pageSize
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const getAllOrders = async (patientId, token) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/GetCustomerOrders`,
    {
      patientId,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data.response.data);

  return dataPromise;
};

export const getOrderTotal = async (
  patientId,
  token,
  shippingMethod,
  couponCode
) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/GetOrderTotals`,
    { pharmacyId, patientId, shippingMethod, couponCode },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};

export const getAllCopouns = async (token) => {
  const promise = axios.get(
    `${process.env.REACT_APP_API}api/v1/GetDiscountCouponForOrder`,
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};

export const getAllCopounsForOrder = async (token, orderId) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/PrescriptionOrder/GetDiscountCouponForOrder`,
    {
      orderId
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};

export const createOrderFromCart = async (orderItems, token) => {
  

  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/CreateOrder`,
    {
      ...orderItems,
      pharmacyId,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response);

  return dataPromise;
};

export const getOrderDetailsById = async (token, id) => {
  const promise = axios.get(
    `${process.env.REACT_APP_API}api/v1/GetOrderDetailsById/${id}`,
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data.response.data);

  return dataPromise;
};

export const createOrderByPrescriptionOnly = async (orderItems, token) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/CreateOrderByPrescriptionOnly`,
    {
      ...orderItems,
      pharmacyId,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response);

  return dataPromise;
};


export const updatePrescriptionOrders = async (orderId, orderItems, token) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/PrescriptionOrder/UpdateOrderItems`,
    {
      orderId,
      orderItems
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};


export const updatePrescriptionApproval = async (orderId, approvalStatus, couponCode,  token) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/PrescriptionOrder/UpdateOrderApprovalStatus`,
    {
      orderId,
      approvalStatus,
      couponCode
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};


export const ReUploadPrescriptionImages = async (
  orderId,
  prescriptionImageIds,
  token
) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/ReUploadPrescriptionImages`,
    {
      orderId,
      prescriptionImageIds,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response);

  return dataPromise;
};

export const getOrderTotalPrescriptionOrder = async (
  orderId,
  token,
  couponCode
) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/PrescriptionOrder/GetOrderTotals`,
    {
      orderId,
      couponCode,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};


export const generateTOkenForPayment = async (
  purchasedItemsDesc,
  paymentMethodSystemName,
  shippingMethod,
  couponCode,
  token
) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/Pay/GenerateToken`,
    {
      purchasedItemsDesc,
  paymentMethodSystemName,
  shippingMethod,
  couponCode
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

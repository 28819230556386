
export const formatNumber = (number) => {

  // WAY ONE

  //let toFixed2Deci = number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

  //let convertIntoInternationalValue = toFixed2Deci.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
  //let convertIntoInternationalValue = toFixed2Deci.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  

  // WAY TWO
  let toFixed2Deci = number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  let convertBacktoFloat = parseFloat(toFixed2Deci);
  let convertIntoInternationalValue = convertBacktoFloat.toLocaleString("en-US");

   return convertIntoInternationalValue
};


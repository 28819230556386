import React from "react";

function TcSvg(props) {
    let color = "svg-grad-disabled";
    if (props.isActive) {
      color = "svg-grad";
    }
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99996 0.666626C4.39996 0.666626 0.666626 4.39996 0.666626 8.99996C0.666626 13.6 4.39996 17.3333 8.99996 17.3333C13.6 17.3333 17.3333 13.6 17.3333 8.99996C17.3333 4.39996 13.6 0.666626 8.99996 0.666626ZM8.99996 9.83329C8.54163 9.83329 8.16663 9.45829 8.16663 8.99996V5.66663C8.16663 5.20829 8.54163 4.83329 8.99996 4.83329C9.45829 4.83329 9.83329 5.20829 9.83329 5.66663V8.99996C9.83329 9.45829 9.45829 9.83329 8.99996 9.83329ZM9.83329 13.1666H8.16663V11.5H9.83329V13.1666Z"
        fill={`url(#${color})`}
      />

<defs>
        <linearGradient
          id={color}
          x1="0.584961"
          y1="15.5625"
          x2="15.885"
          y2="1.56197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0854" className="start-color" />
          <stop offset="0.922" className="end-color" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default TcSvg;

import React from "react";
import Home from "../pages/Home";
import AllCategories from "../pages/AllCategories";
import AllBrands from "../pages/AllBrands";
import AllProducts from "../pages/AllProducts";
import Footer from "../components/Footer2";
import Header from "../components/HeaderNew";
import WishList from "../pages/WishList";
import UploadPrescription from "../pages/UploadPrescription";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "../pages/Login";
import User from "../pages/User";
import Orders from "../components/User/Orders";
import Profile from "../components/User/Profile";
import PrivateRoute from "./PrivateRoute";
import OrderDetails from "../components/User/OrderDetails";
import AllAddress from "../components/User/AllAddress";
// import CreateAddress from "../components/User/CreateAddress";
import UpdateAddress from "../components/User/UpdateAddress";
import ProductDetails from "../pages/ProductDetails";
import Cart from "../pages/Cart";
import EditProfile from "../components/User/EditProfile";
import AllFeaturedProducts from "../pages/AllFeaturedProducts";
import AllOfferrProducts from "../pages/AllOfferrProducts";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsConditions from "../pages/TermsConditions";
import ScrollWrapper from "./ScrollWrapper";
import InjectInterceptors from "./InjectInterceptors";
import AllProductsCategory from "../pages/AllProductsCategory";
import { useSelector } from "react-redux";
import FocusedComp from "../components/FocusedSearch/FocusedComp";
import Outlets from "../pages/Outlets";
import FunctionalityEnableDisable from "./FunctionalityEnableDisableRoute";
import Products from "../pages/Products";
import CreateAddressComp from "../components/Cart/CreateAddressComp";
import DataDeletion from "../pages/data-deletion/DataDeletion";
import SubmitRequest from "../pages/data-deletion/SubmitRequest";

function Router() {

  const SearchModal = useSelector((state) => state.searchModal);
  return (
    <BrowserRouter>
      <ScrollWrapper>
        {SearchModal.isEnabled && <FocusedComp type={SearchModal.type} />}
        <div className="d-flex flex-column vh-100">
          <Header />
          <InjectInterceptors />
          <div className="main-ecomm">
            <Routes>

              <Route exact path="/" element={<Home />} />
              <Route exact path="/outlets" element={<Outlets />} />
              <Route exact path="/categories" element={<AllCategories />} />
              <Route exact path="/products" element={<Products />} />
              <Route exact path="/brands" element={<AllBrands />} />
              <Route exact path="/featuredproducts" element={<AllFeaturedProducts />} />
              <Route exact path="/allofferrproducts" element={<AllOfferrProducts />} />
              <Route exact path="/wishlist" element={<PrivateRoute><WishList /></PrivateRoute>} />
              <Route exact path="/uploadprescription" element={<FunctionalityEnableDisable><UploadPrescription /></FunctionalityEnableDisable>} />
              <Route exact path="/products/category/:id" element={<AllProductsCategory />} />
              <Route exact path="/products/subcategory/:id" element={<AllProducts />} />
              <Route exact path="/products/brand/:id" element={<AllProducts />} />

              <Route exact path="/productDetails/:id" element={<ProductDetails />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/cart" element={<PrivateRoute><Cart /></PrivateRoute>} />
              <Route exact path="/data-deletion" element={<DataDeletion />} />
              <Route exact path="/data-deletion/submit-request" element={<PrivateRoute><SubmitRequest /></PrivateRoute>} />

              <Route exact path="user" element={<PrivateRoute><User /></PrivateRoute>}>
                <Route exact path="orders" element={<PrivateRoute><Orders /></PrivateRoute>} />
                <Route exact path="orderdetails/:id" element={<PrivateRoute><OrderDetails /></PrivateRoute>} />
                <Route exact path="createAddress" element={<PrivateRoute><CreateAddressComp /></PrivateRoute>} />
                <Route exact path="updateAddress/:id" element={<PrivateRoute><UpdateAddress /></PrivateRoute>} />
                <Route exact path="address" element={<PrivateRoute><AllAddress /></PrivateRoute>} />
                <Route exact path="editprofile" element={<PrivateRoute><EditProfile /></PrivateRoute>} />
                <Route index exact element={<PrivateRoute><Profile /></PrivateRoute>} />
              </Route>
              <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route exact path="/termsconditions" element={<TermsConditions />} />
            </Routes>
          </div>

          <Footer />
        </div>
      </ScrollWrapper>

    </BrowserRouter>
  );
}

export default Router;

import React from "react";

function SearchHeader() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M9.67628 2.16667C5.25269 2.16667 1.66667 5.67349 1.66667 9.99938C1.66667 14.3253 5.25269 17.8321 9.67628 17.8321C11.5682 17.8321 13.307 17.1906 14.6775 16.1179L17.2809 18.6572L17.3501 18.7155C17.5919 18.8904 17.9345 18.8705 18.1532 18.6561C18.3938 18.4202 18.3933 18.0383 18.1521 17.803L15.5793 15.2936C16.8875 13.8995 17.6859 12.0406 17.6859 9.99938C17.6859 5.67349 14.0999 2.16667 9.67628 2.16667ZM9.67628 3.37311C13.4185 3.37311 16.4522 6.33979 16.4522 9.99938C16.4522 13.659 13.4185 16.6256 9.67628 16.6256C5.93404 16.6256 2.90036 13.659 2.90036 9.99938C2.90036 6.33979 5.93404 3.37311 9.67628 3.37311Z" fill="url(#svg-grad)"/>
<defs>
<linearGradient  id="svg-grad" x1="2.67855" y1="16.9619" x2="17.2141" y2="3.66376" gradientUnits="userSpaceOnUse">
<stop className="start-color" />
          <stop offset="1" className="end-color" />
</linearGradient>
</defs>
</svg>
   
  );
}

export default SearchHeader;

import React from 'react'

function AppleIcon() {
  return (
    <svg width="31" height="39" viewBox="0 0 31 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.5824 20.284C25.5664 17.1705 26.8895 14.8205 29.5675 13.0898C28.0691 10.8077 25.8055 9.55215 22.8167 9.30612C19.9872 9.06858 16.8948 11.0622 15.763 11.0622C14.5675 11.0622 11.8257 9.39096 9.67375 9.39096C5.22636 9.46731 0.5 13.1662 0.5 20.6912C0.5 22.914 0.882572 25.2102 1.64772 27.58C2.66791 30.6935 6.35016 38.3288 10.1918 38.2016C12.2003 38.1507 13.619 36.683 16.2333 36.683C18.7678 36.683 20.0829 38.2016 22.3225 38.2016C26.1961 38.1422 29.5276 31.2025 30.5 28.0805C25.3034 25.476 25.5824 20.4452 25.5824 20.284ZM21.0712 6.3538C23.2471 3.60508 23.0478 1.1024 22.9841 0.203125C21.0632 0.321897 18.8395 1.59445 17.5723 3.16393C16.1775 4.8437 15.3565 6.9222 15.5319 9.2637C17.6121 9.43338 19.509 8.29656 21.0712 6.3538Z" fill="white"/>
</svg>

  )
}

export default AppleIcon
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getProductByID, getRelatedProductsRandom } from "../services/ProductService";
import Minus from "../static/Product/minus.svg";
import Plus from "../static/Product/plus.svg";

import Accordion from "react-bootstrap/Accordion";
import parse from "html-react-parser";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useDispatch, useSelector } from "react-redux";
import { addTOCart } from "../redux/cartSlice";
import { showAlert, hideAlert } from "../redux/alertSlice";
import { getLocalData } from "../utils/localStorage";

import ProductGridItem from "../components/ProductGridItem";


import CartIn from '../components/SvgComponents/Bag'

import { addToCart, getCartItems } from "../services/CartService";
import NoImage from "../static/noimagegray.svg";
import Wishlist from "../components/SvgComponents/Wishlist";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { formatNumber } from "../utils/numberFormater";

function ProductDetails() {
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const [imageGallery, setImageGallery] = useState([]);

  const defaultImage = [
    {
      original: NoImage,

      thumbnail: NoImage,
    },
  ];

  const handleQuantityIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleQuantityDecrement = () => {
    setQuantity(quantity - 1);
  };

  const { id } = useParams();

  const dispatch = useDispatch();

  const outletIdM = getLocalData('outlet-id')


  useEffect(() => {
    getProductByID(id, outletIdM).then(
      (res) => {

        console.log(res)
        setProduct(res);

        let tempMediaGallery = [];


        res.mediaPictures.forEach(picture => {
          let tempVar = {
            original: picture.pictureUrl,
            thumbnail: picture.pictureUrl,
          };
          tempMediaGallery.push(tempVar);
        });

        setImageGallery(tempMediaGallery);
      },
      (failure) => {
        console.error(failure); //expected output: Oopsy...
      }
    );
  }, [id, outletIdM]);

  //userinfo
  const userData = useSelector((state) => state.user.userData);

  //currency
  const currency = useSelector((state) => state.pharmacy.currencySymbol);

  //wishlist functionality
  const allAssociatedProducts = useSelector((state) => state.cart.cartItems);

  const handleClickOnWishlist = (id) => {
    if (JSON.stringify(userData) !== "{}") {
      const isProductPresentInWishList = allAssociatedProducts.find(
        (o) => o.productId === parseInt(id) && o.shoppingCartTypeId === 2
      );

      dispatch(hideAlert());

      let tempProduct = {
        productId: parseInt(product.id),
        quantity: 1,
        shoppingCartTypeId: 2,
      };

      if (!isProductPresentInWishList) {
        let tempArrForAddTOCart = [];



        allAssociatedProducts.forEach((item) => {
          let tempItem = {
            productId: item.productId,
            quantity: item.quantity,
            shoppingCartTypeId: item.shoppingCartTypeId,
          };

          tempArrForAddTOCart.push(tempItem);
        });

        tempArrForAddTOCart.push(tempProduct);

        console.log("jhgjhg", userData.outletId)
        addToCart(
          getLocalData("user-info")?.userData.id,
          outletIdM,
          getLocalData("user-info")?.userToken,
          tempArrForAddTOCart
        )
          .then((data) => {
            console.log(data);
            if (data === "Success") {
              console.log("yo9")
              getCartItems(getLocalData("user-info")?.userToken, getLocalData("user-info")?.userData?.id, getLocalData("outlet-id")).then(
                (res) => {
                  dispatch(addTOCart(res));
                },
                (failure) => {
                  console.error(failure); //expected output: Oopsy...
                }
              );
            }
          })
          .catch((err) => console.log(err));
        let alert = {
          state: "success",
          msg: `${product.name} Added to Wishlist`,
        };
        dispatch(showAlert(alert));
      } else {
        let alert = {
          state: "danger",
          msg: `${product.name} is already in Wishlist`,
        };
        dispatch(showAlert(alert));
      }
    } else if (JSON.stringify(userData) === "{}") {
      let alert = {
        state: "danger",
        msg: `Please Login/Sign-Up to Continue`,
      };
      dispatch(showAlert(alert));
    }
  };

  const handleClickOnAddTOCart = (id) => {
    if (JSON.stringify(userData) !== "{}") {
      const isProductPresentInWishList = allAssociatedProducts.find(
        (o) => o.productId === parseInt(id) && o.shoppingCartTypeId === 1
      );

      dispatch(hideAlert());

      let tempProduct = {
        productId: parseInt(product.id),
        quantity: quantity,
        shoppingCartTypeId: 1,
      };

      if (!isProductPresentInWishList) {
        let tempArrForAddTOCart = [];

        allAssociatedProducts.forEach((item) => {
          let tempItem = {
            productId: item.productId,
            quantity: item.quantity,
            shoppingCartTypeId: item.shoppingCartTypeId,
          };

          tempArrForAddTOCart.push(tempItem);
        });

        tempArrForAddTOCart.push(tempProduct);

        addToCart(
          getLocalData("user-info")?.userData.id,
          outletIdM,
          getLocalData("user-info")?.userToken,
          tempArrForAddTOCart
        )
          .then((data) => {
            console.log(data);
            if (data === "Success") {
              console.log("yo10")
              getCartItems(getLocalData("user-info")?.userToken, getLocalData("user-info")?.userData?.id, getLocalData("outlet-id")).then(
                (res) => {
                  dispatch(addTOCart(res));
                },
                (failure) => {
                  console.error(failure); //expected output: Oopsy...
                }
              );
            }
          })
          .catch((err) => console.log(err));
        // let alert = {
        //   state: "success",
        //   msg: `${product.name} Added to Cart`,
        // };
        // dispatch(showAlert(alert));
      } else {
        let tempArrForAddTOCart = [];

        allAssociatedProducts.forEach((item) => {
          let tempItem = {
            productId: item.productId,
            quantity: item.quantity,
            shoppingCartTypeId: item.shoppingCartTypeId,
          };

          if (item.productId == tempProduct.productId) {
            tempItem.quantity = tempItem.quantity + tempProduct.quantity;
          }
          tempArrForAddTOCart.push(tempItem);
        });

        addToCart(
          getLocalData("user-info")?.userData.id,
          outletIdM,
          getLocalData("user-info")?.userToken,
          tempArrForAddTOCart
        )
          .then((data) => {

            if (data === "Success") {
              console.log("yo11")
              getCartItems(getLocalData("user-info")?.userToken, getLocalData("user-info")?.userData?.id, getLocalData("outlet-id")).then(
                (res) => {
                  dispatch(addTOCart(res));
                },
                (failure) => {
                  console.error(failure); //expected output: Oopsy...
                }
              );
            }
          })
          .catch((err) => console.log(err));
        // let alert = {
        //   state: "success",
        //   msg: `${product.name} Added to Cart`,
        // };
        // dispatch(showAlert(alert));
      }
    } else if (JSON.stringify(userData) === "{}") {
      let alert = {
        state: "danger",
        msg: `Please Login/Sign-Up to Continue`,
      };
      dispatch(showAlert(alert));
    }
  };

  const { t } = useTranslation();



  const [relatedProducts, setRelatedProducts] = useState([])

  useEffect(() => {
    getRelatedProductsRandom(id).then((data) => {
      setRelatedProducts(data.products);

    })
      .catch((err) => console.log(err));
  }, [id])

  // slick settings
  let dragging = false;
  const settings = {
    dots: false,
    infinite: relatedProducts.length > 4 ? true : false,

    slidesToShow: 4,
    slidesToScroll: 1,

    beforeChange: () => (dragging = true),
    afterChange: () => (dragging = false),

    autoplay: true,
    speed: 400,
    autoplaySpeed: 1500,
    responsive: [

      {
        breakpoint: 480,
        settings: {
          infinite: relatedProducts.length > 2 ? true : false,
          centerPadding: "20px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const pharmacy = useSelector((state) => state.pharmacy.pharmacyDetails.pharmacy)

  const isStock = (product) => {
    if (!pharmacy.isInventoryLiteEnabled || !pharmacy.isPosEnabled) {
      return true;
    } else {
      return product.stockQuantity > 0;
    }
  };

  return (
    product && (
      <section className="space-at-top all-back">
        <div className="container">
          <Row className="pb-2">
            <Col md={4}></Col>
          </Row>
          <Row>
            <Col md={5} className="">
              {imageGallery[0].original !== "" ? (
                <ImageGallery
                  items={imageGallery}
                  showBullets={true}
                  showFullscreenButton={false}
                  showPlayButton={false}
                />
              ) : (
                <ImageGallery
                  items={defaultImage}
                  showBullets={true}
                  showFullscreenButton={false}
                  showPlayButton={false}
                />
              )}
            </Col>
            <Col md={7}>
              <Row>
                <Col md={10}>
                  <span className="pd-name">{product.name}</span>
                </Col>
                <Col md={2}>
                  <span
                    className="wish-prod-det "
                    onClick={() => {
                      handleClickOnWishlist(product.id);
                    }}
                  >
                    <Wishlist />

                  </span>
                </Col>
                <Col md={12}>
                  <span className="pd-manufacturer-name">
                    {
                      product.manufacturerName !== '' && (
                        `${t("pdp-t-1")} ${product.manufacturerName} `
                      )
                    }

                  </span>
                </Col>
                {
                  product.drugTypeValue === 'PrescriptionDrug' && (
                    <Col md={12}>
                      <span className="pd-type"> {t("pdp-t-2")}</span>
                    </Col>
                  )
                }

                <Col md={12}>
                  <span className="pd-price">{currency}  {formatNumber(product.price)}  {
                    product.oldPrice && (
                      <span className="disc-price-pd">  {`${formatNumber((100 - (product.price / product.oldPrice) * 100))

                        }%`}
                        {t("pdp-t-3")}</span>
                    )
                  }  </span>
                </Col>
                <Col md={12}>
                  {product.oldPrice && (
                    <>
                      <span className="pd-old-price">{currency}  {formatNumber(product.oldPrice)}</span>
                      <span className="pd-disc">

                      </span>
                    </>
                  )}
                </Col>
                {
                  product.stockQuantity > 0 && (
                    <Col md={5} xs={5}>

                      <span className="pd-quantity-header"> {t("pdp-t-4")}</span>
                      <div className="pd-cart-button-grp">
                        <button
                          className="btn btn-secondary"
                          disabled={quantity === 1 ? "disabled" : ""}
                          onClick={() => {
                            handleQuantityDecrement();
                          }}
                        >
                          <img src={Minus} alt="minus" />
                        </button>
                        <span>{quantity}</span>
                        <button
                          className="btn btn-secondary"
                          disabled={quantity === 100 ? "disabled" : ""}
                          onClick={() => {
                            handleQuantityIncrement();
                          }}
                        >
                          <img src={Plus} alt="plus" />
                        </button>
                      </div>
                    </Col>
                  )

                }


                <Col md={6} xs={6} className="d-flex justify-content-center align-items-end">
                  {
                    isStock(product) > 0 ? (<button
                      className="btn pd-btn pd-atc pdetails-btn mb-0"
                      onClick={() => {
                        handleClickOnAddTOCart(product.id);
                      }}
                    >
                      <CartIn />

                      {t("pdp-t-5")}
                    </button>) : (<button
                      className="btn pd-btn pd-atc"

                    >
                      {t("pdp-t-6")}
                    </button>)
                  }

                </Col>
                <Col md={1}></Col>

                <Col md={12} className="pd-accordians">

                  <Accordion defaultActiveKey={["0"]} alwaysOpen>

                    <Accordion.Item eventKey="0">

                      {
                        (
                          product.fullDescription) && (
                          <>
                            <Accordion.Header>
                              <span>
                                <span className="pd-accordian-h">{t("pdp-t-8")}  </span>

                              </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              {product.fullDescription &&
                                parse(product.fullDescription)}
                            </Accordion.Body>
                          </>
                        )
                      }

                    </Accordion.Item>

                    {
                      (product.therapeuticName ||
                        product.genericName) && (
                        <Accordion.Item eventKey="4">

                          {
                            (product.therapeuticName ||
                              product.genericName) && (
                              <>
                                <Accordion.Header>
                                  <span>
                                    <span className="pd-accordian-h">{t("pdp-t-30")}  </span>

                                  </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                  {
                                    product.therapeuticName && (
                                      <div className="accordian-body">
                                        <span className="pd-abh"> {t("pdp-t-28")}   </span>
                                        <span className="pd-abt">  {product.therapeuticName}</span>
                                      </div>
                                    )
                                  }

                                  {
                                    product.genericName && (
                                      <div className="accordian-body">
                                        <span className="pd-abh">{t("pdp-t-29")} </span>
                                        <span className="pd-abt">{product.genericName}</span>
                                      </div>
                                    )
                                  }


                                </Accordion.Body>
                              </>
                            )
                          }

                        </Accordion.Item>
                      )
                    }



                    {
                      (product.adultDosage || product.childrenDosage || product.indication || product.contraIndication || product.availabiltyPackage || product.storageCondition) && (
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            {t("pdp-t-9")}
                          </Accordion.Header>
                          <Accordion.Body>


                            {product.indication && (
                              <div className="accordian-body">
                                <span className="pd-abh">{t("pdp-t-12")} </span>
                                <span className="pd-abt">{product.indication}</span>
                              </div>
                            )}

                            {product.contraIndication && (
                              <div className="accordian-body">
                                <span className="pd-abh">{t("pdp-t-13")} </span>
                                <span className="pd-abt">
                                  {product.contraIndication}
                                </span>
                              </div>
                            )}

                            {product.adultDosage && (
                              <div className="accordian-body">
                                <span className="pd-abh">{t("pdp-t-10")} </span>
                                <span className="pd-abt">
                                  {product.adultDosage}
                                </span>
                              </div>
                            )}

                            {product.childrenDosage && (
                              <div className="accordian-body">
                                <span className="pd-abh">{t("pdp-t-11")} </span>
                                <span className="pd-abt">
                                  {product.childrenDosage}
                                </span>
                              </div>
                            )}

                            {product.availabiltyPackage && (
                              <div className="accordian-body">
                                <span className="pd-abh">{t("pdp-t-14")} </span>
                                <span className="pd-abt">
                                  {product.availabiltyPackage}
                                </span>
                              </div>
                            )}

                            {product.storageCondition && (
                              <div className="accordian-body">
                                <span className="pd-abh">{t("pdp-t-15")} </span>
                                <span className="pd-abt">
                                  {product.storageCondition}
                                </span>
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      )}

                    {
                      (product.cautions ||
                        product.sideEffectsOrAdverseEffects ||
                        product.precautionOrWarning ||
                        product.symptomsAndOverdoseTreatment ||
                        product.drugInteractions) && (
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            {t("pdp-t-16")}
                          </Accordion.Header>
                          <Accordion.Body>


                            {product.sideEffectsOrAdverseEffects && (
                              <div className="accordian-body">
                                <span className="pd-abh">
                                  {t("pdp-t-18")}
                                </span>
                                <span className="pd-abt">
                                  {product.sideEffectsOrAdverseEffects}
                                </span>
                              </div>
                            )}

                            {product.precautionOrWarning && (
                              <div className="accordian-body">
                                <span className="pd-abh">
                                  {t("pdp-t-19")}
                                </span>
                                <span className="pd-abt">
                                  {product.precautionOrWarning}
                                </span>
                              </div>
                            )}

                            {product.drugInteractions && (
                              <div className="accordian-body">
                                <span className="pd-abh">{t("pdp-t-21")}</span>
                                <span className="pd-abt">
                                  {product.drugInteractions}
                                </span>
                              </div>
                            )}

                            {product.cautions && (
                              <div className="accordian-body">
                                <span className="pd-abh">{t("pdp-t-17")}</span>
                                <span className="pd-abt">{product.cautions}</span>
                              </div>
                            )}

                            {product.symptomsAndOverdoseTreatment && (
                              <div className="accordian-body">
                                <span className="pd-abh">
                                  {t("pdp-t-20")}
                                </span>
                                <span className="pd-abt">
                                  {product.symptomsAndOverdoseTreatment}
                                </span>
                              </div>
                            )}


                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    }
                    {
                      (product.pregnancyAlert ||
                        product.lactationAlert ||
                        product.drivingAlert ||
                        product.liverAlert ||
                        product.kidneyAlert) && (
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            {t("pdp-t-22")}
                          </Accordion.Header>
                          <Accordion.Body>
                            {product.pregnancyAlert && (
                              <div className="accordian-body">
                                <span className="pd-abh">{t("pdp-t-23")}</span>
                                <span className="pd-abt">
                                  {product.pregnancyAlert}
                                </span>
                              </div>
                            )}

                            {product.lactationAlert && (
                              <div className="accordian-body">
                                <span className="pd-abh">{t("pdp-t-24")}</span>
                                <span className="pd-abt">
                                  {product.lactationAlert}
                                </span>
                              </div>
                            )}

                            {product.drivingAlert && (
                              <div className="accordian-body">
                                <span className="pd-abh">{t("pdp-t-25")}</span>
                                <span className="pd-abt">
                                  {product.drivingAlert}
                                </span>
                              </div>
                            )}

                            {product.liverAlert && (
                              <div className="accordian-body">
                                <span className="pd-abh">{t("pdp-t-26")}</span>
                                <span className="pd-abt">{product.liverAlert}</span>
                              </div>
                            )}

                            {product.kidneyAlert && (
                              <div className="accordian-body">
                                <span className="pd-abh">{t("pdp-t-27")}</span>
                                <span className="pd-abt">
                                  {product.kidneyAlert}
                                </span>
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    }

                  </Accordion>
                </Col>

                <Col md={12}></Col>
              </Row>
            </Col>


          </Row>
        </div>
        <div className="container all-back mt-4">

          {
            relatedProducts.length > 0 && (
              <Col md={12} className="slick-container pt-5">
                <span class="pd-name">Related Products</span>

                <Row className="mobile-grid mt-5">

                  <Slider {...settings}>
                    {relatedProducts.map((item) => {
                      return <ProductGridItem
                        imgUrl={item.pictureUrl}
                        name={item.name}
                        brand={item.manufacturerName}
                        oldPrice={item.oldPrice}
                        price={item.price}
                        id={item.id}
                        enableWishlist={true}
                        type={item.drugType}
                        outOfStock={isStock(product)}
                      />;
                    })}
                  </Slider>

                </Row>
              </Col>
            )
          }
        </div>
      </section>
    )
  );
}

export default ProductDetails;

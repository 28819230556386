import React from 'react'

function Wish() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="36" viewBox="0 0 42 36" fill="none">
<path d="M41.25 11.25C41.25 16.9875 37.9312 22.875 31.3875 28.7625C28.4161 31.4338 25.1807 33.7959 21.7312 35.8125C21.5072 35.9356 21.2557 36.0001 21 36.0001C20.7443 36.0001 20.4928 35.9356 20.2687 35.8125C19.4625 35.3625 0.75 24.7312 0.75 11.25C0.75 8.88882 1.49292 6.5875 2.87353 4.67201C4.25413 2.75652 6.20243 1.32398 8.44244 0.577313C10.6824 -0.169357 13.1006 -0.192304 15.3544 0.511723C17.6082 1.21575 19.5833 2.61106 21 4.5C22.4167 2.61106 24.3918 1.21575 26.6456 0.511723C28.8994 -0.192304 31.3176 -0.169357 33.5576 0.577313C35.7976 1.32398 37.7459 2.75652 39.1265 4.67201C40.5071 6.5875 41.25 8.88882 41.25 11.25Z" fill="url(#svg-grad)"/>
<defs>
        <linearGradient
          id="svg-grad"
          x1="0.584961"
          y1="15.5625"
          x2="15.885"
          y2="1.56197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0854" className="start-color" />
          <stop offset="0.922" className="end-color" />
        </linearGradient>
      </defs>
</svg>
  )
}

export default Wish
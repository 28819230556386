import React from "react";

function AddressSvg(props) {
  let color = "svg-grad-disabled";
  if (props.isActive) {
    color = "svg-grad";
  }
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99996 0.833374C4.14996 0.833374 0.666626 3.78504 0.666626 8.35004C0.666626 11.3934 3.11413 14.9959 7.99996 19.1667C12.8858 14.9959 15.3333 11.3934 15.3333 8.35004C15.3333 3.78504 11.85 0.833374 7.99996 0.833374ZM7.99996 10C6.99163 10 6.16663 9.17504 6.16663 8.16671C6.16663 7.15837 6.99163 6.33337 7.99996 6.33337C9.00829 6.33337 9.83329 7.15837 9.83329 8.16671C9.83329 9.17504 9.00829 10 7.99996 10Z"
        fill={`url(#${color})`}
      />
       <defs>
        <linearGradient
          id={color}
          x1="0.584961"
          y1="15.5625"
          x2="15.885"
          y2="1.56197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0854" className="start-color" />
          <stop offset="0.922" className="end-color" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default AddressSvg;

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import { authentication } from "../../utils/firebase";
import OtpInput from "react-otp-input";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import { clientLoginPhoneNumber } from "../../services/UserService";
import { setLocalData } from "../../utils/localStorage";

import { useDispatch } from "react-redux";
import { updateUser } from "../../redux/userSlice";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import swal from "sweetalert";

import Countdown from "react-countdown-now";

function Phone(props) {


  const {formValues, setFormValues, setOtpModal, setIsPhoneVerified} = props

  let startDate = React.useRef(Date.now());

  const [phoneNumber, setPhoneNumber] = useState({
    countryCode: `${process.env.REACT_APP_DEFAULT_COUNTRYCODE}`,
    number: "",
  });

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recapta-verify",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      },
      authentication
    );
  };

  const getOtp = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!window.recaptchaVerifier) {
      generateRecaptcha();
    }

    let appVerifier = window.recaptchaVerifier;
    let finalPhone = "+" + phoneNumber.countryCode + phoneNumber.number;

    signInWithPhoneNumber(authentication, finalPhone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        
        window.confirmationResult = confirmationResult;
        setOptGenerated(true);
        setLoading(false);
        window.recaptchaVerifier = null;
        setIsCounterCompleted(false);
        startDate.current = Date.now();
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Can't Send Otp",
          text: "Enter a valid Phone Number",
        });
        console.log(error)
        window.recaptchaVerifier.render().then(function (widgetId) {
          window.recaptchaVerifier.recaptcha.reset(widgetId);
        });
        setLoading(false);
      });
  };

  const [loading, setLoading] = useState(false);
  const [optGenerated, setOptGenerated] = useState(false);
  const [otp, setOtp] = useState("");

  const [verifyOptLoading, setVerifyOptLoading] = useState(false);

  const handleChange = (otpN) => {
    setOtp(otpN);
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const verifyOtp = () => {
    setVerifyOptLoading(true);
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;

      confirmationResult
        .confirm(otp)
        .then((result) => {
          setVerifyOptLoading(false);

          setFormValues({
            ...formValues,
            countryCode: phoneNumber.countryCode,
            phoneNumber: phoneNumber.number

          })
          setOtpModal(false)
          setIsPhoneVerified(true)
         
        })
        .catch((error) => {
          setVerifyOptLoading(false);
          swal({
            icon: "error",
            title: "OTP Verification failed",
            text: "Enter a valid OTP",
          });
        });
    }
  };

  const changeNumber = () => {
    setPhoneNumber({
      countryCode: `${process.env.REACT_APP_DEFAULT_COUNTRYCODE}`,
      number: "",
    });
    setOtp("");
    setOptGenerated(false);
    window.recaptchaVerifiertwo = null;
  };

  const regenerateRecaptcha = () => {
    window.recaptchaVerifiertwo = new RecaptchaVerifier(
      "recapta-verify",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      },
      authentication
    );
  };

  const resendOtp = (e) => {
    e.preventDefault();

    if (!window.recaptchaVerifiertwo) {
      regenerateRecaptcha();
    }

    let appVerifier2 = window.recaptchaVerifiertwo;
    let finalPhone = "+" + phoneNumber.countryCode + phoneNumber.number;

    signInWithPhoneNumber(authentication, finalPhone, appVerifier2)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
       
        window.confirmationResult = confirmationResult;
        setIsCounterCompleted(false);
        startDate.current = Date.now()
        //window.recaptchaVerifier = null;
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Can't Send Otp",
          text: "Something went wrong",
        });
        window.recaptchaVerifiertwo.render().then(function (widgetId) {
          window.recaptchaVerifiertwo.recaptcha.reset(widgetId);
        });
      });
  };

  const [isCounterCompleted, setIsCounterCompleted] = useState(false);

  
  

  return (
    <div className="">
      {!optGenerated && (
        <form onSubmit={getOtp}>
          <Row>
            <Col md={12} className="text-center">
              <div id="recapta-verify"></div>
            </Col>

            <Col md={12}>
              <Row className="phone-no-grp">
                <Col md={4}>
                  <PhoneInput
                    inputProps={{
                      disabled: true,
                    }}
                    inputClass="phone-code"
                    buttonClass="phone-code-dropDown"
                    country={"us"}
                    value={phoneNumber.countryCode}
                    onChange={(phone) =>
                      setPhoneNumber({ ...phoneNumber, countryCode: phone })
                    }
                  />
                </Col>
                <Col md={8}>
                  <input
                    placeholder="Enter Phone Number"
                    className="phoneNumber-login"
                    value={phoneNumber.number}
                    type="number"
                    onChange={(e) =>
                      setPhoneNumber({ ...phoneNumber, number: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col md={12} className="text-center">
              <Button
                type="submit"
                disabled={loading ? "disabled" : ""}
                className="btn pd-btn pd-atc mt-3"
              >
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                <span className="visually-hidden">Loading...</span>
                SEND OTP
              </Button>
            </Col>
          </Row>
        </form>
      )}
      {optGenerated && (
        <form onSubmit={resendOtp}>
          <Row>
            <Col md={12} className="text-center">
              <div id="recapta-verify"></div>
            </Col>
            <Col md={12} className="verify-1">
              {" "}
              Enter OTP
            </Col>
            <Col md={12} className="verify-2">
              {" "}
              We've sent a code to your number
            </Col>
            <Col md={12} className="verify-3">
              {" "}
              +{phoneNumber.countryCode} {phoneNumber.number}
            </Col>
            <Col md={12} className="text-center otp-boxes mb-4">
              <OtpInput value={otp} onChange={handleChange} numInputs={6} />
            </Col>
            <Col md={5} className="verify-4" onClick={() => changeNumber()}>
              CHANGE NUMBER
            </Col>
            <Col md={7} className="verify-5">
              <button disabled={isCounterCompleted ? "" : "disabled"}>
                {" "}
                RESEND OTP
              </button>

              {!isCounterCompleted && (
                <span>
                  {/* <Countdown
                    date={dateNow + 60000}
                    renderer={(props) => <div>in {props.seconds} seconds</div>}
                    onComplete={() => {
                      setIsCounterCompleted(true);
                    }}
                  /> */}

                  <Countdown
                    
                    date={startDate.current + 20000}
                    renderer={(props) => <div>in {props.seconds} seconds</div>}
                    onComplete={() => {
                      setIsCounterCompleted(true);
                    }}
                  />
                </span>
              )}
            </Col>

            <Col md={12} className="text-center">
              <Button onClick={verifyOtp} className="btn pd-btn pd-atc mt-4">
                {verifyOptLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Verify OTP
              </Button>
            </Col>
          </Row>
        </form>
      )}
    </div>
  );
}

export default Phone;

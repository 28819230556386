import React from "react";

function Uback2() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none"
    >
      
      <path xmlns="http://www.w3.org/2000/svg" d="M13.95 19.65L15.1 18.5L13.5 16.9L12.35 18.05C12.1167 18.2833 12 18.55 12 18.85C12 19.15 12.1167 19.4167 12.35 19.65C12.5833 19.8833 12.85 20 13.15 20C13.45 20 13.7167 19.8833 13.95 19.65ZM16.5 17.1L17.65 15.95C17.8833 15.7167 18 15.45 18 15.15C18 14.85 17.8833 14.5833 17.65 14.35C17.4167 14.1167 17.15 14 16.85 14C16.55 14 16.2833 14.1167 16.05 14.35L14.9 15.5L16.5 17.1ZM15.375 21.075C14.7583 21.6917 14.0167 22 13.15 22C12.2833 22 11.5417 21.6917 10.925 21.075C10.3083 20.4583 10 19.7167 10 18.85C10 17.9833 10.3083 17.2417 10.925 16.625L14.625 12.925C15.2417 12.3083 15.9833 12 16.85 12C17.7167 12 18.4583 12.3083 19.075 12.925C19.6917 13.5417 20 14.2833 20 15.15C20 16.0167 19.6917 16.7583 19.075 17.375L15.375 21.075ZM8.125 20H0V2H6.2C6.41667 1.4 6.77933 0.916667 7.288 0.55C7.796 0.183333 8.36667 0 9 0C9.63333 0 10.2043 0.183333 10.713 0.55C11.221 0.916667 11.5833 1.4 11.8 2H18V10.125C17.6667 10.0417 17.3333 10 17 10C16.6667 10 16.3333 10.025 16 10.075V4H2V18H8.075C8.025 18.3333 8 18.6667 8 19C8 19.3333 8.04167 19.6667 8.125 20ZM9 3.25C9.21667 3.25 9.396 3.179 9.538 3.037C9.67933 2.89567 9.75 2.71667 9.75 2.5C9.75 2.28333 9.67933 2.104 9.538 1.962C9.396 1.82067 9.21667 1.75 9 1.75C8.78333 1.75 8.60433 1.82067 8.463 1.962C8.321 2.104 8.25 2.28333 8.25 2.5C8.25 2.71667 8.321 2.89567 8.463 3.037C8.60433 3.179 8.78333 3.25 9 3.25ZM4 8V6H14V8H4ZM4 12V10H14V10.85C13.8667 10.9333 13.7377 11.029 13.613 11.137C13.4877 11.2457 13.3583 11.3667 13.225 11.5L12.725 12H4ZM4 16V14H10.725L9.5 15.225C9.36667 15.3583 9.246 15.4877 9.138 15.613C9.02933 15.7377 8.93333 15.8667 8.85 16H4Z"  fill="url(#svg-grad)"/>
      <defs>
        <linearGradient
          id="svg-grad"
          x1="0.584961"
          y1="15.5625"
          x2="15.885"
          y2="1.56197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0854" className="start-color" />
          <stop offset="0.922" className="end-color" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Uback2;

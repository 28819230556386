import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../static/logos/logo.PNG";

import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";

import UserDropDown from "./Header/UserDropDown";
import { UpdatePatientProfile } from "../services/UserService";
import { getLocalData, setLocalData } from "../utils/localStorage";
import { updateUser } from "../redux/userSlice";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Divide as Hamburger } from "hamburger-react";
import Wishlist from "./SvgComponents/WishlistBlack";
import User from "./SvgComponents/UserBlack";
import Cart from "./SvgComponents/CartBlack";

import { useTranslation } from "react-i18next";
import BigInput from "./FocusedSearch/BigInput";
import CartDrop from "./Header/CartDrop";


import HeaderLang from "./Header/HeaderLang";

function HeaderNew() {
  const { i18n, t } = useTranslation();

  let location = useLocation();
  const navigate = useNavigate();
  const [fix, setFix] = useState(true);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => { }, [location]);

  const noOfProductsInWishlist = useSelector(
    (state) => state.cart.noOfProductsInWishList
  );
  const noOfProductsInCart = useSelector(
    (state) => state.cart.noOfProductsInCart
  );

  const [outlet, setoutlet] = useState(null);
  const [options, setOptions] = React.useState([]);
  const userData = useSelector((state) => state.user.userData);

  const userTokenMain = getLocalData("user-info")?.userToken;


  const dispatch = useDispatch();



  //when changing the outlet id for a logged in user
  useEffect(() => {
    if (JSON.stringify(userData) !== "{}") {
      if (outlet) {
        if (outlet.id !== userData.outletId) {
          var tempUser = {
            ...userData,
            outletId: outlet.id,
          };
          var tempTOken = getLocalData("user-info")?.userToken;
          UpdatePatientProfile(tempUser, tempTOken)
            .then((data) => {
              if (data.status === "Success") {
                let tempObj = {
                  userData: data.response.patientData,
                  userToken: tempTOken,
                };
                setLocalData("user-info", tempObj);
                dispatch(updateUser(data.response.patientData));
                setLocalData("outlet-id", outlet.id);
              }
            })
            .catch((err) => console.log(err));
        }
      }
    }
  }, [outlet]);

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (getLocalData("user-info")?.userToken?.length > 0) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    //console.log("data changed" + loggedIn);
  }, [getLocalData("user-info")?.userToken]);

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  const [drawerState, setDrawerState] = useState(false);

  const [focusSearch, setFocusSearch] = useState(false);
  const [focusOutletSel, setFocusOutletSel] = useState(false);






  return (
    <>
      {windowDimenion.winWidth > 787 && (
        <div className={`header-main`}>
          <div className="">
            <div className=" header-parent container">
              <div className="header-logo">
                <Link to="/">
                  <img alt="logo" className="img-fluid logoheader" src={logo} />
                </Link>
              </div>
              <div className="header-input">
                <BigInput />

              </div>
              <div className="header-buttons">
                <HeaderLang />
                <UserDropDown />
                {loggedIn ? (
                  <>
                    <Link to="/wishlist" className="round-btn-header d-none">
                      <Wishlist />
                    </Link>

                    <CartDrop />

                  </>
                ) : (
                  <>
                    <Link to="/login" className="round-btn-header d-none">
                      <Wishlist />
                    </Link>
                    <Link to="/login" className="round-btn-header">
                      <Cart />
                    </Link>
                  </>
                )}
              </div>
            </div>
            <div className="nav-parent">
              <div className="container">
                <div className="header-navbar">
                  <ul className="hlinks">
                    <li>
                      <Link
                        className={`header-text ${location.pathname === "/" ? "active" : ""
                          }`}
                        to="/"
                      >
                        {t("hc-t-1")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`header-text ${location.pathname === "/categories" ? "active" : ""
                          }`}
                        to="/categories"
                      >
                        {t("hc-t-2")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`header-text ${location.pathname === "/products" ? "active" : ""
                          }`}
                        to="/products"
                      >
                        {t("hc-t-8")}
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={`header-text ${location.pathname === "/brands" ? "active" : ""
                          }`}
                        to="/brands"
                      >
                        {t("hc-t-3")}

                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`header-text ${location.pathname === "/allofferrproducts"
                          ? "active"
                          : ""
                          }`}
                        to="/allofferrproducts"
                      >
                        {t("hc-t-4")}

                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`header-text ${location.pathname === "/featuredproducts"
                          ? "active"
                          : ""
                          }`}
                        to="/featuredproducts"
                      >
                        {t("hc-t-5")}

                      </Link>
                    </li>
                    <li>
                      <Link className={`header-text ${location.pathname === "/outlets"
                        ? "active"
                        : ""
                        }`} to="/outlets">
                        {t("hc-t-9")}
                      </Link>
                    </li>
                  </ul>
                  <Link
                    to="/#download-apps"
                    className="hero-h-download-btn btn get-app"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="22"
                      viewBox="0 0 14 22"
                      fill="none"
                    >
                      <path
                        d="M11 0H3C1.34 0 0 1.34 0 3V19C0 20.66 1.34 22 3 22H11C12.66 22 14 20.66 14 19V3C14 1.34 12.66 0 11 0ZM12 17H2V3H12V17ZM9 20H5V19H9V20Z"
                        fill="black"
                      />
                    </svg>
                    <span> {t("hc-t-6")} </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {windowDimenion.winWidth <= 787 && (
        <>
          <div className="mobile-header">
            <div className="mg-btn">
              <Hamburger
                color="#222222"
                size={25}
                toggled={drawerState}
                onToggle={(toggled) => {
                  if (toggled) {
                    setDrawerState(true);
                  } else {
                    setDrawerState(false);
                  }
                }}
              />
            </div>
            <div className="mg-logo">
              <Link
                to="/"
                onClick={() => {
                  setDrawerState(false);
                }}
              >
                <img alt="logo" className="img-fluid logoheader" src={logo} />
              </Link>
            </div>
            <div className="mg-user">
              {loggedIn ? (
                <>
                  <Link
                    to="/user"
                    className="round-btn-header"
                    onClick={() => {
                      setDrawerState(false);
                    }}
                  >
                    <User />
                  </Link>
                  <Link
                    to="/wishlist"
                    className="round-btn-header"
                    onClick={() => {
                      setDrawerState(false);
                    }}
                  >
                    <Wishlist />
                  </Link>
                  <Link
                    to="/cart"
                    className="round-btn-header"
                    onClick={() => {
                      setDrawerState(false);
                    }}
                  >
                    <Cart />
                    {noOfProductsInCart > 0 && (
                      <span className="counter">{noOfProductsInCart}</span>
                    )}
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/login"
                    className="round-btn-header"
                    onClick={() => {
                      setDrawerState(false);
                    }}
                  >
                    <User />
                  </Link>
                  <Link
                    to="/login"
                    className="round-btn-header"
                    onClick={() => {
                      setDrawerState(false);
                    }}
                  >
                    <Wishlist />
                  </Link>
                  <Link
                    to="/login"
                    className="round-btn-header"
                    onClick={() => {
                      setDrawerState(false);
                    }}
                  >
                    <Cart />
                  </Link>
                </>
              )}
            </div>
            <div className="mg-srch">
              < BigInput setDrawerState={setDrawerState} />
            </div>
          </div>
          <SwipeableDrawer
            anchor={"left"}
            open={drawerState}
            onClose={() => {
              setDrawerState(false);
            }}
            onOpen={() => {
              setDrawerState(true);
            }}
          >
            <div className="drawer-parent">
              <div className="drawer-sec">
                <Col xs={12}>
                  <Link
                    onClick={() => {
                      setDrawerState(false);
                    }}
                    className={`header-text ${location.pathname === "/" ? "active" : ""
                      }`}
                    to="/"
                  >
                    {t("hc-t-1")}

                  </Link>
                </Col>
                <Col xs={12}>
                  <Link
                    onClick={() => {
                      setDrawerState(false);
                    }}
                    className={`header-text ${location.pathname === "/categories" ? "active" : ""
                      }`}
                    to="/categories"
                  >
                    {t("hc-t-2")}
                  </Link>
                </Col>
                <Col xs={12}>
                  <Link
                    onClick={() => {
                      setDrawerState(false);
                    }}
                    className={`header-text ${location.pathname === "/products" ? "active" : ""
                      }`}
                    to="/products"
                  >
                    {t("hc-t-8")}
                  </Link>
                </Col>
                {/* <Col xs={12}>
                  <a
                    className="header-text"
                    href="/"
                    onClick={() => {
                      setDrawerState(false);
                    }}
                  >
                    Deals
                  </a>
                </Col> */}
                <Col xs={12}>
                  <Link
                    onClick={() => {
                      setDrawerState(false);
                    }}
                    className={`header-text ${location.pathname === "/brands" ? "active" : ""
                      }`}
                    to="/brands"
                  >
                    {t("hc-t-3")}

                  </Link>
                </Col>
                <Col xs={12}>
                  <Link
                    onClick={() => {
                      setDrawerState(false);
                    }}
                    className={`header-text ${location.pathname === "/allofferrproducts" ? "active" : ""
                      }`}
                    to="/allofferrproducts"
                  >
                    {t("hc-t-4")}

                  </Link>
                </Col>
                <Col xs={12}>
                  <Link
                    onClick={() => {
                      setDrawerState(false);
                    }}
                    className={`header-text ${location.pathname === "/featuredproducts" ? "active" : ""
                      }`}
                    to="/featuredproducts"
                  >
                    {t("hc-t-5")}

                  </Link>
                </Col>
                <Col xs={12}>
                  <Link
                    onClick={() => {
                      setDrawerState(false);
                    }}
                    className={`header-text ${location.pathname === "/outlets"
                      ? "active"
                      : ""
                      }`} to="/outlets">
                    Outlets
                  </Link>
                </Col>
              </div>
              <div className="drawer-logout">
                {JSON.stringify(userData) !== "{}" && (
                  <button
                    className="btn btn-danger btn-logout user-profile-logout"
                    onClick={() => {
                      localStorage.clear();
                      dispatch(updateUser({}));
                      navigate("/");
                    }}
                  >
                    {t("hc-t-7")}

                  </button>
                )}
              </div>
            </div>
          </SwipeableDrawer>
        </>
      )}
    </>
  );
}

export default HeaderNew;

import React, { useEffect } from "react";
import en from "../../static/icns/en.svg";
import es from "../../static/icns/es.svg";
import ar from "../../static/icns/ar.svg";
import fr from "../../static/icns/fr.svg";
import ar_iq from "../../static/icns/ar-iq.svg";

import {
  getContactInformationRedux,
  updateRTLSettings,
} from "../../redux/pharmacySlice";
import { useTranslation } from "react-i18next";
import { getLocalData, setLocalData } from "../../utils/localStorage";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

function HeaderLang() {

  const { i18n, t } = useTranslation();

  const [lang, setlang] = useState("en");
  const [flag, setFlag] = useState(en);

  const dispatch = useDispatch();

  function changeLanguage(e) {
    i18n.changeLanguage(e);
    if (e === "ar" || e === "arIQ") {
      document.dir = "rtl";
      dispatch(updateRTLSettings(true));
      if (e === "ar") {
        setFlag(ar)
      } else if (e === "arIQ") {
        setFlag(ar_iq)
      }
    } else {
      document.dir = "ltr";
      dispatch(updateRTLSettings(false));
      if (e === "en") {
        setFlag(en)
      } else if (e === 'es') {
        setFlag(es)
      } else if (e === 'fr') {
        setFlag(fr)
      }
    }

    setlang(e);

    setLocalData("lang-id", e)
  }


  useEffect(() => {
    let c = getLocalData("lang-id");

    if (getLocalData("lang-id")?.length > 0) {
      changeLanguage(c);
    } else {
      changeLanguage("en");
    }
  }, []);
  //language

  const supportedLanguages = useSelector(
    (state) => state.pharmacy.pharmacyDetails?.pharmacy?.supportedLanguages
  );

  return (
    <div className="hover-drop">
      <div className="langSelect">
        <span className="icon-globe globe">

          <img src={flag} alt={lang} />
        </span>

        {lang === 'en' && "English"}
        {lang === 'es' && "Español"}
        {lang === 'ar' && "عربى"}
        {lang === 'arIQ' && "عربى"}
        {lang === 'fr' && "French"}
      </div>
      <ul className="sub-menu">

        {
          supportedLanguages &&

            supportedLanguages?.length > 0

            ? supportedLanguages.map((item, index) => {

              if (item.name === "English-US") {
                return (
                  <li className="menu-item " key={index}>
                    <span onClick={() => changeLanguage("en")} >

                      <span className="globe">
                        <img src={en} alt="en" />
                      </span>
                      ENG
                    </span>
                  </li>
                )

              } else if (item.name === "Spanish-ES") {

                return (
                  <li className="menu-item" key={index}>
                    <span onClick={() => changeLanguage("es")} >
                      <span className="globe">
                        <img src={es} alt="es" />
                      </span>
                      ESP
                    </span>
                  </li>
                )
              } else if (item.name === "Arabic-EG") {
                return (
                  <li className="menu-item" key={index}>
                    <span onClick={() => changeLanguage("ar")} >
                      <span className="globe">
                        <img src={ar} alt="ar" />
                      </span>
                      عربى
                    </span>
                  </li>
                )

              } else if (item.name === "French-BJ") {
                return (
                  <li className="menu-item" key={index}>
                    <span onClick={() => changeLanguage("fr")} >
                      <span className="globe">
                        <img src={fr} alt="fr" />
                      </span>
                      FRA
                    </span>
                  </li>
                )
              } else if (item.name === "Arabic-IQ") {
                return (
                  <li className="menu-item" key={index}>
                    <span onClick={() => changeLanguage("arIQ")} >
                      <span className="globe">
                        <img src={ar_iq} alt="fr" />
                      </span>
                      عربى
                    </span>
                  </li>
                )
              }

            }) :
            (<li className="menu-item ">
              <span onClick={() => changeLanguage("en")} >

                <span className="globe">
                  <img src={en} alt="en" />
                </span>
                ENG
              </span>
            </li>)
        }
      </ul>
    </div>
  )
}

export default HeaderLang;
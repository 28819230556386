import React, { useCallback, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

//import mapStyles from "./MapStyles";
import "./map.css";
import { useState } from "react";
import { useRef } from "react";
import currentLocation from "./currentLoc.svg";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { useTranslation } from "react-i18next";

function MapT(props) {

  const { t } = useTranslation();
  const [libraries] = useState(["places"]);
  const { inputValues, setValues, setismapactivated, validation } = props;

  const mapContainerStyle = {
    width: "100%",
    height: "461px",
  };

  const initPoint = {
    lat: 17.347623643269607,
    lng: 78.49703099431717,
  };

  const [center, setCenter] = useState(initPoint);

  const returnAddressValues = (googleAddressArray) => {
    let valuesForInput = {
      address: googleAddressArray.formatted_address
        ? googleAddressArray.formatted_address
        : "",
      state: "",
      country: "",
      zipCode: "",
    };

    for (var i = 0; i < googleAddressArray.address_components.length; i++) {
      if (googleAddressArray.address_components[i].types[0] === "locality") {
        //this is the object you are looking for City
        let city = googleAddressArray.address_components[i];
      }
      if (
        googleAddressArray.address_components[i].types[0] ===
        "administrative_area_level_1"
      ) {
        //this is the object you are looking for State
        valuesForInput.state =
          googleAddressArray.address_components[i].long_name;
      }
      if (googleAddressArray.address_components[i].types[0] === "country") {
        //this is the object you are looking for
        valuesForInput.country =
          googleAddressArray.address_components[i].long_name;
      }
      if (googleAddressArray.address_components[i].types[0] === "postal_code") {
        //this is the object you are looking for
        valuesForInput.zipCode =
          googleAddressArray.address_components[i].long_name;
      }
    }
    return valuesForInput;
  };

  const onMapClick = useCallback(
    async (event) => {
      let valuesForInput = {
        address: "",
        state: "",
        country: "",
        zipCode: "",
        country: "",
      };

      try {
        const latlng = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        const results = await getGeocode({ location: latlng });
        let tempValues = returnAddressValues(results[0]);
        valuesForInput.address = tempValues.address;
        valuesForInput.state = tempValues.state;
        valuesForInput.country = tempValues.country;
        valuesForInput.zipCode = tempValues.zipCode;
      } catch (err) {
        console.log(err);
      }

      setValues({
        ...inputValues,

        longitude: event.latLng.lat(),
        latitude: event.latLng.lng(),
        address: valuesForInput.address,
        zipCode: valuesForInput.zipCode,
        state: valuesForInput.state,

        country: valuesForInput.country,
      });

      setMarkers((current) => [
        {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        },
      ]);
    },
    [inputValues]
  );

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    setMarkers((current) => [
      {
        lat: inputValues.latitude,
        lng: inputValues.longitude,
      },
    ]);
  }, []);

  const panTO = useCallback(
    ({ lat, lng }, placesResults) => {
      let valuesForInput = {
        address: "",
        state: "",
        country: "",
        zipCode: "",
        country: "",
      };
      if (placesResults) {
        let tempValues = returnAddressValues(placesResults[0]);
        valuesForInput.address = tempValues.address;
        valuesForInput.state = tempValues.state;
        valuesForInput.country = tempValues.country;
        valuesForInput.zipCode = tempValues.zipCode;
      }

      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(18);
      setValues({
        ...inputValues,

        longitude: lat,
        latitude: lng,
        address: valuesForInput.address,
        zipCode: valuesForInput.zipCode,
        state: valuesForInput.state,

        country: valuesForInput.country,
      });

      setMarkers((current) => [
        {
          lat: lat,
          lng: lng,
        },
      ]);
    },
    [inputValues]
  );

  const panTOGeo = useCallback(
    async ({ lat, lng }) => {
      let valuesForInput = {
        address: "",
        state: "",
        country: "",
        zipCode: "",
        country: "",
      };
      try {
        const latlng = {
          lat: lat,
          lng: lng,
        };
        const results = await getGeocode({ location: latlng });
        let tempValues = returnAddressValues(results[0]);
        valuesForInput.address = tempValues.address;
        valuesForInput.state = tempValues.state;
        valuesForInput.country = tempValues.country;
        valuesForInput.zipCode = tempValues.zipCode;
      } catch (err) {
        console.log(err);
      }

      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(18);
      setValues({
        ...inputValues,

        longitude: lat,
        latitude: lng,
        address: valuesForInput.address,
        zipCode: valuesForInput.zipCode,
        state: valuesForInput.state,
        country: valuesForInput.country,
      });

      setMarkers((current) => [
        {
          lat: lat,
          lng: lng,
        },
      ]);
    },
    [inputValues]
  );
  const [markers, setMarkers] = useState([]);

  const options = {
    //styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_PLACES_API_KEY}`,
    libraries,
  });

  function handleCenter() {
    if (!mapRef.current) return;

    const newPos = mapRef.current.getCenter().toJSON();
    setCenter(newPos);
  }

  if (loadError) return t("mcmt-t-1");
  if (!isLoaded) return t("mcmt-t-2");

  return (
    <>
      <div className="map-parent">
        <SearchMap panTO={panTO} />
        <Locate panTO={panTOGeo} />
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={16}
          center={center}
          onDragEnd={handleCenter}
          options={options}
          onClick={onMapClick}
          onLoad={onMapLoad}
        >
          {markers.map((marker) => (
            <Marker
              key={marker.lat}
              position={{ lat: marker.lat, lng: marker.lng }}
              icon={{
                url: "/pointerpin.svg",
                scaledSize: new window.google.maps.Size(30, 30),
                origin: new window.google.maps.Point(0, 0),
                // anchor: new window.google.maps.Point(15, 15),
              }}
            />
          ))}
        </GoogleMap>
      </div>
      <div className="add-modal-btns">
        <button
          className="btn btn-outline-secondary"
          onClick={() => {
            setValues({
              ...inputValues,
              address: "",
              city: "",
              country: "",
              landmark: "",
              latitude: 0,
              longitude: 0,

              state: "",
              zipCode: "",
            });
            setismapactivated(false);
          }}
        >
        {t("mcmt-t-3")} 
        </button>

        <button
          type="submit"
          className="btn-main btn-solid-primary"
          onClick={() => {
            setismapactivated(false);
            validation()
          }}
        >
        {t("mcmt-t-4")} 
        </button>
      </div>
    </>
  );
}

export default MapT;

function Locate({ panTO }) {
  return (
    <button
      className="get-current-location"
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTO({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
      }}
    >
      <img src={currentLocation} />
    </button>
  );
}

function SearchMap({ panTO }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const renderSuggestions = () => {
    const suggestions = data.map(({ place_id, description }) => (
      <div
        key={place_id}
        onClick={() => {
          setLocationOnMap(description);
        }}
      >
        <span className="search-item-name search-item-name-map">{description}</span>
      </div>
    ));

    return (
      <div className={`search-results search-results-map ${suggestions.length > 0 ? "show" : ""}`}>
        {suggestions}
      </div>
    );
  };

  const setLocationOnMap = async (address) => {
    setValue(address, false);
    clearSuggestions();
   
    try {
      const results = await getGeocode({ address });

      const { lat, lng } = await getLatLng(results[0]);

      panTO({ lat, lng }, results);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    const { current: wrap } = wrapperRef;

    if (wrap && !wrap.contains(e.target)) {
      clearSuggestions();
    }
  };

  const wrapperRef = useRef(null);

  return (
    <div className="serach-map-parent">
      <div className="autocompletesearch" ref={wrapperRef}>
        <input
          placeholder="enter an address"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          disabled={!ready}
        />
        {status === "OK" && renderSuggestions()}
      </div>
    </div>
  );
}

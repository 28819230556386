import { FormControl, Grid, InputLabel, Select, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import MapT from "../Map/MapT";
import countryList from "../User/extraResources/countryList";
import stateList from "../User/extraResources/stateList";
import { getLocalData } from "../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import { createAddress } from "../../services/AddressService";
import Loc from "../../static/Product/loc.svg";
import swal from 'sweetalert';
import { useTranslation } from "react-i18next";
import CountryCodeAddress from "../AtomicComponents/CountryCodeAddress";


const initialValues = {
  fullName: "",
  mobileNumber: "",
  address: "",
  landmark: "",
  state: "",
  city: "",
  country: "",
  zipCode: "",
  countryCode: process.env.REACT_APP_DEFAULT_COUNTRYCODE,
  latitude: 0,
  longitude: 0,
  addressCategory: "home"
};

function CreateAddressComp(props) {

  const { t } = useTranslation();
  const { ismapactivated, setismapactivated, setiscreateaddressactivated, setselectaddressid } = props

  //Form Values Start
  const [values, setValues] = useState(initialValues);
  console.log(values);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: value === "" ? t("ucca-t-1") : "",
    });
  };
  //Form Values END

  //Handle State and Country DropDown  --- START
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  //State Filtered List
  const [stateFilteredList, setStateFilteredList] = useState([]);

  const getStateList = (countryId) => {
    let tempListForState = stateList.filter(
      (states) => states.country_id === countryId
    );
    setStateFilteredList(tempListForState);
  };

  //updating the dropdown from maps
  useEffect(() => {
    let tempCountryList = countryList.filter(
      (country) => country.name === values.country
    );
    if (tempCountryList[0]) {
      setSelectedCountry(tempCountryList[0]);

      let tempListForState = stateList.filter(
        (states) => states.country_id === tempCountryList[0].id
      );
      setStateFilteredList(tempListForState);

      let tempStateList = stateFilteredList.filter(
        (state) => state.name === values.state
      );
      if (tempStateList[0]) {
        setSelectedState(tempStateList[0]);
      }
    }
  }, [values.country]);

  useEffect(() => {
    let tempStateList = stateFilteredList.filter(
      (state) => state.name === values.state
    );
    if (tempStateList[0]) {
      setSelectedState(tempStateList[0]);
    }
  }, [values.state]);

  //Handle State and Country DropDown  --- END

  //Handle Form Submit
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault()
    if (validation()) {
      console.log(values)
      createAddress(getLocalData("user-info")?.userToken, values)
        .then((data) => {
          if (data.status == "Success") {
            //redirect after validation

            swal(t("ucca-t-2"), {
              icon: "success",
            }).then(() => {
              setselectaddressid(data.response.request.id)

            })

          }
        })
        .catch((err) => console.log(err));



    } else {

      swal({
        icon: "error",
        title: t("ucca-t-3")
      });
    }


  };


  //Validation

  let tempErrors = {
    fullName: "",
    mobileNumber: "",
    address: "",
    state: "",
    city: "",
    country: "",
    zipCode: ""
  };
  const [errors, setErrors] = useState(tempErrors);
  const validation = () => {
    let tempErrors = {
      fullName: values.fullName ? "" : t("ucca-t-4"),
      mobileNumber: values.mobileNumber ? "" : t("ucca-t-4"),
      address: values.address ? "" : t("ucca-t-4"),
      state: values.state ? "" : t("ucca-t-4"),
      city: values.city ? "" : t("ucca-t-4"),
      country: values.country ? "" : t("ucca-t-4"),
      zipCode: values.zipCode ? "" : t("ucca-t-4")
    };

    setErrors(tempErrors)

    return Object.values(tempErrors).every(x => x == "")
  }


  const containerVariats = {
    hidden: {
      opacity: 0,
      y: "100vw",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "tween",

      },
    },
    exit: {
      opacity: 0,
      y: "100vw",
      transition: {
        type: "tween",

      },
    }
  };

  const [phoneNumber, setPhoneNumber] = useState({
    countryCode: `${process.env.REACT_APP_DEFAULT_COUNTRYCODE}`,
    number: "",
  });

  return (
    <Row>

      <Col md={12}>
        <Row className="">
          <Col md={12} className="map-filler">
            <form className="" onSubmit={onSubmit}>
              <Row>
                <Col md={12}>
                  <TextField
                    {...(errors.fullName !== "" && { error: true, helperText: errors.fullName })}
                    label={t("ucca-t-6")}
                    name="fullName"
                    value={values.fullName}
                    variant="standard"
                    onChange={handleInputChange}

                  />
                </Col>

                <Col md={12} className="CountryCodeForAddress">
                  <FormControl fullWidth>
                    <CountryCodeAddress
                      defaultValue={process.env.REACT_APP_DEFAULT_COUNTRYCODE}
                      values={values}
                      setValues={setValues}
                    />
                  </FormControl>
                  <TextField
                    {...(errors.mobileNumber !== "" && { error: true, helperText: errors.mobileNumber })}
                    label={t("ucca-t-7")}
                    name="mobileNumber"
                    value={values.mobileNumber}
                    variant="standard"
                    onChange={handleInputChange}
                  />
                </Col>
                <Col md={12}>
                  <span className="getmyloc" onClick={() => { setismapactivated(true) }}><img alt="loc" src={Loc} />{t("ucca-t-8")}</span>
                </Col>
                <Col md={12}>
                  <TextField
                    {...(errors.address !== "" && { error: true, helperText: errors.address })}

                    label={t("ucca-t-9")}
                    name="address"
                    value={values.address}
                    variant="standard"
                    onChange={handleInputChange}
                  />
                </Col>
                <Col md={12}>
                  <TextField

                    label={t("ucca-t-10")}
                    name="landmark"
                    value={values.landmark}
                    variant="standard"
                    onChange={handleInputChange}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    {...(errors.city !== "" && { error: true, helperText: errors.city })}

                    label={t("ucca-t-11")}
                    name="city"
                    value={values.city}
                    variant="standard"
                    onChange={handleInputChange}
                  />
                </Col>
                <Col md={6}>
                  <Autocomplete
                    id="jarvis-state"
                    options={stateFilteredList}
                    renderInput={(params) => (
                      <TextField {...params} label={t("ucca-t-12")} variant="standard"

                        {...(errors.state !== "" && { error: true, helperText: errors.state })} />
                    )}
                    getOptionLabel={(option) => option.name}
                    value={selectedState}
                    onChange={(_event, newTeam) => {
                      setValues({
                        ...values,
                        state: newTeam.name,
                      }); setErrors({
                        ...errors,
                        state: newTeam.name === "" ? t("ucca-t-4") : "",
                      });
                      setSelectedState(newTeam);
                    }}
                  />
                </Col>

                <Col md={6}>
                  <Autocomplete

                    id="jarvis-country"
                    options={countryList}
                    renderInput={(params) => (
                      <TextField
                        {...(errors.country !== "" && { error: true, helperText: errors.country })}
                        {...params}
                        label={t("ucca-t-13")}
                        variant="standard"
                      />
                    )}
                    getOptionLabel={(option) => option.name}
                    value={selectedCountry}
                    onChange={(_event, newTeam) => {
                      getStateList(newTeam.id);
                      setSelectedState(null);
                      setSelectedCountry(newTeam);
                      setValues({
                        ...values,
                        country: newTeam.name,
                      }); setErrors({
                        ...errors,
                        country: newTeam.name === "" ? t("ucca-t-4") : "",
                      });
                    }}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    {...(errors.zipCode !== "" && { error: true, helperText: errors.zipCode })}

                    label={t("ucca-t-14")}
                    name="zipCode"
                    value={values.zipCode}
                    variant="standard"
                    onChange={handleInputChange}
                  />
                </Col>
                <Col md={6}>
                  <TextField

                    label="latitude"
                    name="latitude"
                    value={values.latitude}
                    variant="standard"
                    onChange={handleInputChange}
                    className="hidden-input"
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    label="longitude"
                    name="longitude"
                    value={values.longitude}
                    variant="standard"
                    onChange={handleInputChange}
                    className="hidden-input"
                  />
                </Col>
                <Col md={12} className="add-modal-btns">
                  <button

                    className="btn btn-outline-secondary"
                    onClick={() => {
                      setiscreateaddressactivated(false)
                    }}
                  >

                    {t("cccac-t-1")}
                  </button>

                  <button
                    type="submit"
                    className="btn btn-info address-form-button"
                  >
                    {t("ucca-t-15")}
                  </button>
                </Col>
              </Row>
            </form>

            {
              ismapactivated && (
                <div className={ismapactivated && "map-create"}>
                  <MapT inputValues={values} setValues={setValues} setismapactivated={setismapactivated} validation={validation} />
                </div>
              )
            }

          </Col>
          <Col md={12} >



          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CreateAddressComp;

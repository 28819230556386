import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { getLocalData,setLocalData, deleteFromLocalData } from "../../utils/localStorage";
import { getAllAddress } from "../../services/AddressService";
import { deleteAddress } from "../../services/AddressService";
import { updateAddressInStore } from "../../redux/addressSlice";

import pen from '../../static/Account/pen.svg';
import create from '../../static/Account/create.svg';

import swal from 'sweetalert';
import Create from "../SvgComponents/Solid/Create";
import { useTranslation } from "react-i18next";

function AllAddress() {
  const address = useSelector((state) => state.address.address);
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const fetchAddress =()=>{
    getAllAddress(getLocalData("user-info")?.userToken)
    .then((data) => {
      dispatch(updateAddressInStore(data));
    
    })
    .catch((err) => console.log(err));
  }

  useEffect(() => {

    
    getAllAddress(getLocalData("user-info")?.userToken)
    .then((data) => {
      dispatch(updateAddressInStore(data));
    
    })
    .catch((err) => console.log(err));

    deleteFromLocalData('updateAddressValuestemp')
  }, [dispatch]);

  const onDelete= (id) =>{
    swal({
      title: t("ucaa-t-6"),
      text: t("ucaa-t-7"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        deleteAddress(getLocalData("user-info")?.userToken, id)
        .then((data) => {
          if(data === 'Success'){
            fetchAddress()
            swal(t("ucaa-t-5"), {
              icon: "success",
            });
          }
        
        
        })
        .catch((err) => console.log(err));
        
      } else {
        swal(t("ucaa-t-4"));
      }
    });
  }

  const navigate = useNavigate();
  const navigateTOEdit = (id) =>{
    
    navigate(`/user/updateAddress/${id}`);
  }
  return (
    <Row>
      <Col md={12}>
        <span className="header-1">{t("ucaa-t-1")}</span>
      </Col>
      <Col md={12} className="account-general">
        <Row>
          <Col md={4}>
            <Link to={`/user/createAddress`} className="address-create">
                <Create/>
                <span>
                {t("ucaa-t-2")} 
                </span>
            </Link>
          </Col>
          {address.length > 0
            ? address.map((item) => {
                return (
                  <Col md={4} className="addres-grid" key={item.id}>
                    <div className="order-details-address-div address-boxes">
                      <span className="order-details-address-div-title">
                       
                      </span>
                      <span className="order-details-address-div-name">
                        {item.fullName}
                      </span>
                      <span className="order-details-address-div-address wb-2">
                        {item.address}
                      </span>
                      <span className="order-details-address-div-address wb-2">
                    
                        {item.city.length > 0 ? `${item.city}` : ""}
                        {item.state.length > 0 ? `, ${item.state}` : ""}
                        {item.zipCode.length > 0 ? `, ${item.zipCode}` : ""}
                      </span>
                      <span className="order-details-address-div-phone">
                     { t("ucaa-t-8")}: {item.mobileNumber}
                      </span>
                      <button className="order-details-address-div-button" onClick={() => {onDelete(item.id)}} >
                       {t("ucaa-t-3")}
                      </button>
                      <span  onClick={()=> navigateTOEdit(item.id)} className="order-details-address-div-button-edt" >
                        <img alt='pen' src={pen}/>
                      </span>
                    </div>
                  </Col>
                );
              })
            : ""}
        </Row>
      </Col>
    </Row>
  );
}

export default AllAddress;

import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { updateCategories } from "../../redux/categorySlice";
import { updateBrands } from "../../redux/brandSlice";
import { getAllCategory, getAllCategoryWithPagination2 } from "../../services/CategoryService";
import PropTypes from "prop-types";
import { getAllBrands } from "../../services/BrandService";

import Slider from "@mui/material/Slider";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Drawer from "@mui/material/Drawer";
import { useTranslation } from "react-i18next";
const minDistance = 0;

function FilterMobile(props) {
  const { filters, setFilters, fromPage, tempMaxPrice , resetfilters} = props;
  const {  t } = useTranslation();
  const [discount, setDiscount] = useState(null);

  const allCategories = useSelector((state) => state.category.allCategories);
  const allBrands = useSelector((state) => state.brand.allBrands);
  const dispatch = useDispatch();
  const  languageId  = 1;
  useEffect(() => {
    if (allCategories.length <= 0) {
      getAllCategoryWithPagination2(languageId, 1, 4).then(
        (res) => {
          dispatch(updateCategories(res));
        },
        (failure) => {
          console.error(failure); //expected output: Oopsy...
        }
      );
    }
  }, []);

  useEffect(() => {
    getAllBrands(1, 1, 100).then(
      (res) => {
        dispatch(updateBrands(res));
      },
      (failure) => {
        console.error(failure); //expected output: Oopsy...
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeValue(event) {
    setDiscount(event.target.value);
    setFilters({
      ...filters,
      discountParcentage: event.target.value,
      pageNo: 1,
    });
  }

  const [categoryFilter, setCategoryFilterr] = useState(0);
  let catArr = React.useRef([]);
  function onCategoryFilterChange(event) {
    if (catArr.current.includes(event.target.value)) {
      const tempArr = catArr.current.filter(
        (cat) => cat !== event.target.value
      );
      catArr.current = tempArr;
    } else {
      catArr.current.push(event.target.value);
    }

    //setCategoryFilterr(event.target.value);

    setFilters({
      ...filters,
      categories: catArr.current,
      pageNo: 1,
    });
  }

  const [brandFilter, setBrandFilter] = useState(0);
  let brandArr = React.useRef([]);

  function onBrandFilterChange(event) {
    if (brandArr.current.includes(event.target.value)) {
      const tempArr = brandArr.current.filter(
        (brand) => brand !== event.target.value
      );
      brandArr.current = tempArr;
    } else {
      brandArr.current.push(event.target.value);
    }

    // setBrandFilter(event.target.value);
    setFilters({
      ...filters,
      manufacturers: brandArr.current,
      pageNo: 1,
    });
  }

  const [value1, setValue1] = React.useState([20, tempMaxPrice]);

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const getFinalSliderValues = (event, newValue, numBerArr) => {
    setFilters({
      ...filters,
      minPrice: newValue[0],
      maxPrice: newValue[1],
      pageNo: 1,
    });
  };

  useEffect(() => {
    setValue1([0, tempMaxPrice]);
  }, [tempMaxPrice]);

  //tabs

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //drawer

  const [drawerState, setDrawerState] = useState(false);

  //check values
  const checkVal = (id) =>{
    
    
    if (brandArr.current.includes(id.toString())){
return "checked"
    }else{
      return ""
    }
  }

  const checkValCat = (id) =>{
    
    
    if (catArr.current.includes(id.toString())){
return "checked"
    }else{
      return ""
    }
  }


  const resetintenalfilters = () => { 
    brandArr.current = []
    catArr.current = []
    setDrawerState(false);
    resetfilters()
  }

  return (
    <>
      <div
        className="d-none filter-mobile"
        onClick={() => {
          setDrawerState(true);
        }}
      >
        <FilterAltOutlinedIcon />
      </div>
      <div>
        <Drawer
          anchor={"right"}
          
          open={drawerState}
          onClose={() => {
            setDrawerState(false);
          }}
          onOpen={() => {
            setDrawerState(true);
          }}
        >
          {true && (
            <div className="filter-mobile-master">
              <div>
                <span className="header-1 filters">
                  <span
                    className="arr-btn"
                    onClick={() => {
                      setDrawerState(false);
                    }}
                  >
                    <ArrowBackIcon />
                  </span>
                  {t("pcf-t-1")}
                </span>
                <span className="reset-filter" onClick={()=>resetintenalfilters()}>
                {t("pcf-t-8")} 
                </span>
              </div>
              <div className="filter-mobile-tabs-parent">
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Filter"
                >
                  <Tab
                    label={`${fromPage === "brand" ? t("pcf-t-3") : t("pcf-t-2")}`}
                    {...a11yProps(0)}
                  />
                  <Tab label={t("pcf-t-4")} {...a11yProps(1)} />
                  <Tab label={t("pcf-t-5")} {...a11yProps(2)} />
                </Tabs>
                <div className="tab-parent">
                  <TabPanel value={value} index={0}>
                    {fromPage === "category" ? (
                      <div className="filter-sub">
                        <div className="filter-category-list">
                          <Form>
                            <div
                              className="mb-3"
                              onChange={onBrandFilterChange}
                            >
                              {allBrands
                                .filter((brand) => brand.totalProducts > 0)
                                .map((brand) => {
                                  return (
                                    <Form.Check
                                      className="mb-3 form-check-radio form-check-box"
                                      label={brand.name }
                                      name="group1"
                                      type="checkbox"
                                      value={brand.id}
                                      key={brand.id}
                                      defaultChecked={checkVal(brand.id)}
                                      
                                      id={`inline-radio-${brand.id}`}
                                    />
                                  );
                                })}
                            </div>
                          </Form>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {fromPage === "brand" ? (
                      <div className="filter-sub">
                        <div className="filter-category-list">
                          <Form>
                            <div
                              className="mb-3"
                              onChange={onCategoryFilterChange}
                            >
                              {allCategories
                                .filter((brand) => brand.totalProducts > 0)
                                .map((category) => {
                                  return (
                                    <Form.Check
                                      className="mb-3 form-check-radio form-check-box"
                                      label={category.name}
                                      name="group1"
                                      type="checkbox"
                                      value={category.id}
                                      key={category.id}
                                      defaultChecked={checkValCat(category.id)}
                                      id={`inline-radio-${category.id}`}
                                    />
                                  );
                                })}
                            </div>
                          </Form>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Form>
                      <div className="mb-3 mobile-radio" onChange={onChangeValue}>
                        <Form.Check
                          className="mb-3 form-check-radio form-check-radio"
                          label="10% and above"
                          name="group1"
                          type="radio"
                          value="10"
                          defaultChecked={filters.discount === '10'? 'checked':''}
                          id={`inline-radio-1`}
                        />
                        <Form.Check
                          className="mb-3 form-check-radio"
                          label="20% and above"
                          name="group1"
                          type="radio"
                          value="20"
                          defaultChecked={filters.discount === '20'? 'checked':''}
                          id={`inline-radio-2`}
                        />
                        <Form.Check
                          className="mb-3 form-check-radio"
                          label="30% and above "
                          type="radio"
                          name="group1"
                          value="30"
                          defaultChecked={filters.discount === '30'? 'checked':''}
                          id={`inline-radio-3`}
                        />

                        <Form.Check
                          className="mb-3 form-check-radio"
                          label="40% and above "
                          type="radio"
                          name="group1"
                          value="40"
                          defaultChecked={filters.discount === '40'? 'checked':''}
                          id={`inline-radio-4`}
                        />

                        <Form.Check
                          className="mb-3 form-check-radio"
                          label="50% and above "
                          type="radio"
                          name="group1"
                          value="50"
                          defaultChecked={filters.discount === '50'? 'checked':''}
                          id={`inline-radio-5`}
                        />
                      </div>
                    </Form>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Slider
                      value={value1}
                      min={0}
                      max={tempMaxPrice}
                      onChange={handleChange1}
                      valueLabelDisplay="auto"
                      onChangeCommitted={getFinalSliderValues}
                      disableSwap
                    />
                    <div className="price-input-grid">
                      <div className="price-input-left">
                        <span>{t("pcf-t-6")}</span>
                        <input readOnly value={value1[0]} placeholder="0" />
                      </div>
                      <div className="price-input-middle">
                        <span>-</span>
                      </div>
                      <div className="price-input-right">
                        <span>{t("pcf-t-7")}</span>
                        <input readOnly value={value1[1]} placeholder="0" />
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>
          )}
        </Drawer>
      </div>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default FilterMobile;

const rootApi = `${process.env.NEXT_PUBLIC_API}api/${process.env.NEXT_PUBLIC_API_VERSION_V1}`;
const clinicId = process.env.NEXT_PUBLIC_CLINIC_ID;

export const deleteDataService = async (token) => {
    const response = await fetch(`${rootApi}/DeletePatientAccountInformation/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    });

    if (!response.ok) {
        console.log(response); 
        throw new Error("Network response was not ok on Delete Data");
    }

    const data = await response.json();
    return data;
}
import axios from "axios";

export const getAllAddress = async (token) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/GetAllPatientAddresses`,
    {},
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then(
    (response) => response.data.response.addresses
  );

  return dataPromise;
};

export const createAddress = async (token, address) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/AddPatientAddress`,
    {
     
      fullName: address.fullName,
      address: address.address,
      mobileNumber: address.mobileNumber,
      landmark: address.landmark,
      zipCode: address.zipCode,
      city: address.city,
      state: address.state,
      countryCode: address.countryCode,
      longitude: address.longitude,
      latitude: address.latitude,
      addressCategory : address.addressCategory 
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then(
    (response) => response.data
  );

  return dataPromise;
};


export const updateAddress = async (token, address) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/UpdatePatientAddress`,
    {
      id: address.id,
      fullName: address.fullName,
      address: address.address,
      mobileNumber: address.mobileNumber,
      landmark: address.landmark,
      zipCode: address.zipCode,
      city: address.city,
      state: address.state,
      longitude: address.longitude,
      latitude: address.latitude,
      addressCategory : address.addressCategory 
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then(
    (response) => response.data
  );

  return dataPromise;
};




export const deleteAddress = async (token, id) => {
  
  const promise = axios.delete(
    `${process.env.REACT_APP_API}api/v1/DeletePatientAddress`,
    
    {
      headers: { Authorization: `Bearer  ${token}` },
      data: {
        id: id
      }
    }
  );

  const dataPromise = promise.then(
    (response) => response.data.status
  );

  return dataPromise;
};

export const getAddressById = async (token, id) => {
  
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/GetPatientAddress`,
    {
      addressId: id,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then(
    (response) => response
  );

  return dataPromise;
};

import React from "react";

function ProfileSvg(props) {
    let color = "svg-grad-disabled";
    if (props.isActive) {
      color = "svg-grad";
    }
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 7.5C9.43375 7.5 11 5.93375 11 4C11 2.06625 9.43375 0.5 7.5 0.5C5.56625 0.5 4 2.06625 4 4C4 5.93375 5.56625 7.5 7.5 7.5ZM7.5 9.25C5.16375 9.25 0.5 10.4225 0.5 12.75V14.5H14.5V12.75C14.5 10.4225 9.83625 9.25 7.5 9.25Z"
        fill={`url(#${color})`}
      />
      <defs>
        <linearGradient
          id={color}
          x1="0.584961"
          y1="15.5625"
          x2="15.885"
          y2="1.56197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0854" className="start-color" />
          <stop offset="0.922" className="end-color" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ProfileSvg;

import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function OrderListItem({item, index}) {
    const { t } = useTranslation();
    const currency = useSelector((state) => state.pharmacy.currencySymbol);
  return (
    <Col md={6} xs={12} >
        <Row className={`order-pl-block ${index % 2 === 0 ? 'm-r-s0':'m-l-s0'}`}>
            <Col md={4} className=" d-flex justify-content-center align-items-center">
                <img className="ol-image" src={item.product.pictureUrl} alt={item.product.name} />
            </Col>
            <Col md={8}>
                <Row>
                    <Col xs={12}>
                    <span className='ol-sm-name'>{item.product.name}</span>
                    </Col>
                    <Col xs={6}>
                        <span className='ol-sm-h'>Order Qty</span>
                        <span className='ol-sm-t'>{item.quantity}</span>
                    </Col>
                    <Col xs={6}>
                    <span className='ol-sm-h'>{t("ocol-t-1")}</span>
                    <span className='ol-sm-t'>{currency}{item.unitPrice}</span>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Col>
  )
}

export default OrderListItem
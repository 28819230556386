import React from 'react'

function Tc() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40" fill="none">
<path d="M16 24C17.1 24 18.042 23.608 18.826 22.824C19.6087 22.0413 20 21.1 20 20C20 18.9 19.6087 17.958 18.826 17.174C18.042 16.3913 17.1 16 16 16C14.9 16 13.9587 16.3913 13.176 17.174C12.392 17.958 12 18.9 12 20C12 21.1 12.392 22.0413 13.176 22.824C13.9587 23.608 14.9 24 16 24ZM16 40C11.3667 38.8333 7.542 36.1747 4.526 32.024C1.50867 27.8747 0 23.2667 0 18.2V6L16 0L32 6V18.2C32 20.3667 31.7167 22.4333 31.15 24.4C30.5833 26.3667 29.8 28.2333 28.8 30L22.9 24.1C23.2667 23.4667 23.542 22.808 23.726 22.124C23.9087 21.4413 24 20.7333 24 20C24 17.8 23.2167 15.9167 21.65 14.35C20.0833 12.7833 18.2 12 16 12C13.8 12 11.9167 12.7833 10.35 14.35C8.78333 15.9167 8 17.8 8 20C8 22.2 8.78333 24.0833 10.35 25.65C11.9167 27.2167 13.8 28 16 28C16.7 28 17.392 27.9087 18.076 27.726C18.7587 27.542 19.4 27.2667 20 26.9L26.45 33.35C25.0167 34.95 23.4587 36.3167 21.776 37.45C20.092 38.5833 18.1667 39.4333 16 40Z" fill="url(#svg-grad)"/>
<defs>
        <linearGradient
          id="svg-grad"
          x1="0.584961"
          y1="15.5625"
          x2="15.885"
          y2="1.56197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0854" className="start-color" />
          <stop offset="0.922" className="end-color" />
        </linearGradient>
      </defs>
</svg>
  )
}

export default Tc
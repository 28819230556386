import React from 'react'

function AddAddress() {
  return (

    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
<path d="M36 22C37.4 22 38.74 22.2 40 22.58V18L24 6L8 18V42H23.36C22.3453 39.867 21.8871 37.5119 22.0283 35.1541C22.1694 32.7963 22.9052 30.5126 24.1671 28.5159C25.4291 26.5192 27.176 24.8745 29.245 23.7352C31.3141 22.5958 33.638 21.9989 36 22Z" fill="url(#svg-grad)"/>
<path d="M36 26C30.48 26 26 30.48 26 36C26 41.52 30.48 46 36 46C41.52 46 46 41.52 46 36C46 30.48 41.52 26 36 26ZM42 37H37V42H35V37H30V35H35V30H37V35H42V37Z" fill="url(#svg-grad)"/>
<defs>
<linearGradient
              id="svg-grad"
              x1="0.584961"
              y1="15.5625"
              x2="15.885"
              y2="1.56197"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0854" className="start-color" />
          <stop offset="0.922" className="end-color" />
            </linearGradient>
</defs>
</svg>


    
  )
}

export default AddAddress
import axios from "axios";

const pharmacyId = process.env.REACT_APP_PHARMACY_ID;

export const clientLoginPhoneNumber = async (countryCode, phoneNumber) => {
  let loginType = "PhoneNumber";
  let isPhoneVerified = true;
  let language = "en";

  const promise = axios.post(`${process.env.REACT_APP_API}api/v1/PatientLogin`, {
    pharmacyId,
    countryCode,
    phoneNumber,
    loginType,
    isPhoneVerified,
    language
  });

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};
//nw
export const UpdatePatientProfile = async (userData, token) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/UpdatePatientProfile`,
    {
      ...userData,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};
//nw
export const validateEmail = async (email) => {
  const promise = axios.post(`${process.env.REACT_APP_API}api/ValidateEmail`, {
    pharmacyId,
    email,
  });

  const dataPromise = promise.then((response) => response);

  return dataPromise;
};
//nw
export const forgotPassSendEmail = async (email) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/ForgotPasswordSendEmail`,
    {
      email,
      pharmacyId,
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};
//nw
export const verifyforgotPassSendEmail = async (forgotPasswordCode, email) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/ValidateForgotPasswordCode`,
    { forgotPasswordCode, email, pharmacyId }
  );

  const dataPromise = promise.then((response) => response);

  return dataPromise;
};
//nw
export const updateClientPassword = async (
  newPassword,
  email,
  forgotPasswordCode
) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/UpdatePatientPassword`,
    { forgotPasswordCode, email, pharmacyId, newPassword }
  );

  const dataPromise = promise.then((response) => response);

  return dataPromise;
};

export const userLogin = async (loginObject) => {
  const promise = axios.post(`${process.env.REACT_APP_API}api/v1/PatientLogin`, {
    ...loginObject,
    pharmacyId,
  });

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

//nw0
export const newRegistration = async (
  tempObjj
) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/PatientRegistration`,
    { ...tempObjj, pharmacyId }
  );

  const dataPromise = promise.then((response) => response);

  return dataPromise;
};


export const mediaUpload = async (
  formData,
  token
) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/Media/Upload/${pharmacyId}`,
    formData ,
    {
      headers: {  'Content-Type': "multipart/form-data", Authorization: `Bearer  ${token}`  },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};


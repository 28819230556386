import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GetAllCategoriesHierchyFormat2 } from "../../services/CategoryService";

const checkForParent = (parentArr, seelctedItem) => {
  let tempArr = parentArr.children.filter(
    (item) => item.id === parseInt(seelctedItem)
  );
  if (tempArr.length > 0) {
    return true;
  }
  return false;
};

const checkForParentDefault = (parentArr, seelctedItem) => {
  let defSelect = 0;
  if (parentArr.length > 0) {
    let tempArr = parentArr.filter(
      (item) => item.id === parseInt(seelctedItem)
    );

    if (tempArr.length > 0) {
      defSelect = tempArr[0].id;
    } else {
      parentArr.forEach((element) => {
        if (element.children.length > 0) {
          let tempArr = element.children.filter(
            (item) => item.id === parseInt(seelctedItem)
          );
          if (tempArr.length > 0) {
            defSelect = element.id;
          }
        }
      });
    }
  }

  
  return defSelect;
};

const checkForParentDefault2 = (parentArr) => {
  let defSelect = 0;
  if (parentArr.length > 0) {
    let tempArr = parentArr.filter((item) => item.parentCategoryId === null || item.parentCategoryId === 0 );

    if (tempArr.length > 0) {
      defSelect = tempArr[0].id;
    } else {
    }
  }

console.log(defSelect, parentArr)
  return defSelect;
};

function CatFilter(props) {
  const { t } = useTranslation();
  const { selectedCategory } = props;

  const [catArray, setCatArray] = useState([]);
  const [defCat, setDefCat] = useState(0);

  useEffect(() => {
    GetAllCategoriesHierchyFormat2().then((response) => {
      setCatArray(response.categories);
   
      if (selectedCategory > -1) {
        setDefCat(checkForParentDefault(response.categories, selectedCategory));
        
      } else {
        setDefCat(checkForParentDefault2(response.categories));
      }
    });
  }, []);

  return (
    <div className="filter-parent even-spacer-y mt-0">
      <span className="filter-heading">{t("hc-t-2")}</span>
      <div className="filter-sub category cat-filtrrr">
        {defCat > 0 && (
          <Accordion defaultActiveKey={defCat}>
            {catArray.map((item, index) =>
              item.children.length > 0 ? (
                <Accordion.Item eventKey={item.id} key={item.id}>
                  <Accordion.Header
                    className={`cat-filter-header ${
                      parseInt(selectedCategory) === parseInt(item.id)
                        ? "active"
                        : ""
                    } ${
                      checkForParent(item, selectedCategory) ? "active" : ""
                    }`}
                  >
                    {" "}
                    <Link to={`/products/category/${item.id}`}>
                      {item.name}
                    </Link>{" "}
                  </Accordion.Header>

                  <Accordion.Body>
                    <ul>
                      {item.children.map((subcategory) => (
                        <li
                          className={`subcat-filter-header ${
                            parseInt(selectedCategory) ===
                            parseInt(subcategory.id)
                              ? "active"
                              : ""
                          }`}
                          key={subcategory.id}
                        >
                          {" "}
                          <Link to={`/products/subcategory/${subcategory.id}`}>
                            {" "}
                            {subcategory.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                (item.parentCategoryId === null || item.parentCategoryId === 0 ) && (
                  <div
                    className={`cat-filter-header ${
                      parseInt(selectedCategory) === parseInt(item.id)
                        ? "active"
                        : ""
                    }`}
                    key={item.id}
                  >
                    {" "}
                    <Link to={`/products/category/${item.id}`}>
                      {item.name}
                    </Link>
                  </div>
                )
              )
            )}
          </Accordion>
        )}
      </div>
    </div>
  );
}

export default CatFilter;

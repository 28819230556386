// 18-03
export const ZainCashAPI = async (obj, token) => {
  try {
    const response = await fetch(
      // `${process.env.REACT_APP_API}api/v1/ZainCashApi/Initiate`,
      `${process.env.REACT_APP_API}api/v1.1/ZainCashApi/Initiate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          // Add any other necessary headers, if applicable
        },
        body: JSON.stringify(obj),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create Razorpay order");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating Razorpay order:", error);
    throw error; // Rethrow the error for further handling
  }
};

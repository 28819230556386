import React from "react";

function UserBlack() {
  return (
    
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M7.57202 2.57612C8.57303 2.57612 9.3842 3.38729 9.3842 4.38831C9.3842 5.38933 8.57303 6.20049 7.57202 6.20049C6.571 6.20049 5.75983 5.38933 5.75983 4.38831C5.75983 3.38729 6.571 2.57612 7.57202 2.57612ZM7.57202 10.3427C10.1349 10.3427 12.836 11.6026 12.836 12.1548V13.1041H2.30805V12.1548C2.30805 11.6026 5.00907 10.3427 7.57202 10.3427ZM7.57202 0.936523C5.66491 0.936523 4.12024 2.4812 4.12024 4.38831C4.12024 6.29542 5.66491 7.84009 7.57202 7.84009C9.47913 7.84009 11.0238 6.29542 11.0238 4.38831C11.0238 2.4812 9.47913 0.936523 7.57202 0.936523ZM7.57202 8.70304C5.26795 8.70304 0.668457 9.85938 0.668457 12.1548V14.7437H14.4755V12.1548C14.4755 9.85938 9.87608 8.70304 7.57202 8.70304Z"
        fill="url(#svg-gradb)"
      />
      <defs>
        <linearGradient
          id="svg-gradb"
          x1="0.668457"
          y1="14.7437"
          x2="15.062"
          y2="1.57762"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0854" className="start-colorb" />
          <stop offset="0.922" className="end-colorb" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default UserBlack;

import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";

import Pagination from "@mui/material/Pagination";
import ProductGridItem from "../components/ProductGridItem";
import { filterProductsAPI } from "../services/ProductService";
import Filter from "../components/Product/Filter";
import FilterMobile from "../components/Product/FilterMobile";
import { getLocalData } from "../utils/localStorage";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CatFilter from "../components/Product/CatFilter";

function AllProducts() {
  const { id } = useParams();
  useEffect(() => {
    let onLoadValues = {};
    if (window.location.href.indexOf("subcategory") > -1) {
      onLoadValues = {
        ...initialFilters,
        categoryId: id,
      };
      setFromPage("category");
    } else if (window.location.href.indexOf("brand") > -1) {
      onLoadValues = {
        ...initialFilters,
        manufacturerId: id,
      };
      setFromPage("brand");
    }

    setFilters(onLoadValues);
  }, [id]);
  const allCategories = useSelector((state) => state.category.allCategories);
  const allBrands = useSelector((state) => state.brand.allBrands);
  
  const outletIdM = getLocalData('outlet-id')
  
  
  const initialFilters = {
    pageNo: 1,
    pageSize: 9,
    discountParcentage: null,
    minPrice: null,
    maxPrice: null,
    manufacturerId: null,
    categoryId: null,
    manufacturers: [],
    categories: [],
    isNewlyProduct: false,
    isFeatureProduct: false,
    outletId: outletIdM
  };





  
  const [filters, setFilters] = useState(null);
  const [fromPage, setFromPage] = useState(null);

  const initProducts = [];
  const [products, setProducts] = useState(initProducts);

  const [noOfPages, setNoOfpages] = useState(1);
  const calculateNoOfPages = (tp) => {
    let tempVar = Math.ceil(tp / initialFilters.pageSize);
    if (tempVar === 0) {
      tempVar = 1;
    }

    setNoOfpages(tempVar);
  };


  useEffect(() => {
    setFilters((filters)=> ({
      ...filters,
      outletId: outletIdM
    }))
  
    
  }, [outletIdM])
  

  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setFilters({
      ...filters,
      pageNo: value,
    });
    setPage(value);
  };



  const [tempMaxPrice, setTempMaxPrice] = useState(7000);

  const [isLoading, setIsLoading] = useState(true);
  //hits when filter is updated
  useEffect(() => {
    setIsLoading(true);
    if (filters !== null) {
      filterProductsAPI(filters).then(
        (res) => {
          setProducts(res.products);
          if(tempMaxPrice <= res.matchingProductProductMaxValue){
            setTempMaxPrice(res.matchingProductProductMaxValue);
          }
          
          calculateNoOfPages(res.matchingProductCount);
          setIsLoading(false);
        },
        (failure) => {
          console.error(failure); //expected output: Oopsy...
        }
      );
    }
  }, [filters]);

  //screen size

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  //drawer


  const resetfilters = () =>{
    let onLoadValues = {};
    
    if (window.location.href.indexOf("subcategory") > -1) {
      onLoadValues = {
        ...initialFilters,
        categoryId: id,
      };
      setFromPage("category");
    } else if (window.location.href.indexOf("brand") > -1) {
      onLoadValues = {
        ...initialFilters,
        manufacturerId: id,
      };
      setFromPage("brand");
    }

    setFilters(onLoadValues);
  }

  const pharmacy = useSelector((state) => state.pharmacy.pharmacyDetails.pharmacy)

    const isStock = (product) => {
        if (!pharmacy.isInventoryLiteEnabled || !pharmacy.isPosEnabled) {
            return true;
        } else {
            return product.stockQuantity > 0;
        }
    };
  
  const { t } = useTranslation();
  return (
    <section className="space-at-top ">
      <div className="container">
        <Row className="pb-2">
          <Col md={3}></Col>
          <Col md={9}>
            {fromPage === "category"
              ? allCategories
                  .filter((category) => category.id === parseInt(id))
                  .map((category, index) => {
                    return (
                      <span key={index} className="header-1">
                        {category.name}
                      </span>
                    );
                  })
              : allBrands
                  .filter((brand) => brand.id === parseInt(id))
                  .map((brand, index) => {
                    return (
                      <span key={index} className="header-1">
                        {brand.name}
                      </span>
                    );
                  })}
          </Col>
        </Row>
        <Row>
          {windowDimenion.winWidth >= 787 && (
            <Col md={3} className="filter-main">
            <CatFilter selectedCategory={fromPage === "category" ? id : -1}/>

              {fromPage && (
                <Filter
                  filters={filters}
                  setFilters={setFilters}
                  fromPage={fromPage}
                  tempMaxPrice={tempMaxPrice}
                />
              )}
            </Col>
          )}

          <Col md={9} xs={12} className="prduct-list-parent">
            {isLoading ? (
              <div className="fl-loader">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              /> {t("app-t-1")}
              </div>
              
            ) : (
              <>
                {products.length > 0 ? (
                  <Row className="mobile-grid">
                    {products.map((product) => {
                      return (
                        product.published && (
                          <Col
                            lg={3}
                            md={3}
                            sm={6}
                            xs={6}
                            key={product.id}
                            className="even-spacer-y"
                          >
                            <ProductGridItem
                              imgUrl={product.pictureUrl}
                              enableWishlist={true}
                              name={product.name}
                              brand={product.manufacturerName}
                              oldPrice={product.oldPrice}
                              price={product.price}
                              id={product.id}
                              outOfStock={isStock(product)}
                              type={product.drugType}
                            />
                          </Col>
                        )
                      );
                    })}
                    <Col md={12}>
                      <div className="pagination-parent">
                        <Pagination
                          count={noOfPages}
                          page={page}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <div className="even-spacer-y">{t("app-t-2")}</div>
                )}
              </>
            )}
          </Col>
        </Row>
        {fromPage && (
                  <FilterMobile
                    filters={filters}
                    setFilters={setFilters}
                    fromPage={fromPage}
                    tempMaxPrice={tempMaxPrice}
                    resetfilters={resetfilters}
                  />
                )}
        
      </div>
    </section>
  );
}

export default AllProducts;

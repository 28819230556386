import React from "react";

function OrdersSvg(props) {
  let color = "svg-grad-disabled";
  if (props.isActive) {
    color = "svg-grad";
  }
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0417 2.37496H9.7325C9.4 1.45663 8.52917 0.791626 7.5 0.791626C6.47083 0.791626 5.6 1.45663 5.2675 2.37496H1.95833C1.0875 2.37496 0.375 3.08746 0.375 3.95829V15.0416C0.375 15.9125 1.0875 16.625 1.95833 16.625H13.0417C13.9125 16.625 14.625 15.9125 14.625 15.0416V3.95829C14.625 3.08746 13.9125 2.37496 13.0417 2.37496ZM7.5 2.37496C7.93542 2.37496 8.29167 2.73121 8.29167 3.16663C8.29167 3.60204 7.93542 3.95829 7.5 3.95829C7.06458 3.95829 6.70833 3.60204 6.70833 3.16663C6.70833 2.73121 7.06458 2.37496 7.5 2.37496ZM5.91667 13.4583L2.75 10.2916L3.86625 9.17538L5.91667 11.2179L11.1337 6.00079L12.25 7.12496L5.91667 13.4583Z"
        fill={`url(#${color})`}
      />
      <defs>
        <linearGradient
          id={color}
          x1="0.584961"
          y1="15.5625"
          x2="15.885"
          y2="1.56197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0854" className="start-color" />
          <stop offset="0.922" className="end-color" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default OrdersSvg;

import React from "react";

function Home() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="40"
      viewBox="0 0 44 40"
      fill="none"
    >
      <path
        d="M38 38C38 38.5304 37.7893 39.0391 37.4142 39.4142C37.0391 39.7893 36.5304 40 36 40H8C7.46957 40 6.96086 39.7893 6.58579 39.4142C6.21071 39.0391 6 38.5304 6 38V20H0L20.654 1.224C21.0222 0.888956 21.5022 0.703285 22 0.703285C22.4978 0.703285 22.9778 0.888956 23.346 1.224L44 20H38V38ZM22 28C23.3261 28 24.5979 27.4732 25.5355 26.5355C26.4732 25.5979 27 24.3261 27 23C27 21.6739 26.4732 20.4021 25.5355 19.4645C24.5979 18.5268 23.3261 18 22 18C20.6739 18 19.4021 18.5268 18.4645 19.4645C17.5268 20.4021 17 21.6739 17 23C17 24.3261 17.5268 25.5979 18.4645 26.5355C19.4021 27.4732 20.6739 28 22 28Z"
        fill="url(#svg-grad)"
      />
      <defs>
        <linearGradient
          id="svg-grad"
          x1="0.584961"
          y1="15.5625"
          x2="15.885"
          y2="1.56197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0854" className="start-color" />
          <stop offset="0.922" className="end-color" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Home;

import React from 'react';

const DatabaseIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2888_33786)">
                <path d="M16.7891 22.0566L16.7894 22.057L20.8861 22.0576L20.8851 17.149L16.7914 17.1484L16.7891 22.0566ZM19.222 18.4065C19.222 18.2968 19.2656 18.1915 19.3432 18.1139C19.4208 18.0363 19.526 17.9927 19.6358 17.9927C19.7455 17.9927 19.8508 18.0363 19.9284 18.1139C20.006 18.1915 20.0496 18.2968 20.0496 18.4065V20.888C20.0496 20.9977 20.006 21.103 19.9284 21.1806C19.8508 21.2582 19.7455 21.3018 19.6358 21.3018C19.526 21.3018 19.4208 21.2582 19.3432 21.1806C19.2656 21.103 19.222 20.9977 19.222 20.888V18.4065ZM17.5706 18.4065C17.5706 18.3522 17.5813 18.2984 17.6021 18.2481C17.6229 18.1979 17.6534 18.1523 17.6918 18.1139C17.7302 18.0755 17.7759 18.045 17.8261 18.0242C17.8763 18.0034 17.9301 17.9927 17.9844 17.9927C18.0388 17.9927 18.0926 18.0034 18.1428 18.0242C18.193 18.045 18.2386 18.0755 18.277 18.1139C18.3154 18.1523 18.3459 18.1979 18.3667 18.2481C18.3875 18.2984 18.3982 18.3522 18.3982 18.4065V20.888C18.3982 20.9423 18.3875 20.9961 18.3667 21.0463C18.3459 21.0965 18.3154 21.1422 18.277 21.1806C18.2386 21.219 18.193 21.2495 18.1428 21.2703C18.0926 21.2911 18.0388 21.3018 17.9844 21.3018C17.9301 21.3018 17.8763 21.2911 17.8261 21.2703C17.7759 21.2495 17.7302 21.219 17.6918 21.1806C17.6534 21.1422 17.6229 21.0965 17.6021 21.0463C17.5813 20.9961 17.5706 20.9423 17.5706 20.888V18.4065Z" fill="white" />
                <path d="M18.9348 13.8402C16.1419 13.8402 13.8697 16.1183 13.8697 18.9184C13.8697 21.7184 16.1419 23.9961 18.9348 23.9961C21.7277 23.9961 24 21.7182 24 18.9182C24 16.1181 21.7277 13.8402 18.9348 13.8402ZM18.0505 14.956L19.6341 14.9562C19.7439 14.9562 19.8491 14.9998 19.9267 15.0774C20.0043 15.155 20.0479 15.2603 20.0479 15.37C20.0479 15.4798 20.0043 15.585 19.9267 15.6626C19.8491 15.7402 19.7439 15.7838 19.6341 15.7838L18.0505 15.7836C17.9408 15.7836 17.8355 15.74 17.7579 15.6624C17.6803 15.5848 17.6367 15.4796 17.6367 15.3698C17.6367 15.2601 17.6803 15.1548 17.7579 15.0772C17.8355 14.9996 17.9408 14.956 18.0505 14.956ZM22.2184 17.1495H21.7159L21.7169 22.014C21.7169 22.4891 21.3205 22.8855 20.8455 22.8855L16.8358 22.8849C16.3608 22.8849 15.9646 22.4878 15.965 22.0128L15.9674 17.1485H15.5373C15.4275 17.1485 15.3223 17.1049 15.2447 17.0273C15.1671 16.9497 15.1235 16.8445 15.1235 16.7347C15.1235 16.625 15.1671 16.5197 15.2447 16.4421C15.3223 16.3645 15.4275 16.3209 15.5373 16.3209L22.2186 16.3219C22.3283 16.3219 22.4336 16.3655 22.5112 16.4432C22.5887 16.5208 22.6323 16.6261 22.6323 16.7358C22.6323 16.8456 22.5886 16.9508 22.511 17.0284C22.4334 17.106 22.3281 17.1495 22.2184 17.1495ZM9.26052 -0.00390625C3.80323 -0.00390625 0 1.67242 0 3.17687C0 4.68132 3.80323 6.35745 9.26052 6.35745C14.7178 6.35745 18.521 4.68132 18.521 3.17687C18.521 1.67242 14.718 -0.00390625 9.26052 -0.00390625Z" fill="white" />
                <path d="M0 4.80859V7.68085C0 9.1853 3.80323 10.8616 9.26052 10.8616C14.7178 10.8616 18.521 9.1853 18.521 7.68085V4.80859C16.8735 6.3173 13.0181 7.17821 9.26052 7.17821C5.50297 7.17821 1.64748 6.3173 0 4.80859ZM0 14.174V17.1252C0 18.6297 3.80323 20.306 9.26052 20.306C10.6175 20.306 11.9212 20.203 13.1429 19.9994C13.0758 19.6405 13.042 19.2762 13.0421 18.9111C13.0421 17.9277 13.278 16.9847 13.7313 16.137C12.3519 16.3983 10.8265 16.5345 9.26052 16.5345C5.004 16.5349 1.52226 15.5912 0 14.174Z" fill="white" />
                <path d="M0 9.3125V12.5271C0 14.0316 3.80323 15.7079 9.26052 15.7079C11.094 15.7079 12.8654 15.5153 14.3938 15.1507C15.3941 13.9391 16.8323 13.1769 18.3871 13.0323C18.4525 13.0114 18.5259 12.809 18.521 12.5271V9.3125C16.9877 10.7335 13.4692 11.6899 9.26052 11.6899C5.05161 11.6899 1.53329 10.7335 0 9.3125Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_2888_33786">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default DatabaseIcon;

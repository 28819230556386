import React from "react";

function PpSvg(props) {
    let color = "svg-grad-disabled";
    if (props.isActive) {
      color = "svg-grad";
    }
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.833374L0.5 4.16671V9.16671C0.5 13.7917 3.7 18.1167 8 19.1667C12.3 18.1167 15.5 13.7917 15.5 9.16671V4.16671L8 0.833374ZM7.16667 5.83337H8.83333V7.50004H7.16667V5.83337ZM7.16667 9.16671H8.83333V14.1667H7.16667V9.16671Z"
        fill={`url(#${color})`}
      />
      <defs>
        <linearGradient
          id={color}
          x1="0.584961"
          y1="15.5625"
          x2="15.885"
          y2="1.56197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0854" className="start-color" />
          <stop offset="0.922" className="end-color" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PpSvg;

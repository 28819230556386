import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBanners } from '../services/BannersService';
import { updateBanners } from '../redux/homePageSlice';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

const SmallBanner = () => {

    const banners = useSelector((state) => state.homepage.banners);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        if (banners.length > 0) {
            setLoading(false);
        } else {
            getBanners()
                .then((data) => {
                    dispatch(updateBanners(data));
                    setLoading(false);
                })
                .catch((err) => console.log(err));
        }
    }, [banners.length, dispatch]);

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    let type = "";

    // slick settings
    let dragging = false;
    const settings = {
        dots: false,
        infinite: banners.length > 1 ? true : false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        // centerMode: true,
        // centerPadding: "100px",
        // beforeChange: () => (dragging = true),
        // afterChange: () => (dragging = false),

        autoplay: true,
        speed: 400,
        autoplaySpeed: 2500,
        responsive: [

            {
                breakpoint: 480,
                settings: {
                    centerPadding: "20px",
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        banners.length > 0 && (
            <section className='bannerSection'>
                <div className='bannerContainer row container m-auto p-0'>
                    <div className={!banners.length > 1 ? "col-12" : "col-md-6 col-sm-12"}>
                        {
                            banners.length > 1 ?
                                <Slider {...settings}>
                                    {banners.map((banner, index) => (
                                        index % 2 === 0 &&
                                        <div key={banner.id}>
                                            {banner.bannerType === 1 && (
                                                <Link
                                                    onClick={(e) => dragging && e.preventDefault()}
                                                    to={`/productDetails/${banner.entityId}`}
                                                >
                                                    <img
                                                        className="d-block w-100"
                                                        src={banner.pictureUrl}
                                                        alt="First slide"
                                                    />
                                                </Link>
                                            )}
                                            {banner.bannerType === 2 && (
                                                <Link
                                                    onClick={(e) => dragging && e.preventDefault()}
                                                    to={`/products/category/${banner.entityId}`}
                                                >
                                                    <img
                                                        className="d-block w-100"
                                                        src={banner.pictureUrl}
                                                        alt="First slide"
                                                    />
                                                </Link>
                                            )}
                                            {banner.bannerType === 3 && (
                                                <Link
                                                    onClick={(e) => dragging && e.preventDefault()}
                                                    to={`/products/brand/${banner.entityId}`}
                                                >
                                                    <img
                                                        className="d-block w-100"
                                                        src={banner.pictureUrl}
                                                        alt="First slide"
                                                    />
                                                </Link>
                                            )}
                                        </div>
                                    ))}
                                </Slider>
                                :
                                <Slider {...settings}>
                                    {banners.map((banner, index) => (
                                        <div key={banner.id}>
                                            {banner.bannerType === 1 && (
                                                <Link
                                                    onClick={(e) => dragging && e.preventDefault()}
                                                    to={`/productDetails/${banner.entityId}`}
                                                >
                                                    <img
                                                        className="d-block w-100"
                                                        src={banner.pictureUrl}
                                                        alt="First slide"
                                                    />
                                                </Link>
                                            )}
                                            {banner.bannerType === 2 && (
                                                <Link
                                                    onClick={(e) => dragging && e.preventDefault()}
                                                    to={`/products/category/${banner.entityId}`}
                                                >
                                                    <img
                                                        className="d-block w-100"
                                                        src={banner.pictureUrl}
                                                        alt="First slide"
                                                    />
                                                </Link>
                                            )}
                                            {banner.bannerType === 3 && (
                                                <Link
                                                    onClick={(e) => dragging && e.preventDefault()}
                                                    to={`/products/brand/${banner.entityId}`}
                                                >
                                                    <img
                                                        className="d-block w-100"
                                                        src={banner.pictureUrl}
                                                        alt="First slide"
                                                    />
                                                </Link>
                                            )}
                                        </div>
                                    ))}
                                </Slider>
                        }

                    </div>
                    {banners.length > 1 &&
                        <div className="col-6 d-none d-md-block">
                            <Slider {...settings}>
                                {banners.map((banner, index) => (
                                    index % 2 !== 0 &&
                                    <div key={banner.id}>
                                        {banner.bannerType === 1 && (
                                            <Link
                                                onClick={(e) => dragging && e.preventDefault()}
                                                to={`/productDetails/${banner.entityId}`}
                                            >
                                                <img
                                                    className="d-block w-100"
                                                    src={banner.pictureUrl}
                                                    alt="First slide"
                                                />
                                            </Link>
                                        )}
                                        {banner.bannerType === 2 && (
                                            <Link
                                                onClick={(e) => dragging && e.preventDefault()}
                                                to={`/products/category/${banner.entityId}`}
                                            >
                                                <img
                                                    className="d-block w-100"
                                                    src={banner.pictureUrl}
                                                    alt="First slide"
                                                />
                                            </Link>
                                        )}
                                        {banner.bannerType === 3 && (
                                            <Link
                                                onClick={(e) => dragging && e.preventDefault()}
                                                to={`/products/brand/${banner.entityId}`}
                                            >
                                                <img
                                                    className="d-block w-100"
                                                    src={banner.pictureUrl}
                                                    alt="First slide"
                                                />
                                            </Link>
                                        )}
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    }

                </div>
            </section>
        )

    );
};

export default SmallBanner;
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../redux/searchModalSlice";
import CaretDown from "../SvgComponents/CaretDown";
import OutletHeader from "../SvgComponents/OutletHeader";
import SearchHeader from "../SvgComponents/SearchHeader";
import { useTranslation } from "react-i18next";

function BigInput(props) {

  const { i18n, t } = useTranslation();

  const {setDrawerState} = props;
    const dispatch = useDispatch();
    const selectOutObj = useSelector((state) => state.outlet.selectOutObj);


    const intiModal = (type) => { 

      if(setDrawerState){
        setDrawerState(false)
      }
      dispatch(showModal(type))
    }

  return (
    <div className="big-input">
      <div className="INoutlet-selector" onClick={()=>intiModal(1)}>
        <div className="out-btn">
          <OutletHeader />
          <span>{selectOutObj.autoLoad ? "Select store" :  selectOutObj.outletName}</span>
        </div>
        <CaretDown />
      </div>
      <div className="INsearch-product" onClick={()=>intiModal(2)}>
        <div className="out-btn">
          <SearchHeader />
          <span>{t("Search")}</span>
        </div>
      </div>
    </div>
  );
}

export default BigInput;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Cart from "../SvgComponents/CartBlack";
import whiteCart from "../../static/cartwhite.svg";
import bin from "../../static/bin.svg";
import { Col, Row } from "react-bootstrap";
import { addToCart, getCartItems } from "../../services/CartService";
import { getLocalData } from "../../utils/localStorage";
import { addTOCart } from "../../redux/cartSlice";
import { formatNumber } from "../../utils/numberFormater";
import { useTranslation } from "react-i18next";

function calcuateSubtotal(tempArr) {
  let approxSubtotal = 0;

  tempArr.forEach((element) => {
    approxSubtotal = approxSubtotal + (element.product.price * element.quantity);
  });

  return formatNumber(approxSubtotal);
}

function CartDrop() {
  const noOfProductsInCart = useSelector(
    (state) => state.cart.noOfProductsInCart
  );

  //currency
  const currency = useSelector((state) => state.pharmacy.currencySymbol);


  const productsArray = useSelector((state) => state.cart.cartItems).filter(
    (item) => item.shoppingCartTypeId === 1
  );

  const allCartItems = useSelector((state) => state.cart.cartItems);

  const [isDropActivated, setIsDropActivated] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);


  const [isWhite, setIsWhite] = useState(noOfProductsInCart > 0 ? true : false)


  useEffect(() => {
    if (noOfProductsInCart > 0) {
      setIsWhite(true)
    } else {
      setIsWhite(false)
    }

  }, [noOfProductsInCart])

  const removeFromCart = (id) => {

    let tempArray = allCartItems.filter((item) => (item.id !== id))



    addToCart(
      userData.id,
      userData.outletId,
      getLocalData("user-info")?.userToken,
      tempArray
    )
      .then((data) => {
        if (data === "Success") {

          getCartItems(getLocalData("user-info")?.userToken, getLocalData("user-info")?.userData?.id, userData.outletId).then(
            (res) => {
              dispatch(addTOCart(res));
            },
            (failure) => {
              console.error(failure); //expected output: Oopsy...
            }
          );
        }
      })
      .catch((err) => console.log(err));
  }
  const { t } = useTranslation();

  return (
    <div

      className={`round-btn-header cartnew  ${isWhite ? 'expanded' : ''} ${isDropActivated ? "opendrop" : ""
        }`}
      onMouseEnter={() => setIsDropActivated(true)}
      onMouseLeave={() => setIsDropActivated(false)}
    >
      <Link to="/cart">
        <Cart isWhite={isWhite} />
      </Link>

      {
        isWhite && (
          <div className="cart-ind-text">
            <span>
              {currency} {calcuateSubtotal(productsArray)}
            </span>
            {noOfProductsInCart > 0 && (
              <span className="counter-inner">{noOfProductsInCart} {noOfProductsInCart > 1 ? t("cdt-t-1") : t("cdt-t-2")} </span>
            )}
          </div>
        )
      }
      <div>
      </div>


      <div
        className="cart-drop"
        onMouseEnter={() => setIsDropActivated(true)}
        onMouseLeave={() => setIsDropActivated(false)}
      >
        <div className="cd-head">
          <span>
            {noOfProductsInCart} {noOfProductsInCart > 1 ? t("cdt-t-1") : t("cdt-t-2")}
          </span>
          {productsArray.length > 0 ? (
            <span>
              {t("cdt-t-3")}: {" "}
              {currency}{calcuateSubtotal(productsArray)}
            </span>
          ) : (
            ""
          )}

        </div>
        <div className="cd-body">
          {productsArray.length > 0
            ? productsArray.map((item) => {
              return <Row key={item.id}>
                <Col md={3}>
                  <img className="cd-prodImg" src={item.product.pictureUrl} alt={item.product.name} />
                </Col>
                <Col md={7} className="cd-text-rak">
                  <span className="cd-prodName">{item.product.name}</span>
                  <span className="cd-prodQty">{t("cdt-t-5")} :{item.quantity}</span>
                  <span className="cd-prodValue"> {currency}{formatNumber(item.product.price * item.quantity)}</span>
                </Col>
                <Col md={2} className="d-flex justify-content-center align-items-center"><img className="remove-cart-drpdwn" src={bin} alt="bin" onClick={() => removeFromCart(item.id)} /></Col>
              </Row>;
            })
            : "no products in cart"}
        </div>
        <div className="cd-foot">
          <Link to="/cart" className="btn pd-btn pd-atc pdetails-btn my-0">
            <img src={whiteCart} alt="sdf" />
            {t("cdt-t-4")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CartDrop;

import { TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  deleteFromLocalData,
  getLocalData,
  setLocalData,
} from "../../utils/localStorage";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import pen from "../../static/Account/pen.svg";
import { useRef } from "react";
import { mediaUpload, UpdatePatientProfile } from "../../services/UserService";
import moment from "moment";
import { updateUser } from "../../redux/userSlice";

import { useNavigate } from "react-router-dom";

import swal from "sweetalert";
import Phone from "./Phone";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from "react-i18next";
function EditProfile() {
  const localValues = getLocalData("user-info")?.userData;

  let gender = 0;

  if (localValues.sex === "Male" || localValues.sex === "male") {
    gender = 1;
  }
  if (localValues.sex === "Female" || localValues.sex === "female") {
    gender = 2;
  }

  const initialVAlues = {
    name: localValues.name ? localValues.name : "",
    email: localValues.email ? localValues.email : "",
    countryCode: localValues.countryCode ? localValues.name : "91",
    phoneNumber: localValues.phoneNumber ? localValues.phoneNumber : "",
    age: localValues.age,
    pictureId: localValues.pictureId,
    pictureUrl: localValues.pictureUrl,
    dob: localValues.dob ? localValues.dob : null,
    sex: gender,
  };

  const [formValues, setFormValues] = useState(initialVAlues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });

    // setErrors({
    //   ...errors,
    //   [name]: value === "" ? "This Feild Is Required" : "",
    // });
  };

  let imageUri = "";

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const [imgSrc, setImgSrc] = useState("");
  const [croppenImg, setCroppenImg] = useState("");

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    var file1 = new File([u8arr], filename, { type: mime });
    var formData = new FormData();
    formData.append("file", file1);



    mediaUpload(formData, getLocalData("user-info")?.userToken)
      .then((response) => {

        if (response.status === "Success") {
          setFormValues({
            ...formValues,
            pictureId: response.response.pictureId,
            pictureUrl: response.response.pictureUrl,
          });
          setShow(false);
        }
      })
      .catch((err) => { });

    return new File([u8arr], filename, { type: mime });
  }

  const cropperRef = useRef(null);
  const handleCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    //console.log();

    var file1 = dataURLtoFile(
      cropper.getCroppedCanvas({ fillColor: '#fff', width: 320, height: 320 }).toDataURL(),
      "product-img-320X320"
    );
  };

  const openCropper = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      //setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      handleShow();
    }
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const savePorfile = () => {


    let tempGender = null;

    if (formValues.sex === parseInt(1)) {
      tempGender = "Male";
    } else if (formValues.sex === parseInt(2)) {
      tempGender = "Female";
    }

    let tempDOB = moment(formValues.dob).toISOString();

    var tempUser = {
      ...formValues,
      sex: tempGender,
      dob: tempDOB,
    };

    var finalUserData = {
      ...localValues,
      ...tempUser,
    };

    var tempTOken = getLocalData("user-info")?.userToken;

    UpdatePatientProfile(finalUserData, tempTOken)
      .then((response) => {
        let userInfo = {
          userToken: getLocalData("user-info")?.userToken,
          userData: response.response.patientData,
        };

        deleteFromLocalData("user-info");
        setLocalData("user-info", userInfo);
        dispatch(updateUser(response.response.patientData));
        swal("Profile has been updated successfully !", {
          icon: "success",
        }).then(() => {
          navigate("/user");
        });
      })
      .catch(() => { });
  };



  const [otpModal, setOtpModal] = useState(false)
  const [isPhoneVerified, setIsPhoneVerified] = useState(false)

  const verifyPhone = (e) => {
    e.preventDefault();
    setOtpModal(true)
  }

  const { t } = useTranslation();

  return (
    <Row>
      <Col md={12}>
        <span className="header-1"><span on onClick={() => { navigate('/user') }}><ArrowBackIcon /></span>{t("ucp-t-2")}</span>
      </Col>

      <Col md={12} >
        <Row className="account-general edit-profile">
          <Col md={6} xs={12} >
            <form className="address-form">
              <Row>
                <Col md={12}>
                  <TextField
                    label="Name"
                    name="name"
                    value={formValues.name}
                    variant="standard"
                    onChange={handleInputChange}
                  />
                </Col>
                <Col md={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="Date of Birth"
                      inputFormat="DD/MM/yyyy"
                      value={formValues.dob}
                      onChange={(newdate) =>
                        setFormValues({
                          ...formValues,
                          dob: newdate,
                        })
                      }
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Col>

                <Col md={12}>
                  <TextField
                    label="Email"
                    name="email"
                    value={formValues.email}
                    variant="standard"
                    onChange={handleInputChange}
                  />
                </Col>
                <Col md={12} className="mt-3 mb-3 gender">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="standard"
                    value={formValues.sex}
                    label="Gender"
                    onChange={(event) =>
                      setFormValues({
                        ...formValues,
                        sex: event.target.value,
                      })
                    }
                  >
                    <MenuItem value={0}>select a gender</MenuItem>
                    <MenuItem value={1}>Male</MenuItem>
                    <MenuItem value={2}>Female</MenuItem>
                  </Select>
                </Col>
                <Col md={12} className="mb-3 mt-1">
                  <Row>
                    <Col md={12}>

                      <Row className="phone-no-grp">
                        {
                          !isPhoneVerified && (
                            <Col md={12} className="verify-profile">
                              <button onClick={(e) => { verifyPhone(e) }}>{t("ucca-t-7")}</button>
                            </Col>
                          )
                        }
                        {
                          isPhoneVerified && (
                            <Col md={12} className="verify-profile">
                              <span className="mobile-header">{t("ucca-t-7")}</span>
                              <span >+{formValues.countryCode}{" "}{formValues.phoneNumber}</span>
                            </Col>
                          )
                        }

                      </Row>

                    </Col>
                  </Row>
                </Col>
                <Col md={12} className="text-center">
                  <Modal show={otpModal} onHide={() => setOtpModal(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>{t("ep-t-1")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Phone formValues={formValues} setFormValues={setFormValues} setOtpModal={setOtpModal} setIsPhoneVerified={setIsPhoneVerified} />
                    </Modal.Body>

                  </Modal>
                </Col>
              </Row>
            </form>
          </Col>
          <Col md={6} xs={12} className="img-container-mobile">
            <div className="img-container-edit-profile">
              <div className="order-details-address-div-button-edt">
                <label htmlFor="file">
                  <img src={pen} alt="change" />{" "}
                </label>

                <input
                  type="file"
                  id="file"
                  onChange={(e) => openCropper(e)}
                  hidden
                />
              </div>
              <img
                src={formValues.pictureUrl}
                key={formValues.pictureUrl}
                alt="profile"
              />
            </div>
          </Col>

        </Row>
        <Row>
          <Col md={12}>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>{t("ep-t-2")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Cropper
                  src={imgSrc}
                  style={{ height: 400, width: "100%" }}
                  // Cropper.js options
                  initialAspectRatio={1 / 1}
                  aspectRatio={1 / 1}
                  guides={true}
                  //crop={onCrop}
                  crop={() => handleCrop}
                  ref={cropperRef}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleCrop}>
                  Crop
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col md={6} className="text-center">
            <a
              className="btn btn-info address-form-button"
              onClick={() => savePorfile()}
            >
              {" "}
              Save Changes{" "}
            </a>
          </Col>
          <Col md={6}></Col>
        </Row>

      </Col>
    </Row>
  );
}

export default EditProfile;

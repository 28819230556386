import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import Phone from '../components/SvgComponents/Phone';
import locIcn from '../static/locOut.svg'


import { useTranslation } from "react-i18next";
import MapOutlet from '../components/Map/MapOutlet';
import OutletHeader from '../components/SvgComponents/OutletHeader';

function Outlets() {

    const outlets = useSelector((state) => state.outlet.outArray);
    const { t } = useTranslation();

    console.log(outlets);

    let initialValues = {
        fullName: "",
        mobileNumber: "",
        address: "",
        landmark: "",
        state: "",
        city: "",
        country: "",
        zipCode: "",
        latitude: 0,
        longitude: 0,
        addressCategory: "home"
    };
    const [locateOut, setLocateOut] = useState({})


    useEffect(() => {
        if (outlets?.length > 0) {
            setLocateOut({
                lat: outlets[0].latitude,
                lng: outlets[0].longitude
            })
        }
    }, [outlets])

    //Form Values Start
    const [values, setValues] = useState(initialValues);


    return (
        <section className='space-at-top all-back'>
            <div className="container out-cont">


                <Row className='ol-pr'>

                    <Col md={12}>
                        <MapOutlet
                            inputValues={values}

                            locateOut={locateOut}
                            outlets={outlets}
                        />
                    </Col>
                    <Col md={12} className="outlet-list-parent">
                        <div className='container '>
                            <Row className="pb-2">
                                <Col md={12} className="d-flex flex-column">
                                    <span className="header-1">{t("olt-t-1")} </span>
                                </Col>

                            </Row>
                            <div className='outlet-listparent outlet-listparent2'>
                                {
                                    outlets.map((outlet) => (
                                        <div className='outlet-list' key={outlet.id}>
                                            <Row >
                                                <Col md={2} className="d-flex justify-content-left outlet-list-img flex-column align-items-center">
                                                    <OutletHeader />
                                                    <div className="out-sel-id">
                                                        {outlet.id}
                                                    </div>
                                                </Col>
                                                <Col md={10} className="p-0">
                                                    <span className='out-sel-t1'>{outlet.outletName}</span>

                                                    <div className='out-sel-t2'>
                                                        <div className='out-sel-t21'>
                                                            <img src={locIcn} alt="loc" />
                                                        </div>
                                                        <div className='out-sel-t22'>
                                                            {outlet.address} {outlet.city} {outlet.state} {outlet.country} {outlet.zipCode}
                                                        </div>
                                                    </div>

                                                    <Row>

                                                        {
                                                            !outlet.is24x7 && (
                                                                <>
                                                                    <Col md={12}>
                                                                        <span className='out-sel-t2-h'>Operating days</span>
                                                                    </Col>
                                                                    <Col md={12} className="mt-2">
                                                                        <span className='out-sel-days'>
                                                                            <div className={`out-week-btn ${outlet.mon === true ? 'out-week-btn-active' : ''}`}>Mon</div>
                                                                            <div className={`out-week-btn ${outlet.tue === true ? 'out-week-btn-active' : ''}`}>Tue</div>
                                                                            <div className={`out-week-btn ${outlet.wed === true ? 'out-week-btn-active' : ''}`}>Wed</div>
                                                                            <div className={`out-week-btn ${outlet.thu === true ? 'out-week-btn-active' : ''}`}>Thu</div>
                                                                            <div className={`out-week-btn ${outlet.fri === true ? 'out-week-btn-active' : ''}`}>Fri</div>
                                                                            <div className={`out-week-btn ${outlet.sat === true ? 'out-week-btn-active' : ''}`}>Sat</div>
                                                                            <div className={`out-week-btn ${outlet.sun === false ? 'out-week-btn-active' : ''}`}>Sun</div>
                                                                        </span>
                                                                    </Col>
                                                                    <Col md={12} className="mt-3">
                                                                        <span className='out-sel-t2-h'>Operating time</span>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <span className='out-sel-t2-text'>{outlet.operatingStartTime} - {outlet.operatingEndTime}</span>
                                                                    </Col>

                                                                </>
                                                            )
                                                        }
                                                        {
                                                            outlet.is24x7 && (
                                                                <>
                                                                    <Col md={12}>
                                                                        <span className='out-sel-t2-h'>24X7</span>
                                                                    </Col>

                                                                </>
                                                            )
                                                        }

                                                        <Col md={12} className="mt-3">
                                                            <span className='out-sel-t2-h'>Contact no</span>
                                                        </Col>
                                                        <Col md={12}>
                                                            <span className='out-sel-t2-text'>+{outlet.countryCode}{outlet.phoneNumber}</span>
                                                        </Col>

                                                    </Row>

                                                    <div className='out-contact'>
                                                        <button className='selout-btn ' onClick={() => {
                                                            setLocateOut({
                                                                lat: outlet.latitude,
                                                                lng: outlet.longitude

                                                            })
                                                        }}>Locate</button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>


                    </Col>


                </Row>
            </div>
        </section>

    )
}

export default Outlets
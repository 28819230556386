import React from 'react'

function Bag() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="23" viewBox="0 0 18 23" fill="none">
<path d="M15.2543 5.05268H13.1753C13.1753 2.61178 11.3146 0.634766 9.01724 0.634766C6.71993 0.634766 4.85921 2.61178 4.85921 5.05268H2.78019C1.63673 5.05268 0.701172 6.04671 0.701172 7.26164V20.5154C0.701172 21.7303 1.63673 22.7243 2.78019 22.7243H15.2543C16.3978 22.7243 17.3333 21.7303 17.3333 20.5154V7.26164C17.3333 6.04671 16.3978 5.05268 15.2543 5.05268ZM9.01724 2.84372C10.1607 2.84372 11.0963 3.83775 11.0963 5.05268H6.93823C6.93823 3.83775 7.87378 2.84372 9.01724 2.84372ZM15.2543 20.5154H2.78019V7.26164H4.85921V9.4706C4.85921 10.0781 5.32699 10.5751 5.89872 10.5751C6.47045 10.5751 6.93823 10.0781 6.93823 9.4706V7.26164H11.0963V9.4706C11.0963 10.0781 11.564 10.5751 12.1358 10.5751C12.7075 10.5751 13.1753 10.0781 13.1753 9.4706V7.26164H15.2543V20.5154Z" fill="#fff"/>
</svg>
  )
}

export default Bag
import axios from "axios";

const pharmacyId = process.env.REACT_APP_PHARMACY_ID;


export const getProductByCategory = async (
  
  categoryId,
  pageNo,
  pageSize
) => {
  
  
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/GetCategoryProducts`,
    {
      pageNo, pageSize, pharmacyId, categoryId
    }
  );

  const dataPromise = promise.then((response) => response.data.response.data);

  return dataPromise;
};

export const getProductByManufacturer = async (
  
  manufacturerId,
  pageNo,
  pageSize
) => {

  const promise = axios.post(
    `${process.env.REACT_APP_API}api/GetManufacturerProducts`,
    {
      pageNo, pageSize, pharmacyId, manufacturerId
    }
  );

  const dataPromise = promise.then((response) => response.data.response.data);

  return dataPromise;

 
};

export const getProductByID = async (productId,outletId) => {
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/V1.1/GetProductDetails`,
    {
      productId,
      outletId
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};

export const getFeaturedProducts = async (pageNo, pageSize) => {
  
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/GetFeaturedProducts`,
    {
      pharmacyId,
      pageNo,
      pageSize,
    }
  );

  const dataPromise = promise.then((response) => {

    return response.data.response});

  return dataPromise;
};


export const getProductsInOffer = async (pageNo, outletId, pageSize) => {
  
  const promise = axios.post(`${process.env.REACT_APP_API}api/v1/GetOfferProductsWithPagination`,{
    pharmacyId,
    pageNo,
    outletId,
    pageSize
  });

  const dataPromise = promise.then((response) => {

    return response.data.response});

  return dataPromise;
};

export const getNewProducts = async (pageNo, pageSize) => {
  
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/GetNewlyAddedProducts`,
    {
      pharmacyId,
      pageNo,
      pageSize,
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};


export const searchProductsAPI = async ( keyword, pageNo, pageSize) => {
 
  const promise = axios.post(`${process.env.REACT_APP_API}api/v1/SearchMedicines`, {
    pharmacyId,
    pageNo ,
    pageSize ,
    keyword
  });

  const dataPromise = promise.then((response) => response.data.response.data);

  return dataPromise;
};


export const filterProductsAPI = async (  filters ) => {
 
  const promise = axios.post(`${process.env.REACT_APP_API}api/v1.1/GetProductsByFilter`, {
    ...filters,
    pharmacyId,
    
  });

  const dataPromise = promise.then((response) => {
    
   return response.data.response.data
  }  );

  
  return dataPromise;
};


export const getRelatedProductsRandom = async (productId) => {
  
  const promise = axios.get(
    `${process.env.REACT_APP_API}api/v1/GetRelatedProductsRandom?productId=${productId}`,
    {
      
    }
  );

  const dataPromise = promise.then((response) => {

    return response.data.response});

  return dataPromise;
};
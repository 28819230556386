import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import { getLocalData } from "../../utils/localStorage";

import Arrow from "../../static/Product/arrow.svg";

import { useSelector } from "react-redux";
import { getAllAddress } from "../../services/AddressService";
import CreateAddressComp from "./CreateAddressComp";
import { useTranslation } from "react-i18next";
import OutletSelector from "../OutletSelector/OutletSelector";
import CountryCodeSelector from "../AtomicComponents/CountryCodeSelector";

function AddressComp(props) {

  const { setshippingmethod, setaddressidtwo, defSelectedOutlet, setDefSelectedOutlet } = props;
  const [modalShow, setModalShow] = useState(false);
  const [allAddress, setAllAddress] = useState([]);

  const [addressid, setaddressid] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState("");

  const { t } = useTranslation();





  useEffect(() => {
    if (addressid !== 0) {
      getAllAddress(getLocalData("user-info")?.userToken)
        .then((data) => {
          setaddressidtwo(addressid)
          setSelectedAddress(
            data.filter((add) => add.id === parseInt(addressid))
          );
        })
        .catch((err) => console.log(err));
    }
  }, [addressid]);

  const openAddressModal = () => {

    getAllAddress(getLocalData("user-info")?.userToken)
      .then((data) => {
        setAllAddress(data);
        setModalShow(true);
      })
      .catch((err) => console.log(err));
  };


  const [outletSelModalShow, setOutletSelModalShow] = useState(false);




  const [isOutEnabled, setIsOutEnabled] = useState(true)

  const changeDeliveryMethod = (event) => {
    setshippingmethod(event.target.value)
    if (event.target.value === 'StorePickup') {
      setIsOutEnabled(true)
    } else {
      setIsOutEnabled(false)
    }

  };
  const [iscreateaddressactivated, setiscreateaddressactivated] =
    useState(false);
  const [ismapactivated, setismapactivated] = useState(false);

  return (
    <Row>
      <Col md={12}>
        <div className="row">
          <div className="os-header col-md-12">{t("ccac-t-1")}</div>
        </div>
      </Col>
      <Col md={12}>
        <Form>
          <div onChange={changeDeliveryMethod}>
            <Form.Check
              className="mb-3 form-check-radio"
              type="radio"
              id={`check-api-${2}`}
            >
              <Form.Check.Input
                name="group1"
                type="radio"
                value={"CashOnDelivery"}
              />
              <Form.Check.Label className="disc-label">
                <Row className="disc-row">
                  <Col md={12} className="disc-address-opt">
                    <div>
                      {t("ccac-t-2")}
                      {addressid === 0 && (
                        <Row>
                          <Col md={12}>
                            <button
                              className="select-address-btn"
                              onClick={(e) => {
                                e.preventDefault();

                                openAddressModal()
                              }}
                            >
                              {t("ccac-t-3")}
                            </button>
                          </Col>
                        </Row>
                      )}
                      {addressid !== 0 && (
                        <Row>
                          <Col md={12} className="text-right"><button
                            className="select-address-btn"
                            onClick={(e) => {
                              e.preventDefault();

                              openAddressModal()
                            }}
                          >
                            {t("ccac-t-4")}
                          </button></Col>
                          <Col md={12}>
                            {selectedAddress.length > 0 &&
                              selectedAddress.map((add, index) => {
                                return (
                                  <div key={index} className="selected-adddd">
                                    <div>
                                      <span>{add.fullName}</span>
                                      <span>{add.mobileNumber}</span>
                                    </div>
                                    <div>{add.address}</div>
                                    <div>
                                      {add.city} {add.state} {add.zipCode}
                                    </div>
                                  </div>
                                );
                              })}
                          </Col>
                        </Row>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form.Check.Label>
            </Form.Check>
            <Form.Check
              className="mb-3 form-check-radio"
              type="radio"
              id={`check-api-${1}`}
            >
              <Form.Check.Input
                name="group1"
                type="radio"
                value={"StorePickup"}
                defaultChecked
              />
              <Form.Check.Label className="disc-label">
                <Row className="disc-row">
                  <Col md={12} className="disc-address-opt">
                    <div>{t("ccac-t-5")}</div>

                    <span
                      className={`select-address-btn ${isOutEnabled === false ? 'd-none' : ''}`}

                    >
                      {defSelectedOutlet.name}
                    </span>

                    <span onClick={() => setOutletSelModalShow(true)} className={`select-address-btn change-address-btn ${isOutEnabled === false ? 'd-none' : ''}`}>
                      CHANGE
                    </span>
                  </Col>
                </Row>
              </Form.Check.Label>
            </Form.Check>
          </div>
        </Form>
      </Col>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => {

          setModalShow(false);
          setismapactivated(false);
          setiscreateaddressactivated(false);

        }}
        alladdress={allAddress}
        setaddressid={setaddressid}
        setismapactivated={setismapactivated}
        ismapactivated={ismapactivated}
        setiscreateaddressactivated={setiscreateaddressactivated}
        iscreateaddressactivated={iscreateaddressactivated}
        t={t}
      />

      <MyVerticallyCenteredModalPickupAtStore
        show={outletSelModalShow}
        onHide={() => {

          setOutletSelModalShow(false);


        }}
        setDefSelectedOutlet={setDefSelectedOutlet}
        defSelectedOutlet={defSelectedOutlet}
        setOutletSelModalShow={setOutletSelModalShow}

        t={t}
      />
    </Row>
  );
}

export default AddressComp;

function MyVerticallyCenteredModal(props) {
  const { alladdress, setaddressid, onHide, iscreateaddressactivated, setiscreateaddressactivated, ismapactivated, show, setismapactivated, t } = props;

  const [selectaddressid, setselectaddressid] = useState(0);
  const [selectaddressidRadio, setselectaddressidRadio] = useState(0);

  const [phoneNumber, setPhoneNumber] = useState({
    countryCode: `${process.env.REACT_APP_DEFAULT_COUNTRYCODE}`,
    number: "",
  });

  function onBrandFilterChange(event) {
    setselectaddressidRadio(event.target.value);

  }

  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {

    onHide();
    setaddressid(selectaddressid);
    setiscreateaddressactivated(false);
    setismapactivated(false);
  }, [selectaddressid]);

  const setAddressfromRadio = () => {
    setselectaddressid(selectaddressidRadio);
  };



  const addNewAddress = () => {
    setiscreateaddressactivated(true);
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {!iscreateaddressactivated && (
            <span className="modal-header-cust">{t("ccac-t-6")}</span>
          )}
          {iscreateaddressactivated && !ismapactivated && (
            <>
              <span
                className="modalBanck-btn"
                onClick={() => {
                  setiscreateaddressactivated(false);
                }}
              >
                <img alt="dfds" src={Arrow} />{" "}
              </span>
              <span className="modal-header-cust">{t("ccac-t-7")}</span>
            </>
          )}
          {iscreateaddressactivated && ismapactivated && (
            <>
              <span
                className="modalBanck-btn"
                onClick={() => {
                  setismapactivated(false);
                }}
              >
                <img alt="dfds" src={Arrow} />{" "}
              </span>
              <span className="modal-header-cust">{t("ccac-t-8")}</span>
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="disc-mb">
        {!iscreateaddressactivated && (
          <div>
            <div className="mb-3">
              <button
                className="btn  pd-btn "
                onClick={() => {
                  addNewAddress();
                }}
              >
                {t("ccac-t-9")}
              </button>
            </div>
            <Form>
              <div className="mb-3" onChange={onBrandFilterChange}>
                {alladdress.length > 0
                  ? alladdress.map((address, index) => {
                    return (
                      <Form.Check
                        className="mb-3 form-check-radio"
                        key={address.id}
                        type="radio"
                        id={`check-api-add-${address.id}`}
                      >
                        <Form.Check.Input
                          name="group2"
                          type="radio"
                          value={address.id}
                        />
                        <Form.Check.Label className="disc-label">
                          {" "}
                          <Row className="disc-row">
                            <Col md={12} className="disc-discountSave">
                              {address.fullName} {address.mobileNumber}
                            </Col>
                            <Col md={12} className="disc-discountAppOn">
                              {address.address}
                            </Col>
                            <Col md={12} className="disc-discountAppOn">
                              {address.city} {address.state} {address.zipCode}
                            </Col>
                          </Row>
                        </Form.Check.Label>
                      </Form.Check>
                    );
                  })
                  : ""}
              </div>
            </Form>
            <div className="add-modal-btns">
              <button
                type="submit"
                className="btn btn-info address-form-button"
                onClick={() => setAddressfromRadio()}
              >
                {t("ccac-t-10")}
              </button>
            </div>
          </div>
        )}
        {iscreateaddressactivated && (
          <div>
            <CreateAddressComp
              setselectaddressid={setselectaddressid}
              setiscreateaddressactivated={setiscreateaddressactivated}
              setismapactivated={setismapactivated}
              ismapactivated={ismapactivated}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}



function MyVerticallyCenteredModalPickupAtStore(props) {
  const { onHide, show, setDefSelectedOutlet, defSelectedOutlet, setOutletSelModalShow, t } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"

    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          outlet picker


        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <OutletSelector setDefSelectedOutlet={setDefSelectedOutlet} defSelectedOutlet={defSelectedOutlet} setOutletSelModalShow={setOutletSelModalShow} />
      </Modal.Body>
    </Modal>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "react-bootstrap";

import Pagination from "@mui/material/Pagination";
import ProductGridItem from "../components/ProductGridItem";
import {  getProductsInOffer } from "../services/ProductService";
import { getLocalData } from "../utils/localStorage";
import { useTranslation } from "react-i18next";

function AllOfferrProducts() {
  
  const { t } = useTranslation();
  const initProducts = [];
  const [products, setProducts] = useState(initProducts);
  const [isLoaded, setIsLoaded] = useState(false);
  

  const outletIdM = getLocalData('outlet-id')


  useEffect(() => {
    getProductsInOffer(1, outletIdM ,8).then(
      (res) => {
        setProducts(res.products);
        setIsLoaded(true);
        calculateNoOfPages(res.productCount)
      },
      (failure) => {
        console.error(failure); //expected output: Oopsy...
      }
    );
  }, []);

  const [noOfPages, setNoOfpages] = useState(1);
  const calculateNoOfPages = (tp) => {
    let tempVar = Math.ceil(tp / 8);
    if (tempVar === 0) {
      tempVar = 1;
    }

    setNoOfpages(tempVar);
  };


  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    getProductsInOffer(value, outletIdM ,8).then(
      (res) => {
        console.log(res)
        setProducts(res.products);
        setIsLoaded(true);
        calculateNoOfPages(res.productCount)
      },
      (failure) => {
        console.error(failure); //expected output: Oopsy...
      }
    );
    setPage(value);
  };
 
 
  return (
    <section className="space-at-top  all-back">
      <div className="container">
        <Row className="pb-2">
          <Col md={12}>
            <span className="header-1">{t("of-t-1")}</span>
          </Col>
        </Row>
        <Row className="mobile-grid">
          {isLoaded && (
            <>
            <Col md={12}>
              {products.length > 0 ? (
                <Row>
                  {products.map((product) => {
                    return (
                      product.published && (
                        <Col
                          lg={2}
                          md={2}
                          xs={6}
                          key={product.id}
                          className="even-spacer-y fcol "
                        >
                          <ProductGridItem
                            imgUrl={product.pictureUrl}
                            enableWishlist={true}
                            name={product.name}
                            brand={product.manufacturerName}
                            oldPrice={product.oldPrice}
                            price={product.price}
                            id={product.id}
                            type={product.drugType}
                          />
                        </Col>
                      )
                    );
                  })}
                  
                </Row>
              ) : (
                <div>{t("fp-t-1")}</div>
              )}
            </Col>
             <Col md={12}>
                      <div className="pagination-parent">
                        <Pagination
                          count={noOfPages}
                          page={page}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
            </>
            
            
          )}
        </Row>
      </div>
    </section>
  );
}

export default AllOfferrProducts;

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { getLocalData } from "../utils/localStorage";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Phone from "../components/Login/Phone";
import FacebookLogin from "../components/Login/FacebookLogin";
import GoogleLogin from "../components/Login/GoogleLogin";
import EmailLogin from "../components/Login/EmailLogin";

import Email from "../static/email.svg";
import CreateNewUser from "../components/Login/CreateNewUser";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
function Login() {

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    if (getLocalData("user-info")?.userToken?.length > 0) {
      navigate(from, { replace: true });
    }
  }, [getLocalData("user-info")?.userToken]);

  const [isLoginUsingPhoneNo, setIsLoginUsingPhoneNo] = useState(true);
  const [isLoginUsingEmail, setIsLoginUsingEmail] = useState(false);

  const [isRegistration, setIsRegistration] = useState(false);
  const [newEmail, setNewEmail] = useState("");

  const { t } = useTranslation();
  return (
    <section className="space-at-top">
      <div className="container">
        {!isRegistration && (
          <Row className="pb-2">
            <Col md={12} className="d-flex justify-content-center">
              <Box sx={{ width: { xs: '100%', lg: '35%', md: '70%' } }}>
                <div className="login-box">
                  {isLoginUsingPhoneNo && (
                    <div>
                      <span className="header-1-login">{t("lp-t-1")}</span>
                      <div>
                        <Phone />
                      </div>
                      <div className="login-or">
                        <span>{t("lp-t-2")}</span>
                      </div>
                      <div className="login-btn-grp">
                        <GoogleLogin />
                        <FacebookLogin />
                        {/* <span
                          className="login-btn"
                          onClick={() => {
                            setIsLoginUsingEmail(true);
                            setIsLoginUsingPhoneNo(false);
                          }}
                        >
                          <img alt="email" src={Email} />
                        </span> */}
                      </div>
                    </div>
                  )}
                  {isLoginUsingEmail && (
                    <div>
                      <span className="header-1-login">
                        {t("lp-t-3")}
                      </span>
                      <div>
                        <EmailLogin
                          setIsRegistration={setIsRegistration}
                          setNewEmail={setNewEmail}
                        />
                      </div>
                    </div>
                  )}

                  <div>
                    <span className="tandc">
                      {t("lp-t-4")} <Link to="/termsconditions">{t("fc-t-7")}</Link> {t("lp-t-7")} <Link to="/privacypolicy">{t("fc-t-8")}</Link>
                    </span>
                    <span className="gethelp">
                      {t("lp-t-5")}
                      <a href="mailto:support@digitalpharmacy.io" className="HelpLink">
                        <span>{t("lp-t-6")}</span>{" "}
                      </a>
                    </span>
                  </div>
                </div>
              </Box>
            </Col>
          </Row>
        )}

        {isRegistration && <CreateNewUser newEmail={newEmail} />}
      </div>
    </section>
  );
}

export default Login;

import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import Phone from '../SvgComponents/Phone';
import locIcn from '../../static/locOut.svg'
import OutletHeader from '../SvgComponents/OutletHeader';
import { getLocalData } from '../../utils/localStorage';
import MapOutlet from '../Map/MapOutlet';
import { useTranslation } from 'react-i18next';
function OutletSelector(props) {

    const {setDefSelectedOutlet, defSelectedOutlet, setOutletSelModalShow} = props;
    const outlets = useSelector((state) => state.outlet.outArray);

    let initialValues = {
        fullName: "",
        mobileNumber: "",
        address: "",
        landmark: "",
        state: "",
        city: "",
        country: "",
        zipCode: "",
        latitude: 0,
        longitude: 0,
        addressCategory: "home"
      };

      const { t } = useTranslation();
      
    

    //Form Values Start
    const [values, setValues] = useState(initialValues);


    const selectOutlet = (id,name) => {
        setDefSelectedOutlet({
            id: id,
            name: name
        })

        setOutletSelModalShow(false)
     }

    const [locateOut, setLocateOut] = useState({})

  return (
    <Row>
        <Col md={4}>
            <span className='out-sel-heading'>Select the pickup outlet</span>

            <span className='out-btn-picker'>There are {outlets.length} </span>

            <div className='outlet-listparent'>
                {
                    outlets.map((outlet)=>(
                        <div className='outlet-list' key={outlet.id}>
                            <Row>
                                <Col md={1}>
                                <OutletHeader />
                                <div className="out-sel-id">
                                {outlet.id}
                                </div>
                                </Col>
                                <Col md={11}>
                                    <span className='out-sel-t1'>{outlet.outletName}</span>
                             
                                    <div className='out-sel-t2'>
                                        <div className='out-sel-t21'>
                                            <img src={locIcn} alt="loc" />
                                        </div>
                                        <div className='out-sel-t22'>
                                        {outlet.address} {outlet.city} {outlet.state} {outlet.country} {outlet.zipCode}
                                        </div>
                                    </div>

                                    <Row>
                                        <Col md={3}>
                                            <span className='out-sel-t2-h'>Operating time</span>
                                        </Col>
                                        <Col md={9}>
                                            <span className='out-sel-t2-h'>Operating days</span>
                                        </Col>
                                        {
                                            !outlet.is24x7 && (
                                                <>
                                                    <Col md={3}>
                                                        <span className='out-sel-t2-text'>{outlet.operatingStartTime} - {outlet.operatingEndTime}</span>
                                                    </Col>
                                                    <Col md={9}>
                                                        <span className='out-sel-days'>
                                                            <div className={`out-week-btn ${outlet.mon === true ? 'out-week-btn-active' : ''}`}>Mon</div>
                                                            <div className={`out-week-btn ${outlet.tue === true ? 'out-week-btn-active' : ''}`}>Tue</div>
                                                            <div className={`out-week-btn ${outlet.wed === true ? 'out-week-btn-active' : ''}`}>Wed</div>
                                                            <div className={`out-week-btn ${outlet.thu === true ? 'out-week-btn-active' : ''}`}>Thu</div>
                                                            <div className={`out-week-btn ${outlet.fri === true ? 'out-week-btn-active' : ''}`}>Fri</div>
                                                            <div className={`out-week-btn ${outlet.sat === true ? 'out-week-btn-active' : ''}`}>Sat</div>
                                                            <div className={`out-week-btn ${outlet.sun === false ? 'out-week-btn-active' : ''}`}>Sun</div>
                                                        </span>
                                                    </Col>
                                                </>
                                            )
                                        }
                                        {
                                            outlet.is24x7 && (
                                                <>
                                                    <Col md={12}>
                                                        <span className='out-sel-t2-h'>24X7</span>
                                                    </Col>
                                                    
                                                </>
                                            )
                                        }
                                    </Row>

                                    <div className='out-contact d-none'>
                                        <a href={`tel:+${outlet.countryCode}${outlet.phoneNumber}`} className='phone-icn'>
                                        <Phone/>
                                        </a>
                                        <a href={`mailto: ${outlet.email}`} className='phone-icn'>
                                        <Phone/>
                                        </a>
                                    </div>

                                    
                                    <div className='out-contact d-none'>
                                       

                                        {
                                            defSelectedOutlet.id === outlet.id ? ( <a className='selout-btn disabled'  >Selected</a>) : (<a className='selout-btn'  onClick={()=>selectOutlet(outlet.id, outlet.outletName)}>Select outlet</a>)
                                        }
                                        

                                        
                                        
                                         <a className='selout-btn ' onClick={()=>{
                                            setLocateOut({
                                                lat: outlet.latitude,
                                                lng: outlet.longitude
                                          
                                            })
                                         }}>Locate</a>
                                        
                                        
                                        
                                       
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ))
                }
            </div>
        </Col>
        <Col md={8}>
        <MapOutlet
        inputValues={values}
     
        locateOut={locateOut}
        outlets={outlets}
        />
        </Col>
        
    </Row>
    
  )
}

export default OutletSelector
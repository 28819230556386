import React, {useEffect} from "react";
import { Row, Col } from "react-bootstrap";
import GeneralGridItem from "../GeneralGridItemSlider";
import {  getAllCategoryWithPagination2 } from "../../services/CategoryService";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'
import { updateCategoriesWidget } from '../../redux/homePageSlice'
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

function CategoryWidget() {

    const  items  = useSelector((state) => state.homepage.categoriesWidget);
    const  languageId  = 1;
  
  const dispatch = useDispatch()

  

  useEffect(() => {
    getAllCategoryWithPagination2( languageId, 1, 8).then(
        (res) => {
          // console.log(res)
          dispatch(updateCategoriesWidget(res)) ;
          
        },
        (failure) => {
          console.error(failure); 
        }
      );
  }, [languageId, dispatch]);

  const { t } = useTranslation();

  // slick settings
  let dragging = false;
  const settings = {
    dots: false,
    infinite: items.length > 6 ? true : false,

    slidesToShow: 6 ,
    slidesToScroll: 1,
    
    beforeChange: () => (dragging = true),
    afterChange: () => (dragging = false),

    autoplay: true,
    speed: 450,
    autoplaySpeed: 1110,
    responsive: [
      
      {
        breakpoint: 480,
        settings: {
          infinite: items.length > 2 ? true : false,
          centerPadding: "20px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="brand-sec-margin cat-widget">
      <div className="container cat-cont">
        <Row className="mt-1">
          <Col md={6} sm={6} xs={8} className="d-flex flex-column">
          <span className="home-head"> {t("gen-t-3")}   {t("gen-t-4")}</span>
            
          </Col>
          <Col md={6} sm={6} xs={4} className="d-flex align-items-center justify-content-end">
            <Link to={`/categories`} className="big-Link">
            {t("gen-t-5")}
            </Link>
          </Col>
        </Row>
        <Row className="mobile-grid">
          <Slider {...settings}>
            {items.map((item) => {
              return <GeneralGridItem imgUrl={ item.imageURL} key={item.id} totalProducts={item.totalProducts}  name= {item.name} id={item.id}  type="category" />;
            })}
           </Slider>
        </Row>
      </div>
    </section>
  );
}

export default CategoryWidget;

import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import AddressSvg from "./dynamicSvg/AddressSvg";
import OrdersSvg from "./dynamicSvg/OrdersSvg";
import PpSvg from "./dynamicSvg/PpSvg";
import ProfileSvg from "./dynamicSvg/ProfileSvg";
import TcSvg from "./dynamicSvg/TcSvg";
import WishlistSvg from "./dynamicSvg/WishlistSvg";

function AccountNav() {
    let location = useLocation();
    const { t } = useTranslation();

  return (
    <div className="account-nav-parent even-spacer-y">
      <div className="account-nav-sub">
        <span className="account-nav-subheading">{t("ucnav-t-1")}</span>
        <div className="account-nav-category-list">
          <Link  to="/user">
            <Row>
              <Col md={2} className="account-nav-counter-column">
                <span className=" text-center">
                  <ProfileSvg isActive={location.pathname === "/user" ? true : false} />
                </span>
              </Col>
              <Col
                md={10}
                className={`d-flex align-items-center subnav-text ${location.pathname === "/user" ? "active" : ""} `}
              >
                <span className="">{t("ucnav-t-2")}</span>
              </Col>
            </Row>
          </Link>
          <Link to="/user/address/">
          <Row>
            <Col md={2} className="account-nav-counter-column">
              <span className=" text-center">
                <AddressSvg isActive={location.pathname === "/user/address/" ||  location.pathname.includes("/user/updateAddress/") ||  location.pathname.includes("/user/createAddress") ? true : false} />
              </span>
            </Col>
            <Col md={10} className={`d-flex align-items-center subnav-text ${location.pathname === "/user/address/" ||  location.pathname.includes("/user/updateAddress/") ||  location.pathname.includes("/user/createAddress") ? "active" : ""} `}>
              <span className="">{t("ucnav-t-3")}</span>
            </Col>
          </Row>
          </Link>
          
        </div>
      </div>

      <div className="account-nav-sub">
        <span className="account-nav-subheading">{t("ucnav-t-4")}</span>
        <div className="account-nav-category-list">
            <Link to="/user/orders/">
            <Row>
            <Col md={2} className="account-nav-counter-column">
              <span className=" text-center">
                <OrdersSvg isActive={location.pathname === "/user/orders/" || location.pathname.includes("/user/orderdetails/")  ? true : false} />
              </span>
            </Col>
            <Col md={10}className={`d-flex align-items-center subnav-text ${location.pathname === "/user/orders/" || location.pathname.includes("/user/orderdetails/") ? "active" : ""} `}>
              <span className="">{t("ucnav-t-5")}</span>
            </Col>
          </Row>
            </Link>
          
        </div>
      </div>
      <div className="account-nav-sub">
        <span className="account-nav-subheading">{t("ucnav-t-6")}</span>
        <div className="account-nav-category-list">
            <Link to="/wishlist">
            <Row>
            <Col md={2} className="account-nav-counter-column">
              <span className=" text-center">
                <WishlistSvg />
              </span>
            </Col>
            <Col md={10} className="d-flex align-items-center subnav-text">
              <span className="">{t("ucnav-t-7")}</span>
            </Col>
          </Row>
            </Link>
          
        </div>
      </div>
      <div className="account-nav-sub">
        <span className="account-nav-subheading">{t("ucnav-t-8")}</span>
        <div className="account-nav-category-list">
          
          <Link to="/privacypolicy">
          <Row>
            <Col md={2} className="account-nav-counter-column">
              <span className=" text-center">
                <PpSvg isActive={false} />
              </span>
            </Col>
            <Col md={10} className="d-flex align-items-center subnav-text">
              <span className="">{t("ucnav-t-9")}</span>
            </Col>
          </Row>
          </Link>
          <Link to="/termsconditions">
          <Row>
            <Col md={2} className="account-nav-counter-column">
              <span className=" text-center">
                <TcSvg isActive={false} />
              </span>
            </Col>
            <Col md={10} className="d-flex align-items-center subnav-text">
              <span className="">{t("ucnav-t-10")}</span>
            </Col>
          </Row>
          </Link>
          
        </div>
      </div>
    </div>
  );
}

export default AccountNav;

import React from "react";

function CaretDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
    >
      <path
        d="M1.1665 1.375L5.49984 5.70833L9.83317 1.375"
       
        stroke="url(#svg-grad)"
        strokeWidth="1.08333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
        id="svg-grad"
          x1="1.69268"
          y1="5.22175"
          x2="4.8861"
          y2="-0.62137"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="start-color" />
          <stop offset="1" className="end-color" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CaretDown;

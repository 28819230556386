import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";

import Pagination from "@mui/material/Pagination";
import ProductGridItem from "../components/ProductGridItem";
import { filterProductsAPI } from "../services/ProductService";
import Filter from "../components/Product/Filter";
import FilterMobile from "../components/Product/FilterMobile";
import { getLocalData } from "../utils/localStorage";
import { useSelector } from "react-redux";
import { GetAllCategoriesHierchyFormat } from "../services/CategoryService";
import { Skeleton } from "@mui/material";
import BrandsInner from "../components/BrandsInner";

import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import CatFilter from "../components/Product/CatFilter";

function AllProductsCategory() {
  const outletIdM = getLocalData("outlet-id");

  const initialFilters = {
    pageNo: 1,
    pageSize: 9,
    discountParcentage: null,
    minPrice: null,
    maxPrice: null,
    manufacturerId: null,
    categoryId: null,
    manufacturers: [],
    categories: [],
    isNewlyProduct: false,
    isFeatureProduct: false,
  };

  const [filters, setFilters] = useState(initialFilters);
  const [fromPage, setFromPage] = useState(null);

  //load subcategories & banners

  const [categoryMore, setCategoryMore] = useState({
    categories: [],
    banner: [],
  });
  const [categoryMoreLoading, setCategoryMoreLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    setCategoryMoreLoading(true);
    GetAllCategoriesHierchyFormat(id, 1).then(
      (res) => {
        setCategoryMore(res);
        let tempCategory = res.categories.map((cat) => {
          return cat.id;
        });

        tempCategory.push(parseInt(id));

        console.log(tempCategory);
        setFilters({
          ...filters,
          categories: tempCategory,
        });

        setCategoryMoreLoading(false);
      },
      (failure) => {
        console.error(failure); //expected output: Oopsy...
        setCategoryMoreLoading(false);
      }
    );
  }, [id]);

  useEffect(() => {
    setFromPage("category");
    //setFilters(onLoadValues);
  }, []);
  const allCategories = useSelector((state) => state.category.allCategories);
  const allBrands = useSelector((state) => state.brand.allBrands);

  const initProducts = [];
  const [products, setProducts] = useState(initProducts);

  const [noOfPages, setNoOfpages] = useState(1);
  const calculateNoOfPages = (tp) => {
    let tempVar = Math.ceil(tp / initialFilters.pageSize);
    if (tempVar === 0) {
      tempVar = 1;
    }

    setNoOfpages(tempVar);
  };

  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setFilters({
      ...filters,
      pageNo: value,
    });
    setPage(value);
  };

  

  const [tempMaxPrice, setTempMaxPrice] = useState(7000);

  const [isLoading, setIsLoading] = useState(true);
  //hits when filter is updated
  useEffect(() => {
    setIsLoading(true);

    if (filters !== null && filters.categories.length > 0) {
      let tempFilters = {
        ...filters,
        outletId: outletIdM,
      };

      filterProductsAPI(tempFilters).then(
        (res) => {
          setProducts(res.products);
          if (tempMaxPrice <= res.matchingProductProductMaxValue) {
            setTempMaxPrice(res.matchingProductProductMaxValue);
          }

          calculateNoOfPages(res.matchingProductCount);
          setIsLoading(false);
        },
        (failure) => {
          console.error(failure); //expected output: Oopsy...
        }
      );
    }
  }, [filters, outletIdM]);

  //screen size

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  //drawer

  const resetfilters = () => {
    let onLoadValues = {};

    if (window.location.href.indexOf("category") > -1) {
      onLoadValues = {
        ...initialFilters,
        categoryId: id,
      };
      setFromPage("category");
    } else if (window.location.href.indexOf("brand") > -1) {
      onLoadValues = {
        ...initialFilters,
        manufacturerId: id,
      };
      setFromPage("brand");
    }

    setFilters(onLoadValues);
  };

  // slick settings
  let dragging = false;
  const settings = {
    dots: false,
    infinite: categoryMore.categories.length > 5 ? true : false,
   
    slidesToShow: 5,
    slidesToScroll: 3,
    beforeChange: () => (dragging = true),
    afterChange: () => (dragging = false),
    autoplay: true,
    speed: 400,
    autoplaySpeed: 1500,
    responsive: [
      
      {
        breakpoint: 480,
        settings: {
          infinite: categoryMore.categories.length > 2 ? true : false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const pharmacy = useSelector((state) => state.pharmacy.pharmacyDetails.pharmacy)

  const isStock = (product) => {
    if (!pharmacy.isInventoryLiteEnabled || !pharmacy.isPosEnabled) {
      return true;
    } else {
      return product.stockQuantity > 0;
    }
  };

  const { t } = useTranslation();
  
  return (
    <section className="space-at-top">
      <div className="container">
        <Row className="pb-2">
          <Col md={3}></Col>
          <Col md={9}>
            {fromPage === "category"
              ? allCategories
                  .filter((category) => category.id === parseInt(id))
                  .map((category, index) => {
                    return (
                      <span key={index} className="header-1">
                        {category.name}
                      </span>
                    );
                  })
              : allBrands
                  .filter((brand) => brand.id === parseInt(id))
                  .map((brand, index) => {
                    return (
                      <span key={index} className="header-1">
                        {brand.name}
                      </span>
                    );
                  })}
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={4}></Col>
          <Col md={8}></Col>
          <Col md={4}></Col>
          <Col md={8}></Col>
        </Row>
        <Row>
          {windowDimenion.winWidth >= 787 && (
            <Col md={3} className="filter-main">
            <CatFilter selectedCategory={id}/>
              {fromPage && (
                <Filter
                  filters={filters}
                  setFilters={setFilters}
                  fromPage={fromPage}
                  tempMaxPrice={tempMaxPrice}
                />
              )}
            </Col>
          )}

          <Col md={9} xs={12}>
            <div>
              {categoryMoreLoading ? (
                <Skeleton variant="rectangular" height={320} />
              ) : (
                <>
                  {categoryMore.banner.map((item) => (
                    <img
                      className="category-banner"
                      src={item.pictureUrl}
                      alt={item.id}
                      key={item.id}
                    />
                  ))}
                </>
              )}
            </div>
            <div className="slick-container">
              {categoryMoreLoading ? (
                <Skeleton variant="rectangular" height={320} />
              ) : categoryMore.categories.length > 0 && (
                
                
                  <div className="subcategories-section">
                  <h3 className="subcat-head">{t("app-t-3")}</h3>
                    <Slider {...settings}>
                      {categoryMore.categories.map((item) => (
                        <BrandsInner key={item.id} item={item} dragging={dragging} />
                      ))}
                  
                      
                    </Slider>
                  </div>
                
              ) }
            </div>
            <div className="prduct-list-parent">
              {isLoading ? (
                <div className="fl-loader">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  {t("app-t-1")}
                </div>
              ) : (
                <>
                  {products.length > 0 ? (
                    <Row className="mobile-grid">
                      <Col md={12}>
                        <h3 className="allProducts">{t("app-t-4")}</h3>
                      </Col>

                      {products.map((product) => {
                        return (
                          product.published && (
                            <Col
                              lg={3}
                              md={3}
                              sm={6}
                              xs={6}
                              key={product.id}
                              className="even-spacer-y"
                            >
                              <ProductGridItem
                                imgUrl={product.pictureUrl}
                                enableWishlist={true}
                                name={product.name}
                                brand={product.manufacturerName}
                                oldPrice={product.oldPrice}
                                price={product.price}
                                id={product.id}
                                outOfStock={isStock(product)}
                                type={product.drugType}
                              />
                            </Col>
                          )
                        );
                      })}
                      <Col md={12}>
                        <div className="pagination-parent">
                          <Pagination
                            count={noOfPages}
                            page={page}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <div className="even-spacer-y">{t("app-t-2")}</div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
        {fromPage && (
          <FilterMobile
            filters={filters}
            setFilters={setFilters}
            fromPage={fromPage}
            tempMaxPrice={tempMaxPrice}
            resetfilters={resetfilters}
          />
        )}
      </div>
    </section>
  );
}

export default AllProductsCategory;

import React from 'react'

function Create() {
  return (
    <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M46.5416 26.7916H26.7916V46.5416H20.2083V26.7916H0.458252V20.2083H20.2083V0.458313H26.7916V20.2083H46.5416V26.7916Z" fill="url(#svg-grad)"/>
<defs>
        <linearGradient
          id="svg-grad"
          x1="0.584961"
          y1="15.5625"
          x2="15.885"
          y2="1.56197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0854" className="start-color" />
          <stop offset="0.922" className="end-color" />
        </linearGradient>
      </defs>
</svg>

  )
}

export default Create
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import profile from "../../static/DropDown/dd-profile-1.svg";
import address from "../../static/DropDown/dd-address-1.svg";
import contact from "../../static/DropDown/dd-contact.svg";
import wishlist from "../../static/DropDown/dd-wishlist-1.svg";
import signout from "../../static/DropDown/signout.svg";

import orders from "../../static/DropDown/dd-orderes-1.svg";
import { getLocalData } from "../../utils/localStorage";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { Col, Row } from "react-bootstrap";
import { updateUser } from "../../redux/userSlice";
import User from "../SvgComponents/User";
import { useTranslation } from "react-i18next";


function UserDropDown() {

  const { t } = useTranslation();
  const userData = useSelector((state) => state.user.userData);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (getLocalData("user-info")?.userToken?.length > 0) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    //console.log("data changed" + loggedIn);
  }, [getLocalData("user-info")?.userToken]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      {!loggedIn && (
        <Link to="/login" className="round-btn-header profile-btn expanded">
          <User />
          <div className="cart-ind-text"><span>€ 5.39</span><span className="counter-inner"> </span></div>
        </Link>
      )}

      {loggedIn && (
        <>
          <Tooltip title="">
            <span className="round-btn-header profile-btn expanded " onClick={handleClick}>
              <User />
              <div className="cart-ind-text"><span>€ 5.39</span><span className="counter-inner"> </span></div>
            </span>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <li className="profiledrop-img-heade">
              <img src={userData?.pictureUrl} alt="profilepic" />
              {userData?.name}
            </li>
            <Divider />
            <Link className="userDD-li" to="user">
              <MenuItem>
                <Row className="userDD-Row">
                  <Col md={2} className="userDD-icon">
                    <img src={profile} alt="profile" />
                  </Col>
                  <Col md={10}>
                    {t("hudd-t-2")}
                  </Col>
                </Row>
              </MenuItem>
            </Link>
            <Link className="userDD-li" to="user/orders">
              <MenuItem >
                <Row className="userDD-Row">
                  <Col md={2} className="userDD-icon">
                    <img src={orders} alt="Orders" />
                  </Col>
                  <Col md={10}>
                    {t("hudd-t-3")}
                  </Col>
                </Row>
              </MenuItem>
            </Link>
            <Link className="userDD-li" to="wishlist">
              <MenuItem>
                <Row className="userDD-Row">
                  <Col md={2} className="userDD-icon">
                    <img src={wishlist} alt="Wishlist" />
                  </Col>
                  <Col md={10}>
                    {t("wp-t-1")}
                  </Col>
                </Row>
              </MenuItem>
            </Link>

            <Link className="userDD-li d-none" to="user">
              <MenuItem>
                <Row className="userDD-Row">
                  <Col md={2} className="userDD-icon">
                    <img src={contact} alt="Contact" />
                  </Col>
                  <Col md={10}>
                    {t("hudd-t-4")}
                  </Col>
                </Row>
              </MenuItem>
            </Link>

            <Link className="userDD-li" to="user/address/">
              <MenuItem>
                <Row className="userDD-Row">
                  <Col md={2} className="userDD-icon">
                    <img src={address} alt="Adresses" />
                  </Col>
                  <Col md={10}>
                    {t("hudd-t-5")}
                  </Col>
                </Row>
              </MenuItem>
            </Link>

            <li>
              <button className="btn btn-secondary btn-logout" onClick={() => {
                localStorage.clear();
                dispatch(updateUser({}))
                navigate("/");
              }}>
                <img src={signout} alt="signout" />
                {t("hc-t-7")}
              </button>
            </li>
            <MenuItem

            >


            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
}

export default UserDropDown;

import locIcn from '../../static/loc.svg'
import storeSolid from '../../static/storeSolid.svg'
import locOut from '../../static/locOut.svg'

function InfoBoxOutlet(props) {
  

  const {isDetectLocEnabled, outlets, getLatLng, selectOutlet} = props;
 

  return (
    <div  className="info-box">
      {
        isDetectLocEnabled && (
          <div className="dete-m-loc d-flex" onClick={()=> getLatLng()}>
          
            <div className="dec-icn">
              <img src={locIcn} alt="loc-icn" />
            </div>
            <div className="dec-text">
              <span className="dec-text-u">Detect my location</span>
              <span className="dec-text-l">Using GPS</span>
            </div>

          </div>
        )
      }

      {
        outlets.length > 0 && (
          <>
          <div>
            <span className='outlets-header-title'>Outlets</span>
          </div>  
        <div className='out-list'>
        {
          outlets.map((outlet, index)=>{
            return (
              <div className='outlet-widget' key={outlet.id} onClick={()=> selectOutlet(outlet.id, outlet.outletName)}>
              <div className='outlet-widget-inc'>
                <img src={storeSolid} alt="storeSolid" />
                <span> {outlet.id}</span>
              </div>
              <div className='outlet-widget-text'>
                <div className='outlet-widget-text-1'>
                {outlet.outletName} 
                </div>
                <div className='outlet-widget-text-2'>
                  <div className='outlet-widget-text-2-1'>
                  {outlet.distance.toString()
                    .match(/^-?\d+(?:\.\d{0,2})?/)[0]}
                  </div>
                  <div className='outlet-widget-text-2-2'>
                  kms<br/>From you
                  </div>
                </div>
                <div className='outlet-widget-text-3'>
                <div className='outlet-widget-text-3-1'>
                  <img src={locOut} alt="loc" />
                  </div>
                  <div className='outlet-widget-text-3-2'>
                  {outlet.address}, 
                  {outlet.city} ,
                  {outlet.country} ,
                  {outlet.zipCode}
                  </div>
                  
                </div>
              </div>
            </div>
            )
           
          })
        }
        </div>
          
          </>
          
        )
      }
    </div>
  );
}

export default InfoBoxOutlet;

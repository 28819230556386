import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { getAllCopouns, getOrderTotal } from "../../services/OrderService";
import { getLocalData } from "../../utils/localStorage";

import Verified from "../../static/Product/verified.svg";
import Arrow from "../../static/Product/arrow.svg";
import Coupon from "../../static/Product/coupon.svg";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";



function DiscountComp(props) {

  const currency = useSelector((state) => state.pharmacy.currencySymbol);

  const { t } = useTranslation();

    const {setdiscountname, discountname, savedmoney} = props
  const [modalShow, setModalShow] = useState(false);
  const [allDiscounts, setAllDiscounts] = useState([]);

  useEffect(() => {
    if(allDiscounts.length > 0){
        setModalShow(true);
    }
    
  }, [allDiscounts]);

  const openCouponModal = () => {
    getAllCopouns(getLocalData("user-info")?.userToken)
      .then((data) => {
        
        setAllDiscounts(data);
      })
      .catch((err) => console.log(err));
  };

  const removeDiscount = () =>{
    setdiscountname('')
  }

  return (
    <Row>
      <Col md={2} xs={2}>
        <img alt="s" src={Coupon} />
      </Col>
      {discountname === ''? (
          <Col className="distitle" md={7} xs={7} >
            <span onClick={() => openCouponModal()}>
            {t("odcdc-t-1")}
            </span>
           
          </Col>
        ): (  
        <Col md={7} xs={7} className="distitle" >
          <span onClick={() => openCouponModal()}>
          {t("odcdc-t-2")}<div className="saved-money">
            {t("odcdc-t-3")} {currency}{" "} {savedmoney}</div>
          </span>
          </Col>)}
      
      <Col md={3} xs={3} className="coupn-end">
        {
          discountname === ''? (
            <img alt="s" src={Arrow} />
          ) : (
            <span onClick={()=> removeDiscount()}>remove</span>
          )
        }
     
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          alldiscounts={allDiscounts}
          setdiscountname={setdiscountname}
          currency={currency}
          t={t}
        />
      </Col>
    </Row>
  );
}

export default DiscountComp;

function MyVerticallyCenteredModal(props) {
  const { alldiscounts, setdiscountname , onHide, show, t, currency} = props;

  function onBrandFilterChange(event) {
    
    SetSelectedDiscount(event.target.value)
    SetIsVerified(false);
    setCheckCpnCode('')
  }

  const userData = useSelector((state) => state.user.userData);
  const checkCode = () => {
    setIsChecking(true)
    getOrderTotal(
      userData.id,
      getLocalData("user-info")?.userToken,
      "CashOnDelivery",
      checkCpnCode
    )
      .then((data) => {
        setIsChecking(false)
        if (data.discountAmountByCoupon === 0) {

          setCpnValidation(true);
         
        } else {
          setCpnValidation(false);
          SetIsVerified(true)
          SetIsVerifiedDiscount(data.discountAmountByCoupon)
          SetSelectedDiscount(checkCpnCode)
        }

        
      })
      .catch((err) => console.log(err));
  };

  const [checkCpnCode, setCheckCpnCode] = useState("");
  const [cpnValidation, setCpnValidation] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isVerified, SetIsVerified] = useState(false);
  const [isVerifiedDisCount, SetIsVerifiedDiscount] = useState(0);
  const [selectedDiscount, SetSelectedDiscount] = useState(0);


  const getSelectedDiscount = ()=> { 
    setdiscountname(selectedDiscount);
    onHide()
   }
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {t("odcdc-t-4")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="disc-mb">
        <div className="mb-3 d-none">
          <InputGroup className=" cpn-ipt-check">
            <Form.Control
              placeholder={t("odcdc-t-5")}
              aria-describedby="basic-addon2"
              value={checkCpnCode}
              onChange={(e) => {
                setCheckCpnCode(e.target.value);
              }}
            />
           

            {!isChecking && !isVerified && ( <Button
              variant="outline-secondary"
              onClick={() => checkCode()}
              id="button-addon2"
            >
            {t("odcdc-t-6")}
            </Button>)}

            {isChecking && (
              <Button
                variant="outline-secondary"
                id="button-addon2"
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )}

            {isVerified && (
              <Button
                variant="outline-secondary"
                id="button-addon2"
              >
                <img alt="v" src={Verified} />
              </Button>
            )}
          </InputGroup>
          {cpnValidation && <span className="cpn-exixts">{t("odcdc-t-11")}</span>}
        </div>
        <Form>
          <div className="mb-3" onChange={onBrandFilterChange}>
            {alldiscounts.length > 0
              ? alldiscounts.map((discount, index) => {
                  return (
                    <Form.Check
                      className="mb-3 form-check-radio"
                      key={index}
                      type="radio"
                      id={`check-api-disc-${index}`}
                    >
                      <Form.Check.Input
                        name="group33"
                        type="radio"
                        value={discount.couponCode}
                      />
                      <Form.Check.Label className="disc-label">
                        {" "}
                        <Row className="disc-row">
                          <Col md={12} className="disc-discountCode">
                            {" "}
                            <div>{discount.couponCode}</div>
                          </Col>
                          <Col md={12} className="disc-discountSave">
                          {t("odcdc-t-7")}  {currency}{" "} {discount.discountAmount}
                          </Col>
                          <Col md={12} className="disc-discountAppOn">
                          {t("odcdc-t-8")} {discount.appliedOn}
                          </Col>
                        </Row>
                      </Form.Check.Label>
                    </Form.Check>
                  );
                })
              : ""}
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="disc-mf">
        <div className="ftr-parent">
        {isVerified && (
              <>
                <span>
                {t("odcdc-t-10")}
                </span>
                <div>
                    $ {isVerifiedDisCount}
                </div>
              </>
            )}
        </div>
      
        <button className="btn ecom-solid dis-apply" onClick={()=>{getSelectedDiscount()}}>{t("odcdc-t-9")}</button>
      </Modal.Footer>
    </Modal>
  );
}

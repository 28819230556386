import React, { useEffect }  from 'react'
import { Row , Col} from 'react-bootstrap'
import GeneralGridItem from '../components/GeneralGridItem'
import { getAllBrands } from "../services/BrandService";

import { useSelector, useDispatch } from 'react-redux'
import { updateBrands } from '../redux/brandSlice'
import { useTranslation } from "react-i18next";

function AllBrands() {
  const  items  = useSelector((state) => state.brand.allBrands);

  const { t } = useTranslation();
  
  const dispatch = useDispatch()

  useEffect(() => {
    getAllBrands( 1, 1, 100).then(
      (res) => {
        dispatch(updateBrands(res))
      },
      (failure) => {
        console.error(failure); //expected output: Oopsy...
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className='allbrands-section space-at-top'>
      <div className="container">
        <Row className="pb-2">
          <Col md={12} className="d-flex flex-column">
            <span className="header-1">{t("hc-t-3")}</span>
          </Col>
          
        </Row>
        
          <Row className="mobile-grid">
            {
                items.map((item) => {
                  return <GeneralGridItem imgUrl={item.pictureUrl} key={item.id} totalProducts={item.totalProducts}  name= {item.name}  id={item.id}  type="brand"/> 
                })
              }
          </Row>
      </div>
    </section>
  )
}

export default AllBrands
import React, { useEffect, useState } from "react";

import { Row, Col, Modal } from "react-bootstrap";

import UploadPres from "../components/UploadPrescription/UploadPres";

import AddressComp from "../components/Cart/AddressComp";
import { useSelector } from "react-redux";
import { createOrderByPrescriptionOnly } from "../services/OrderService";

import { getLocalData } from "../utils/localStorage";

import presw from "../static/pres/presw.png";
import presm from "../static/pres/presm.png";

import OrderPlaced from "../static/Product/orderplaced.svg";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment";
import { useTranslation } from "react-i18next";

function UploadPrescription() {
  const [files, setFiles] = useState([]);

  const [isSideBarActive, setIsSideBarActive] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [shippingmethod, setshippingmethod] = useState("StorePickup");
  const [addressid, setaddressid] = useState(0);

  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);


  const outletObj = useSelector((state) => state.outlet.selectOutObj);

  const localOutlet = getLocalData("outlet-id")

  const [defSelectedOutlet, setDefSelectedOutlet] = useState({
    name: outletObj.outletName,
    id: 2
  })


  useEffect(() => {
    setDefSelectedOutlet({
      id: outletObj.outletID,
      name: outletObj.outletName,

    })
  }, [outletObj])


  useEffect(() => {
    if (files.length > 0) {
      setIsSideBarActive(true);
    } else {
      setIsSideBarActive(false);
    }
  }, [files]);


  const [orderItems, setOrderItems] = useState({
    "customerId": 0,
    "shippingAddressId": 0,
    "billingAddressId": 0,
    "outletId": 0,
    "prescriptionUrl": "string",
    "pictureId": 0,
    "shippingMethod": "string",
    "couponCode": "string",
    "hasPrescription": true,
    "orderByPrescription": true,
    "instruction": "",
    "prescriptionImageIds": [
      0
    ]
  });

  const handleInputChange = (e) => {
    const { value } = e.target;
    setOrderItems({
      ...orderItems,
      instruction: value,
    });


  };

  const userData = useSelector((state) => state.user.userData);
  const outletId = getLocalData('outlet-id')

  const placeOrderWithPrescriptionOnly = () => {



    let pictureIdArr = []
    files.forEach((file) => {
      pictureIdArr.push(file.serverId);
    })
    let finalItems = {
      ...orderItems,
      customerId: userData.id,
      outletId: defSelectedOutlet.id,
      prescriptionImageIds: pictureIdArr,
      shippingMethod: shippingmethod,
      shippingAddressId: addressid
    }

    if (
      finalItems.shippingMethod === "CashOnDelivery" &&
      finalItems.shippingAddressId === 0 &&
      finalItems.billingAddressId === 0
    ) {
      swal({
        icon: "error",
        text: t("cp-t-1"),
      });
    } else {
      console.log(finalItems, defSelectedOutlet.id)
      createOrderByPrescriptionOnly(
        finalItems,
        getLocalData("user-info")?.userToken,
      ).then((data) => {
        setOrderDetails(data.data.response.data);
        if (data.data.status === "Success") {
          setIsOrderPlaced(true);

        }
      })
        .catch((err) => console.log(err));
    }
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { t } = useTranslation();
  return (
    <section className="space-at-top all-back">
      {
        !isOrderPlaced && (
          <div className="container">
            <Row className="pb-2">
              <Col md={12}>
                <span className="header-1">{t("upp-t-1")}</span>
              </Col>
              <Row>
                <Col md={12} className="d-flex">
                  <div>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={10}>
                        <div className="pd-name mt-5 mb-3">
                          {t("upp-t-2")}

                        </div>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={12}>
                        <Row>
                          <Col md={1}></Col>
                          <Col md={10}>
                            <UploadPres files={files} setFiles={setFiles} setIsDisabled={setIsDisabled} />
                          </Col>
                          <Col md={1}></Col>
                        </Row>
                        {
                          isSideBarActive && (
                            <Row>
                              <Col md={1}></Col>
                              <Col md={10}>
                                <div className="form-group">
                                  <label htmlFor="exampleFormControlTextarea1">{t("upp-t-5")}</label>
                                  <textarea
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                    value={orderItems.instruction}

                                    onChange={handleInputChange}
                                  ></textarea>
                                </div>
                              </Col>
                              <Col md={1}></Col>
                            </Row>
                          )
                        }

                      </Col>
                      <Col md={1}></Col>
                      <Col md={10}>
                        <span className="pd-manufacturer-name">
                          {t("upp-t-3")}
                        </span>
                        <span className="vsam" onClick={() => handleShow()}>{t("upp-t-6")}</span>
                      </Col>
                      <Col md={1}></Col>
                      <Modal className="presImgMod" show={show} onHide={handleClose} centered>
                        <Modal.Header closeButton>
                          <Modal.Title>{t("upp-t-7")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                          <img src={presw} className="d-none d-md-block" alt={t("upp-t-7")} />
                          <img src={presm} className="d-block d-md-none" alt={t("upp-t-7")} />
                        </Modal.Body>
                      </Modal>
                    </Row>
                  </div>
                  <div className={`left-menu ${isSideBarActive ? "open" : ""}`}>
                    <Row>
                      <Col md={12}>

                        <div className="os-parent-button">

                          <button
                            onClick={() => placeOrderWithPrescriptionOnly()}
                            className="btn pd-btn pd-atc"
                            disabled={isDisabled ? 'disabled' : ''}
                          >
                            {t("cp-t-5")}
                          </button>

                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="os-parent-pymentmode">
                          {t("cp-t-6")}:<span> {t("cp-t-7")}</span>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="os-parent">
                          <AddressComp
                            setshippingmethod={setshippingmethod}
                            setaddressidtwo={setaddressid}

                            defSelectedOutlet={defSelectedOutlet}
                            setDefSelectedOutlet={setDefSelectedOutlet}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="os-parent">
                          <Row>
                            <Col md={12}>
                              <Row>
                                <Col xs={12} md={12} className="os-header">
                                  {t("cp-t-9")}
                                </Col>
                              </Row>

                              <Row>
                                <Col xs={6} md={6} className="os-text">
                                  {t("cp-t-8")}
                                </Col>
                                <Col xs={6} md={6} className="os-numbers">
                                  {t("upp-t-4")}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} md={6} className="os-text">
                                  {t("cp-t-10")}
                                </Col>
                                <Col xs={6} md={6} className="os-numbers">
                                  {t("upp-t-4")}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} md={6} className="os-text">
                                  {t("cp-t-11")}
                                </Col>
                                <Col xs={6} md={6} className="os-numbers">
                                  {t("upp-t-4")}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} md={6} className="os-text">
                                  {t("cp-t-12")}
                                </Col>
                                <Col xs={6} md={6} className="os-numbers">
                                  {t("upp-t-4")}
                                </Col>
                              </Row>

                              <Row className="os-gt-parent">
                                <Col xs={6} md={6} className="os-text">
                                  {t("cp-t-13")}
                                </Col>
                                <Col xs={6} md={6} className="os-numbers">
                                  {t("upp-t-4")}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Row>
          </div>
        )
      }

      {isOrderPlaced && (
        <div className="container">
          <Row>
            <Col md={12} className="text-center">
              <img src={OrderPlaced} alt="order-placed" />
            </Col>
            <Col md={12} className="text-center order-placed-heading mt-5 ">
              {t("cp-t-18")}
            </Col>
            <Col md={12} className="text-center order-placed-date ">
              {t("cp-t-19")}
              {moment(orderDetails.createdOnUtc).format("MMM DD, YYYY h:mm A")}
            </Col>
            <Col md={12} className="text-center order-placed-text mb-1">
              {t("cp-t-20")}
            </Col>
            <Col md={12} className="text-center order-placed-no">
              {t("cp-t-21")} {orderDetails.id}
            </Col>
            <Col md={3} className="text-center"></Col>
            <Col md={3} className="text-center">
              <Link
                to={`/user/orderdetails/${orderDetails.id}`}
                className="btn pd-btn "
              >
                {t("cp-t-22")}
              </Link>
            </Col>
            <Col md={3} className="text-center">
              <Link to="/" className="btn pd-btn pd-atc">
                {t("cp-t-23")}
              </Link>
            </Col>
            <Col md={3} className="text-center"></Col>
          </Row>
        </div>
      )}
    </section>
  );
}

export default UploadPrescription;

"use client";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import succes from "../../static/DeleteData/success.png";
import bulletTick from "../../static/DeleteData/Bullet.svg";
import { deleteDataService } from '../../services/dataDeleteService';
// import { updateLoginDrawerState } from '../../redux/webAppSlice';
import { Link, useNavigate } from 'react-router-dom';
import { applyforDelete } from '../../redux/userSlice';
import CountryCodeSelector from '../../components/AtomicComponents/CountryCodeSelector';


const bulletPoints = [
    {
        icon: bulletTick,
        title: "Personal Information",
        description: "Name, phone, email, date of birth, & gender"
    },
    {
        icon: bulletTick,
        title: "Appointment Data",
        description: "Appointment records and history"
    },
    {
        icon: bulletTick,
        title: "Health Metrics",
        description: "Vital signs like blood pressure, temperature, body weight etc.,"
    },
    {
        icon: bulletTick,
        title: "Prescription",
        description: "Records of recommendations and medications prescribed by your healthcare providers"
    },
    {
        icon: bulletTick,
        title: "Family Profiles",
        description: "Information about family members added to your account, including their personal details and health records."
    },
    {
        icon: bulletTick,
        title: "Usage Data",
        description: "Information about how you interact with our app, including your preferences and settings."
    }
];

const SubmitRequest = () => {

    const dispatch = useDispatch();
    const apple = useSelector((state) => state.user.appleForDelete);
    const userObj = useSelector((state) => state.user?.userObj);
    const defaultHealthCenter = useSelector((state) => state.clinic?.clincObj?.defaultHealthCenter);
    const [phoneNumber, setPhoneNumber] = useState({
        countryCode: userObj?.patientData?.appointmentCountryCode || "",
        number: "",
    });

    const navigation = useNavigate();

    
    useEffect(()=>{
        console.log(apple, "apple")
        if(!apple){
            navigation("/data-deletion")
        }
    },[apple])

    const [formData, setFormData] = useState({
        fullName: userObj?.patientData.name || "",
        email: userObj?.patientData.email || "",
        phoneNumber: userObj?.patientData.phoneNumber ? userObj?.patientData.phoneNumber : userObj?.patientData?.appointmentPhone || "",
        reason: "",
        termsAccepted: false
    });

    const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);


    

    const submitRequest = async () => {
        // if (!userObj.userData.id) {
        //     // dispatch(updateLoginDrawerState(true));
        //     return;
        // }

        if (!formData.termsAccepted) {
            alert('Please accept the terms to submit the request');
            return;
        }

        try {
            // const response = await deleteDataService(userObj.token);
            // console.log('Request Submitted', response);
            setIsDeleteSuccess(true);
            setFormData({
                fullName: "",
                email: "",
                phoneNumber: "",
                reason: "",
                checkboxes: {},
                termsAccepted: false
            });
        } catch (error) {
            console.error('Error submitting request:', error);
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <div className='container requestMainBox'>
            {isDeleteSuccess ?
                <div className="succesMain">
                    <div className="successMessage" >
                        <h1>Request submitted!</h1>
                        <div>
                            <img src={succes} alt='success img'/>
                        </div>
                        <h3>Your Request Has been Submitted Succesfully.</h3>
                        <p>We've received your request, our team will review and delete the data. Once the data is deleted you will receive a confirmation email. For further queries please email at{" "}
                            <a href={`mailto:${defaultHealthCenter?.email}`}>{defaultHealthCenter?.email}</a>
                        </p>
                        <div>
                            <Link to="/" className='btnr btnr-primary link'>Done</Link>
                        </div>
                    </div>
                </div>
                :
                <div className="submitRequestMain">
                    <div className="submitRequest">
                        <div className="left">
                            <div className='heading'>
                                <h2 className='title'>Your current preferences
                                </h2>
                                <p className='subTitle'>By requesting data deletion, all of the below information will be permanently removed from our platform after the 14 days soft deletion period, ensuring your privacy and compliance with relevant regulations.</p>
                            </div>
                            <div className='checkboxs'>

                                {bulletPoints.map((item, index) => (
                                    <div key={index} className="info-item">
                                        <img src={item.icon} alt="Checkmark icon"/>
                                        <div className='text'>
                                            <strong>{item.title}</strong>: {item.description}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="right">
                            <div className='submitArea'>
                                <div className='heading'>
                                    <h1 className='title'>Fill the form to submit your request</h1>
                                </div>
                                <div className='inputBox'>
                                    <label htmlFor="">Your Full name <span>*</span></label>
                                    <input
                                        className='input'
                                        type="text"
                                        name="fullName"
                                        placeholder='Enter Your Name'
                                        value={formData.fullName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className='inputBox'>
                                    <label htmlFor="">Email Address<span>*</span></label>
                                    <input
                                        className='input'
                                        type="text"
                                        name="email"
                                        placeholder='example@gmail.com'
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className='inputBox'>
                                    <label htmlFor="">Mobile No <span>*</span></label>
                                    <div className='input numberSelector'>
                                        <CountryCodeSelector defaultValue={process.env.REACT_APP_DEFAULT_COUNTRYCODE} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
                                        <input
                                            className='numInput'
                                            type="text"
                                            name="phoneNumber"
                                            placeholder='968652XXXX'
                                            value={formData.phoneNumber}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className='inputBox'>
                                    <label htmlFor="">Reason for deletion</label>
                                    <textarea className='input'
                                        name="reason"
                                        value={formData.reason}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='termsArea'>
                                    <div>
                                        <input
                                            className='checkBox'
                                            type="checkbox"
                                            name="termsAccepted"
                                            checked={formData.termsAccepted}
                                            placeholder='Enter The Reason For Deletion'
                                            onChange={(e) => setFormData(prevState => ({
                                                ...prevState,
                                                termsAccepted: e.target.checked
                                            }))}
                                        />
                                    </div>
                                    <div>
                                        <p>I accept to delete all the data I’ve selected here. I assure that I personally want’s to delete it.</p>
                                    </div>
                                </div>
                                <div className='submitButtons'>
                                    <button className='btnr btnr-primary' onClick={() => submitRequest()}>Submit</button>
                                    <button className='btnr btnr-primary-outline' onClick={()=> dispatch(applyforDelete(false))}>Cancle</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
        </div>
    );
};

export default SubmitRequest;
export const getAllBrands = async (  languageId, pageIndex,pageSize) =>{
    const pharmacyId = process.env.REACT_APP_PHARMACY_ID;
    const response = await fetch(`${process.env.REACT_APP_API}api/v1/GetAllManufacturer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json'
        },
        body: JSON.stringify( {pageIndex, pageSize, pharmacyId, languageId})
      });
      const jsonttt = await response.json();
    const data = jsonttt.response.data.manufacturer;

    return data
   
      
  }
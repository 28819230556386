import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import ProductGridItem from "../ProductGridItem";
import { getFeaturedProducts } from "../../services/ProductService";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { updateFeaturedProductWidget } from "../../redux/homePageSlice";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

function FeaturedProductWidget() {
  const items = useSelector((state) => state.homepage.featuredProductWidget);
  const dispatch = useDispatch();

  useEffect(() => {
    getFeaturedProducts(1, 10).then(
      (res) => {
        dispatch(updateFeaturedProductWidget(res.data.products));

      },
      (failure) => {
        console.error(failure); //expected output: Oopsy...
      }
    );
  }, [dispatch]);
  const { t } = useTranslation();

  // slick settings
  let dragging = false;
  const settings = {
    dots: false,
    infinite: items.length > 5 ? true : false,

    slidesToShow: 5,
    slidesToScroll: 1,

    beforeChange: () => (dragging = true),
    afterChange: () => (dragging = false),

    autoplay: true,
    speed: 550,
    autoplaySpeed: 950,
    responsive: [

      {
        breakpoint: 480,
        settings: {
          infinite: items.length > 2 ? true : false,
          centerPadding: "20px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const pharmacy = useSelector((state) => state.pharmacy.pharmacyDetails.pharmacy)

  const isStock = (product) => {
    if (!pharmacy.isInventoryLiteEnabled || !pharmacy.isPosEnabled) {
        return true;
    } else {
        return product.stockQuantity > 0;
    }
};

  return items.length > 0 ? (
    <section className="brand-sec-margin brand-sec-margin-noboder">
      <div className="container cat-cont">
        <Row className="pb-2">
          <Col md={6} xs={6} className="d-flex flex-column">
            <span className="home-head"> {t("gen-t-6")} <span> {t("gen-t-7")} </span></span>
          </Col>
          <Col md={6} xs={6} className="d-flex align-items-center justify-content-end">
            <Link to={`/featuredproducts`} className="big-Link">
              {t("gen-t-5")}
            </Link>
          </Col>
        </Row>
        <Row className="mobile-grid">
          {/* <Slider {...settings}> */}
          {items.map((product, index) => {
            return (
              index < 12 &&
              <Col lg={3} md={4} xs={6} key={product.id} className="even-spacer-y">
                <ProductGridItem
                  imgUrl={product.pictureUrl}
                  name={product.name}
                  brand={product.manufacturerName}
                  oldPrice={product.oldPrice}
                  price={product.price}
                  id={product.id}
                  enableWishlist={true}
                  type={product.drugType}
                  // outOfStock={isStock(product)}
                />
              </Col>
            );
          })}
          {/* </Slider> */}

        </Row>
      </div>
    </section>
  ) : (
    ""
  );
}

export default FeaturedProductWidget;

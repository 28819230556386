import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { hideModal } from "../../redux/searchModalSlice";
import FocusedOutlet from "./FocusedOutlet";
import FocusedSearch from "./FocusedSearch";


function FocusedComp(props) {
    const dispatch = useDispatch();
    const {type} = props

  return (
    <div className="focusedSearchParent">
      <div className="header-main">
        <div>
          <div className=" header-parent container">
            <div className="header-logo">
            </div>
            <div className={`header-input focusedSearch ${type ===1 && "focusedSearchL"} ${type ===2 && "focusedSearchR"}`}>
            {type === 1 && (<FocusedOutlet/>)}
            {type === 2 && (<FocusedSearch/>)}
            
            
            
            </div>
            <div className="header-buttons">
            </div>
            
          </div>
        </div>
      </div>
      <div className="focus-overlay d-none" onClick={()=>dispatch(hideModal(1))}>
      </div>
    </div>
  );
}

export default FocusedComp;

import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import logo from "../static/logos/logo.PNG";
import fb from "../static/facebook1.svg";
import tw from "../static/twiter1.svg";
import lin from "../static/linkedin1.svg";
import Apple from "../static/appleStore1.svg";
import Google from "../static/googleStore1.svg";
import Youtube from "../static/youtube1.svg";
import Insta from "../static/insta1.svg";

import en from "../static/icns/en.svg";
import es from "../static/icns/es.svg";
import ar from "../static/icns/ar.svg";
import fr from "../static/icns/fr.svg";
import ar_iq from "../static/icns/ar-iq.svg";

import ph from "../static/ph1.svg";
import ad from "../static/ad1.svg";
import em from "../static/em1.svg";
import doctor from "../static/doctor.svg";

import { Link } from "react-router-dom";
import { useState } from "react";
import { getLocalData, setLocalData } from "../utils/localStorage";
import { useSelector } from "react-redux";
import {
  getContactInformationRedux,
  updateRTLSettings,
} from "../redux/pharmacySlice";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import { covertTOWeek } from "../utils/weekdayFormatter";

import { getAllCategory } from "../services/CategoryService";

function Footer() {
  const { i18n, t } = useTranslation();

  const [lang, setlang] = useState("en");
  const [flag, setFlag] = useState(en);

  function changeLanguage(e) {
    i18n.changeLanguage(e);
    if (e === "ar" || e === "arIQ") {
      document.dir = "rtl";
      dispatch(updateRTLSettings(true));
      if (e === "ar") {
        setFlag(ar);
      } else if (e === "arIQ") {
        setFlag(ar_iq);
      }
    } else {
      document.dir = "ltr";
      dispatch(updateRTLSettings(false));
      if (e === "en") {
        setFlag(en);
      } else if (e === "es") {
        setFlag(es);
      } else if (e === "fr") {
        setFlag(fr);
      }
    }

    setlang(e);

    setLocalData("lang-id", e);
  }

  useEffect(() => {
    let c = getLocalData("lang-id");

    if (getLocalData("lang-id")?.length > 0) {
      changeLanguage(c);
    } else {
      changeLanguage("en");
    }
  }, []);

  const [loggedIn, setLoggedIn] = useState(false);

  //currency=
  const address = useSelector(
    (state) => state.pharmacy.contactInfo.data.address
  );
  const email = useSelector(
    (state) => state.pharmacy.contactInfo.data.supportEmail
  );
  const phoneNumber = useSelector(
    (state) => state.pharmacy.contactInfo.data.supportPhoneNumber
  );
  const facebook = useSelector(
    (state) => state.pharmacy.contactInfo.data.facebook
  );
  const twitter = useSelector(
    (state) => state.pharmacy.contactInfo.data.twitter
  );
  const instagram = useSelector(
    (state) => state.pharmacy.contactInfo.data.instagram
  );
  const youtube = useSelector(
    (state) => state.pharmacy.contactInfo.data.youtube
  );

  const pharmacyName = useSelector(
    (state) => state.pharmacy.pharmacyDetails.pharmacy.pharmacyName
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContactInformationRedux());
  }, []);

  useEffect(() => {
    if (getLocalData("user-info")?.userToken?.length > 0) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [getLocalData("user-info")?.userToken]);

  //language

  const supportedLanguages = useSelector(
    (state) => state.pharmacy.pharmacyDetails.pharmacy.supportedLanguages
  );

  const pharmacy = useSelector(
    (state) => state.pharmacy.pharmacyDetails.pharmacy
  );
  const defaultOutltet = useSelector((state) => state.pharmacy.defaultOutlet);

  const playStore = useSelector(
    (state) => state.pharmacy.contactInfo.data.playStore
  );
  const appStore = useSelector(
    (state) => state.pharmacy.contactInfo.data.appStore
  );

  const currentYear = new Date().getFullYear();

  const [allCategories, setAllCategories] = useState();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await getAllCategory(1, true);
        console.log(res);
        setAllCategories(res);
      } catch (failure) {
        console.error(failure);
      }
    };

    fetchCategories();
  }, []);

  return (
    <footer className="footer2">
      <div className="container img-footer-cont"></div>
      <div className="container">
        <div className="container-main2">
          <div className="sec F2-sec-1 aboutus">
            <div className="part1">
              <Link to="/">
                <img src={logo} className="log-main2" alt="footer" />
              </Link>

              <ul className="plinks2">
                {phoneNumber && (
                  <li>
                    <Link className="footer-text2" to={`tel:${phoneNumber}`}>
                      <img src={ph} alt="ph" className="reach-us-image" />
                      {i18n.language === "ar" || i18n.language === "arIQ"
                        ? phoneNumber
                          .replace(/\s/g, "-")
                          .replace("+", "")
                          .split("")
                          .join("") + "+"
                        : phoneNumber}
                    </Link>
                  </li>
                )}

                {email && (
                  <li>
                    <Link className="footer-text2" to={`mailto:${email}`}>
                      <img src={em} alt="em" className="reach-us-image" />
                      {email}
                    </Link>
                  </li>
                )}

                {address && (
                  <li>
                    <a className="footer-text2">
                      <img src={ad} alt="ad" className="reach-us-image" />
                      {address}
                    </a>
                  </li>
                )}

                {defaultOutltet && (
                  <li>
                    <a className="footer-text2">
                      {defaultOutltet.registrationNumber && (<>
                        <img src={ad} alt="ad" className="reach-us-image" style={{ visibility: "hidden" }} />
                        Permit Number -{" "} {defaultOutltet.registrationNumber}

                      </>
                      )}
                    </a>
                  </li>


                )}

              </ul>


              {pharmacy?.authorizeLicenseImageId > 0 && (
                <div className="sec F2-sec-1 aboutus">
                  {/* <h3 className="header-3-footer">{t("fc-t-11")}</h3> */}
                  <div className="certifications">
                    <img src={pharmacy.authorizeLicenseImageUrl} alt="" />
                    <div
                      className="discription"
                      dangerouslySetInnerHTML={{
                        __html: pharmacy.authorizeLicenseDescription,
                      }}
                    ></div>
                  </div>
                </div>
              )}
            </div>

            {defaultOutltet && (
              <>
                <div className="part2">
                  {true && (
                    <>
                      <div className="part2-block">
                        {defaultOutltet.is24x7 ? (<>
                          <h3 className="header-3-footer2">Opening Hours</h3>
                          <ul className="plinks2">
                            <li>
                              <a className="footer-text2">
                                Today: 24 Hours Open
                              </a>
                            </li>
                          </ul>
                        </>
                        ) : (<>
                          {(defaultOutltet.operatingStartTime && defaultOutltet.operatingStartTime) ? (<>
                            <h3 className="header-3-footer2">Opening Hours</h3>
                            <ul className="plinks2">
                              <li>
                                <a className="footer-text2">
                                  {/* {covertTOWeek(defaultOutltet)}{" "} */}
                                  Today:{" "}
                                  {defaultOutltet.operatingStartTime} -{" "}
                                  {defaultOutltet.operatingEndTime}
                                </a>
                              </li>
                            </ul>
                          </>) : (<></>)

                          }
                        </>)}
                      </div>
                    </>
                  )}
                  {(defaultOutltet.pharmacists?.name ||
                    defaultOutltet.pharmacists?.pin) && (
                      <><div className="part2-block">
                        <h3 className="header-3-footer2"><img src={doctor} alt="em" className="doctor-image" />Chief Pharmacist</h3>
                        <ul className="plinks2">
                          <li>
                            <a
                              className="footer-text2 doctors-name"

                              style={{ cursor: "default" }}
                            >
                              {defaultOutltet.pharmacists?.name && (
                                <>{defaultOutltet.pharmacists?.name}</>
                              )}
                              {defaultOutltet.pharmacists.pin && (
                                <>- PIN: {defaultOutltet.pharmacists.pin}</>
                              )}
                            </a>
                          </li>
                        </ul>
                      </div>
                      </>
                    )}
                </div>
              </>
            )}
          </div>
          <div className="sec F2-sec-2 aboutus  ">
            <h3 className="header-3-footer2">{t("hc-t-2")}</h3>
            {allCategories && (
              <ul className="qlinks2">
                {allCategories.map((category) => (
                  <li key={category.id}>
                    <Link
                      className="footer-text2"
                      to={`/products/category/${category.id}`}
                    >
                      {category.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="sec F2-sec-3 aboutus">
            <h3 className="header-3-footer2">{t("fc-t-5")}</h3>
            <ul className="qlinks2">
              <li>
                <Link className="footer-text2" to="/brands">
                  {t("hc-t-3")}
                </Link>
              </li>
              <li>
                <Link className="footer-text2" to="/categories">
                  {t("hc-t-2")}
                </Link>
              </li>
              <li>
                <Link className="footer-text2" to="/allofferrproducts">
                  {t("hc-t-4")}
                </Link>
              </li>
              <li>
                <Link className="footer-text2" to="/featuredproducts">
                  {t("hc-t-5")}
                </Link>
                {/* {loggedIn ? (
                <Link to="/user" className="footer-text2" href="/">
                  My Account
                </Link>
              ) : (
                <Link to="/login" className="footer-text2" href="/">
                  Login / Sign Up
                </Link>
              )} */}
              </li>
            </ul>
          </div>
          <div className="sec F2-sec-4 aboutus">
            <h3 className="header-3-footer2">{t("fc-t-6")}</h3>
            <ul className="qlinks2">
              <li>
                <Link to="/termsconditions" className="footer-text2" href="/">
                  {t("fc-t-7")}
                </Link>
              </li>
              <li>
                <Link to="/privacypolicy" className="footer-text2" href="/">
                  {t("fc-t-8")}
                </Link>
              </li>
              <li className="hover-drop">
                <a>
                  <span className="icon-globe globe">
                    <img src={flag} alt={lang} />
                  </span>

                  {lang === "en" && "ENG"}
                  {lang === "es" && "ESP"}
                  {lang === "ar" && "عربى"}
                  {lang === "arIQ" && "عربى"}
                  {lang === "fr" && "FRA"}
                </a>
                <ul className="sub-menu">
                  {supportedLanguages && supportedLanguages.length > 0 ? (
                    supportedLanguages.map((item, index) => {
                      if (item?.name === "English-US") {
                        return (
                          <li className="menu-item " key={index}>
                            <span onClick={() => changeLanguage("en")}>
                              <span className="globe">
                                <img src={en} alt="en" />
                              </span>
                              ENG
                            </span>
                          </li>
                        );
                      } else if (item?.name === "Spanish-ES") {
                        return (
                          <li className="menu-item" key={index}>
                            <span onClick={() => changeLanguage("es")}>
                              <span className="globe">
                                <img src={es} alt="es" />
                              </span>
                              ESP
                            </span>
                          </li>
                        );
                      } else if (item?.name === "Arabic-EG") {
                        return (
                          <li className="menu-item" key={index}>
                            <span onClick={() => changeLanguage("ar")}>
                              <span className="globe">
                                <img src={ar} alt="ar" />
                              </span>
                              عربى
                            </span>
                          </li>
                        );
                      } else if (item?.name === "French-BJ") {
                        return (
                          <li className="menu-item" key={index}>
                            <span onClick={() => changeLanguage("fr")}>
                              <span className="globe">
                                <img src={fr} alt="fr" />
                              </span>
                              FRA
                            </span>
                          </li>
                        );
                      } else if (item.name === "Arabic-IQ") {
                        return (
                          <li className="menu-item" key={index}>
                            <span onClick={() => changeLanguage("arIQ")}>
                              <span className="globe">
                                <img src={ar_iq} alt="fr" />
                              </span>
                              عربى
                            </span>
                          </li>
                        );
                      }
                    })
                  ) : (
                    <li className="menu-item">
                      <span onClick={() => changeLanguage("en")}>
                        <span className="globe">
                          <img src={en} alt="en" />
                        </span>
                        ENG
                      </span>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </div>

          <div className="sec F2-sec-5 aboutus">
            <h3 className="header-3-footer2">DOWNLOAD THE APP</h3>

            <div className="app-button-center1 ">
              {appStore && (
                <a
                  href={`${appStore}`}
                  target="_blank"
                  rel="noreferrer"
                  className="app-first-button"
                >
                  <img src={Apple} alt="apple" />
                  {/* <div className="app-buttons-inner">
                    <span className="top-text">{t("fc-t-1")}</span>
                    <span className="bottom-text">{t("fc-t-2")}</span>
                  </div> */}
                </a>
              )}
              {playStore && (
                <a href={`${playStore}`} target="_blank" rel="noreferrer">
                  <img src={Google} alt="google" />
                  {/* <div className="app-buttons-inner">
                    <span className="top-text">{t("fc-t-3")}</span>
                    <span className="bottom-text">{t("fc-t-4")}</span>
                  </div> */}
                </a>
              )}
            </div>

            <h3 className="header-3-footer2 mt-4">Follow Us</h3>
            <ul className="sociallinks2">
              {facebook && (
                <li>
                  <a href={facebook}>
                    <img src={fb} alt="footer" />
                  </a>
                </li>
              )}
              {instagram && (
                <li>
                  <a href={instagram}>
                    <img src={Insta} alt="footer" />
                  </a>
                </li>
              )}
              {twitter && (
                <li>
                  <a href={twitter}>
                    <img src={tw} alt="footer" />
                  </a>
                </li>
              )}

              {youtube && (
                <li>
                  <a href={youtube}>
                    <img src={Youtube} alt="footer" />
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div lg={12} className="copyright footer-text text-center">
          {/* © {pharmacyName && pharmacyName} {currentYear}. {t("fc-t-10")} Powered by <a href="DigitalPharmacy.io">DigitalPharmacy.io</a> */}
          Copyright © {currentYear}. Built with <span className="love">❤</span> by <a className="link" href="DigitalPharmacy.io">DigitalPharmacy.io</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

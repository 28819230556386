import React from "react";

function OutletHeader() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_349_11339)">
        <path
          d="M18.6667 20H10.6667V14.6667H9.33333V20H5.33333V14.6667H4V20C4 20.3536 4.14048 20.6928 4.39052 20.9428C4.64057 21.1929 4.97971 21.3333 5.33333 21.3333H18.6667C19.0203 21.3333 19.3594 21.1929 19.6095 20.9428C19.8595 20.6928 20 20.3536 20 20V14.6667H18.6667V20Z"
          fill="url(#svg-grad)"
        />
        <path
          d="M22.5268 8.84667L19.8068 3.40667C19.6965 3.18453 19.5263 2.99758 19.3155 2.86685C19.1047 2.73613 18.8616 2.6668 18.6135 2.66667H5.38684C5.13878 2.6668 4.89568 2.73613 4.68487 2.86685C4.47406 2.99758 4.30389 3.18453 4.19351 3.40667L1.47351 8.84667C1.38064 9.033 1.33269 9.23848 1.33351 9.44667V11.5C1.3329 11.8115 1.4414 12.1135 1.64017 12.3533C1.93395 12.6901 2.29677 12.9597 2.70399 13.1437C3.11122 13.3278 3.55329 13.422 4.00017 13.42C4.72935 13.4211 5.43629 13.169 6.00017 12.7067C6.56405 13.1693 7.27083 13.4221 8.00017 13.4221C8.72951 13.4221 9.4363 13.1693 10.0002 12.7067C10.564 13.1693 11.2708 13.4221 12.0002 13.4221C12.7295 13.4221 13.4363 13.1693 14.0002 12.7067C14.564 13.1693 15.2708 13.4221 16.0002 13.4221C16.7295 13.4221 17.4363 13.1693 18.0002 12.7067C18.6296 13.2235 19.4349 13.4761 20.2468 13.4115C21.0586 13.3468 21.8138 12.9699 22.3535 12.36C22.5547 12.121 22.6656 11.819 22.6668 11.5067V9.44667C22.6677 9.23848 22.6197 9.033 22.5268 8.84667ZM10.0002 9.6V10.6133L9.45351 11.3333C9.28569 11.5621 9.06637 11.7481 8.8133 11.8763C8.56023 12.0045 8.28053 12.0713 7.99684 12.0713C7.71315 12.0713 7.43345 12.0045 7.18038 11.8763C6.92731 11.7481 6.70799 11.5621 6.54017 11.3333L6.00017 10.5867V9.6L7.72684 4H10.6668L10.0002 9.6ZM18.0002 10.5867L17.4602 11.3333C17.2924 11.5621 17.073 11.7481 16.82 11.8763C16.5669 12.0045 16.2872 12.0713 16.0035 12.0713C15.7198 12.0713 15.4401 12.0045 15.187 11.8763C14.934 11.7481 14.7147 11.5621 14.5468 11.3333L14.0002 10.5867V9.6L13.3335 4H16.3002L18.0002 9.6V10.5867Z"
          fill="url(#svg-grad)"
        />
      </g>
      <defs>
        <linearGradient
        id="svg-grad"
          x1="4.97141"
          y1="20.5847"
          x2="9.375"
          y2="10.9158"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="start-color" />
          <stop offset="1" className="end-color" />
        </linearGradient>
        <linearGradient
        id="svg-grad"
          x1="2.62871"
          y1="12.2144"
          x2="10.5902"
          y2="-2.23285"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="start-color" />
          <stop offset="1" className="end-color" />
        </linearGradient>
        <clipPath id="clip0_349_11339">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default OutletHeader;

import axios from "../utils/axios";
export const getBanners = async () => {
 
  const promise = axios.post(`/GetBanners`, {
    pharmacyId: process.env.REACT_APP_PHARMACY_ID,
  });

  const dataPromise = promise.then((response) => response.data.response.data);

  return dataPromise;
};

import React from "react";

function Phone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
    >
      <path
        d="M21.47 17.33C21.47 17.69 21.39 18.06 21.22 18.42C21.05 18.78 20.83 19.12 20.54 19.44C20.05 19.98 19.51 20.37 18.9 20.62C18.3 20.87 17.65 21 16.95 21C15.93 21 14.84 20.76 13.69 20.27C12.54 19.78 11.39 19.12 10.25 18.29C9.1 17.45 8.01 16.52 6.97 15.49C5.94 14.45 5.01 13.36 4.18 12.22C3.36 11.08 2.7 9.94 2.22 8.81C1.74 7.67 1.5 6.58 1.5 5.54C1.5 4.86 1.62 4.21 1.86 3.61C2.1 3 2.48 2.44 3.01 1.94C3.65 1.31 4.35 1 5.09 1C5.37 1 5.65 1.06 5.9 1.18C6.16 1.3 6.39 1.48 6.57 1.74L8.89 5.01C9.07 5.26 9.2 5.49 9.29 5.71C9.38 5.92 9.43 6.13 9.43 6.32C9.43 6.56 9.36 6.8 9.22 7.03C9.09 7.26 8.9 7.5 8.66 7.74L7.9 8.53C7.79 8.64 7.74 8.77 7.74 8.93C7.74 9.01 7.75 9.08 7.77 9.16C7.8 9.24 7.83 9.3 7.85 9.36C8.03 9.69 8.34 10.12 8.78 10.64C9.23 11.16 9.71 11.69 10.23 12.22C10.77 12.75 11.29 13.24 11.82 13.69C12.34 14.13 12.77 14.43 13.11 14.61C13.16 14.63 13.22 14.66 13.29 14.69C13.37 14.72 13.45 14.73 13.54 14.73C13.71 14.73 13.84 14.67 13.95 14.56L14.71 13.81C14.96 13.56 15.2 13.37 15.43 13.25C15.66 13.11 15.89 13.04 16.14 13.04C16.33 13.04 16.53 13.08 16.75 13.17C16.97 13.26 17.2 13.39 17.45 13.56L20.76 15.91C21.02 16.09 21.2 16.3 21.31 16.55C21.41 16.8 21.47 17.05 21.47 17.33Z"
        stroke="url(#svg-grad)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <defs>
        <linearGradient
        id="svg-grad"
          x1="1.5"
          y1="21"
          x2="22.3465"
          y2="1.9597"
          gradientUnits="userSpaceOnUse"
        >
        <stop className="start-color" />
        <stop offset="1" className="end-color" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Phone;

import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { hideModal } from "../../redux/searchModalSlice";

import { searchProductsAPI } from "../../services/ProductService";
import SearchHeader from "../SvgComponents/SearchHeader";

import { Spinner } from "react-bootstrap";
import InfoBoxSearch from "./InfoBoxSearch";
import { useNavigate } from "react-router-dom";

import { debounce } from "lodash";

function FocusedSearch() {
  let [showInfo1, setShowInfo1] = useState(true);



 



  
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const timeout = useRef()

  useEffect(() => {
    if (!showInfo1) {
      dispatch(hideModal(1));
    }
  }, [showInfo1]);

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowInfo1(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);





   //select outlet

   const selectProduct = (productId) => { 
    navigate("/productDetails/"+productId);
    setShowInfo1(false);

   
  }


  const [display, setDisplay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

   
  

  const handleSearch = (e) => {
    const keyword = e.target.value.trim();
    if(keyword === ''){
      setDisplay(false);
      setIsLoading(false);
    }else{
      setIsLoading(true);

      searchProducts(keyword);
    }
    
  };

  const searchProducts = (keyword) => {
    clearTimeout(timeout.current)
    const products = [];
    if (keyword !== "") {
      timeout.current = setTimeout(() => {
        searchProductsAPI(keyword, 1, 20)
        .then(function (response) {
          let tempitemList = response;

          tempitemList.map((item) => {
            products.push({
              id: item.id,
              pictureUrl: item.pictureUrl,
              name: item.name,
            });
          });

          if (products.length > 0) {
            setDisplay(true);
          } else {
            setDisplay(true);
          }
          setIsLoading(false);
          setOptions(products);
        })
        .catch(function (error) {
          console.log(error);
        });
      }, 600)

     
    } else if (keyword === "") {
      setOptions([]);
      setDisplay(false);
    }
  };

  return (
    <div ref={ref} className="foc-wrapper">
      <div className="focused-input">
        <div className="INsearch-product">
          <div className="out-btn">
            <SearchHeader />
            
            <input placeholder="Search" autoFocus onChange={handleSearch} />

            <div className="searchIcon">
            {isLoading && (
              <a href="#">
                <Spinner
                  variant="info"
                  className=""
                  size="sm"
                  animation="border"
                  role="status"
                >
                
                </Spinner>
              </a>
            )}
          </div>
          </div>
          
        </div>
      </div>
      <InfoBoxSearch show={showInfo1} selectProduct={selectProduct} options={options} message="Click outside to close this" />
    </div>
  );
}

export default FocusedSearch;

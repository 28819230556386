import React from "react";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PhoneInput from "react-phone-input-2";
import TextField from "@mui/material/TextField";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import VerifiedIcon from "@mui/icons-material/Verified";
import OtpInput from "react-otp-input";

import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import { authentication } from "../../utils/firebase";
import { Divider } from "@mui/material";
import { newRegistration } from "../../services/UserService";
import { setLocalData } from "../../utils/localStorage";
import { updateUser } from "../../redux/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function CreateNewUser(props) {
  const { newEmail } = props;

  const [email, setEmail] = useState(newEmail);
  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("");
  const [phoneNumber, setPhoneNumber] = useState({
    countryCode: "91",
    number: "",
  });

  const [changePassword, setChangePassword] = useState({
    newPassword: "",
    showNewPassword: false,
  });
  const [isChangingPassword, setIsChangingPassword] = useState("");
  let isPhoneNoVerified = false;
  const [verifyPhoneNumber, setVerifyPhoneNumber] = useState(false);

  const [newPasswordValidator, setNewPasswordValidator] = useState({
    eightChars: false,
    specialChar: false,
    numChars: false,
    upperCaseChars: false,
  });

  const changePasswordVAlidator = (e) => {
    setChangePassword({ ...changePassword, newPassword: e.target.value });
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    var hasNumber = /\d/;
    var hasUpperCase = /[A-Z]/;

    let pass = e.target.value;
    setNewPasswordValidator({
      eightChars: pass.length >= 8 ? true : false,
      specialChar: specialChars.test(pass),
      numChars: hasNumber.test(pass),
      upperCaseChars: hasUpperCase.test(pass),
    });
  };


  const dispatch = useDispatch();

  const navigate = useNavigate();

  const createNewPassword = () => {
    setIsChangingPassword(true);

    let tempObjj = null;
    if (
      newPasswordValidator.eightChars &&
      newPasswordValidator.specialChar &&
      newPasswordValidator.numChars &&
      newPasswordValidator.upperCaseChars
    ) {
      if (phoneNumber.number.length > 0 && !isPhoneNoVerified) {
        getOtp();
      } else if (phoneNumber.number.length > 0 && isPhoneNoVerified) {
       
        tempObjj = {
          name: fullName,
          email: email,
          countryCode: "+" + phoneNumber.countryCode,
          phoneNumber: phoneNumber.number,
          isPhoneVerified: true,
          loginType: "email",
          passWord: changePassword.newPassword,
        };

        newRegistration(tempObjj)
          .then((res) => {

            if (res.data.status === "Success") {
              let userInfo = {
                userToken: res.data.response.token,
                userData: res.data.response.patientData,
              };
              navigate("/user");
              setLocalData("user-info", userInfo);
              dispatch(updateUser(res.patientData));
              
            } else {
              swal({
                icon: "error",
                title: "there are some errors",
                text: res.data.response.message

              });
            }

            setIsChangingPassword(false);
          })
          .catch((err) => {
            setIsChangingPassword(false);
            console.log(err)
            swal({
              icon: "error",
              text: "there are some errors",
            });
          });


      } else if (phoneNumber.number.length === 0) {
        
        tempObjj = {
          name: fullName,
          email: email,
          loginType: "email",
          passWord: changePassword.newPassword,
        };

        newRegistration(tempObjj)
          .then((res) => {
            if (res.data.status === "Success") {
              let userInfo = {
                userToken: res.data.response.token,
                userData: res.data.response.patientData,
              };
              navigate("/user");
              setLocalData("user-info", userInfo);
              dispatch(updateUser(res.patientData));
              
            } else {
              swal({
                icon: "error",
                text: "there are some errors",
              });
            }

            setIsChangingPassword(false);
          })
          .catch((err) => {
            setIsChangingPassword(false);
            swal({
              icon: "error",
              text: "there are some errors",
            });
          });
      }
    } else {
      setIsChangingPassword(false);
      swal({
        icon: "error",
        text: "there are validation errors",
      });
    }
  };

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recapta-verify",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      },
      authentication
    );
  };

  const getOtp = () => {
    
    if (!window.recaptchaVerifier) {
      generateRecaptcha();
    }
    
    

    let appVerifier = window.recaptchaVerifier;
    let finalPhone = "+" + phoneNumber.countryCode + phoneNumber.number;

    signInWithPhoneNumber(authentication, finalPhone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        
        window.confirmationResult = confirmationResult;
        setVerifyPhoneNumber(true);

        // ...
      })
      .catch((error) => {
        console.log(error);
        window.recaptchaVerifier.render().then(function (widgetId) {
          window.recaptchaVerifier.recaptcha.reset(widgetId);
        });
      });
  };

  const [otp, setOtp] = useState("");

  const [verifyOptLoading, setVerifyOptLoading] = useState(false);

  const handleChange = (otpN) => {
    setOtp(otpN);
  };

  const verifyOtp = () => {
    setVerifyOptLoading(true);
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;

      confirmationResult
        .confirm(otp)
        .then((result) => {
         
          if (result._tokenResponse.idToken.length > 0) {
            setVerifyPhoneNumber(false);
            isPhoneNoVerified = true;
            createNewPassword();
          } else {
            swal({
              icon: "error",
              text: "Error Validating Phone Number",
            });
            setIsChangingPassword(false);
           
          }
          setVerifyOptLoading(false);
          setOtp("")
        })
        .catch((error) => {
          setVerifyPhoneNumber(false);
          setIsChangingPassword(false);
          setVerifyOptLoading(false);
         
          swal({
            icon: "error",
            text: "Error Validating Phone Number",
          });
        });
    }
  };

  return (
    <>
      <div className="text-center">
        <div id="recapta-verify"></div>
      </div>
      {!verifyPhoneNumber && (
        <Row className="pb-2">
          <Col md={1}></Col>
          <Col md={10}>
            <Row>
              <Col md={12}>
                <span className="header-1-login">Complete Sign Up</span>
              </Col>
              <Col md={5} xs={12}>
                <span className="csu-subheading">Create your new password</span>
                <div>
                  <FormControl className="password-block" variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">
                      Create Password
                    </InputLabel>
                    <Input
                      id="standard-adornment-password"
                      type={
                        changePassword.showNewPassword ? "text" : "password"
                      }
                      value={changePassword.newPassword}
                      onChange={(e) => changePasswordVAlidator(e)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setChangePassword({
                                ...changePassword,
                                showNewPassword:
                                  !changePassword.showNewPassword,
                              })
                            }
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {changePassword.showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <div className="validator-grp">
                    <span
                      className={`from-cust-blocks ${
                        newPasswordValidator.eightChars ? "active" : ""
                      }`}
                    >
                      8 Chanracters
                    </span>
                    <span
                      className={`from-cust-blocks ${
                        newPasswordValidator.specialChar ? "active" : ""
                      }`}
                    >
                      1 Special
                    </span>
                    <span
                      className={`from-cust-blocks ${
                        newPasswordValidator.numChars ? "active" : ""
                      }`}
                    >
                      1 Numeric
                    </span>
                    <span
                      className={`from-cust-blocks ${
                        newPasswordValidator.upperCaseChars ? "active" : ""
                      }`}
                    >
                      1 Uppercase
                    </span>
                  </div>
                  <Button
                    disabled={isChangingPassword ? "disabled" : ""}
                    className="btn pd-btn pd-atc mt-3 create-user-mobile-upper"
                    onClick={() => createNewPassword()}
                  >
                    {isChangingPassword && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    <span className="visually-hidden">Loading...</span>
                    CREATE PASSWORD
                  </Button>
                </div>
              </Col>
              <Col md={7} xs={12}>
                <div className="csu-optional">
                  <div>
                    <span className="csu-subheading">
                      Share Account Details <span>(Optional)</span>
                    </span>
                  </div>
                  <div>
                    <span className="csu-text">
                      You can also share these details later{" "}
                    </span>
                  </div>
                  <div>
                    <Row className="mt-4">
                      <Col md={12}>
                        <Row className="phone-no-grp">
                          <Col xs={3} md={3}>
                            <PhoneInput
                              inputProps={{
                                disabled: true,
                              }}
                              inputClass="phone-code"
                              buttonClass="phone-code-dropDown"
                              country={"us"}
                              value={phoneNumber.countryCode}
                              onChange={(phone) =>
                                setPhoneNumber({
                                  ...phoneNumber,
                                  countryCode: phone,
                                })
                              }
                            />
                          </Col>
                          <Col xs={9} md={9}>
                            <input
                              placeholder="Enter Phone Number"
                              className="phoneNumber-login"
                              value={phoneNumber.number}
                              type="number"
                              onChange={(e) =>
                                setPhoneNumber({
                                  ...phoneNumber,
                                  number: e.target.value,
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col md={12}>
                        <TextField
                          label="Full Name"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                          variant="standard"
                        />
                      </Col>

                      <Col md={12} className="mb-2 mt-2">
                        <FormControl fullWidth variant="standard">
                          <InputLabel htmlFor="input-with-icon-adornment">
                            Email
                          </InputLabel>
                          <Input
                            id="input-with-icon-adornment"
                            disabled
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            endAdornment={
                              <InputAdornment position="end">
                                <VerifiedIcon color="success" />
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Col>
                      <Col md={12}>
                        <FormControl fullWidth variant="standard">
                          <InputLabel id="demo-simple-select-label">
                            Gender
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={gender}
                            label="Age"
                            onChange={(e) => setGender(e.target.value)}
                          >
                            <MenuItem value={"male"}>Male</MenuItem>
                            <MenuItem value={"female"}>Female</MenuItem>
                            <MenuItem value={"other"}>Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col xs={12} className="d-lg-none d-md-none">
              <Button
                    disabled={isChangingPassword ? "disabled" : ""}
                    className="btn pd-btn pd-atc mt-3 create-user-mobile-lower"
                    onClick={() => createNewPassword()}
                  >
                    {isChangingPassword && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    <span className="visually-hidden">Loading...</span>
                    CHANGE PASSWORD
                  </Button>
              </Col>
            </Row>
          </Col>
          <Col md={1}></Col>
        </Row>
      )}

      {verifyPhoneNumber && (
        <Row>
          <Col md={3}></Col>
          <Col md={6}>
            <Row>
              <Col md={12} className="verify-1">
                {" "}
                Enter OTP
              </Col>
              <Col md={12} className="verify-2">
                {" "}
                We've sent a code to your number
              </Col>
              <Col md={12} className="verify-3">
                {" "}
                +{phoneNumber.countryCode} {phoneNumber.number}
              </Col>
              <Col md={12} className="text-center otp-boxes mb-4">
                <OtpInput value={otp} onChange={handleChange} numInputs={6} />
              </Col>

              <Col md={12} className="text-center">
                <Button onClick={verifyOtp} className="btn pd-btn pd-atc mt-4">
                  {verifyOptLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Verify OTP
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md={3}></Col>
        </Row>
      )}
    </>
  );
}

export default CreateNewUser;

import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Form, FormCheck, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function PaymentMethodComp(props) {
  const { setCreateOrderItems, createOrderItems } = props;

  const { t } = useTranslation();
  const avaiablePaymentMethods1 = useSelector(
    (state) => state.pharmacy.payemntMethods
  );
  console.log(avaiablePaymentMethods1);
  const avaiablePaymentMethods = useSelector(
    (state) => state.pharmacy.payemntMethods
  )?.map((paymentMethod, index) => {
    let tempPM = {
      ...paymentMethod,
      isChecked: index === 0 ? true : false,
    };

    return tempPM;
  });

  const [paymentMethods, setPaymentMethods] = useState(avaiablePaymentMethods);

  const handlePMSChange = (pms) => {
    console.log(pms);
    let tempArrPms = paymentMethods.map((inpms) => {
      let tempPM = {
        ...inpms,
        isChecked: false,
      };
      if (inpms.systemName === pms.systemName) {
        tempPM.isChecked = true;
      }

      return tempPM;
    });
    console.log(pms.systemName);
    setCreateOrderItems({
      ...createOrderItems,
      PaymentMethodSystemName: pms.systemName,
      paymentToken: "",
    });

    setPaymentMethods(tempArrPms);
  };

  return (
    <Row>
      <Col md={12}>
        <Row>
          <Col className="os-header ">{t("cp-t-6")}</Col>
        </Row>
      </Col>
      <Col md={12}>
        {paymentMethods?.length > 0 && (
          <Form>
            {paymentMethods.map((pms) => (
              <FormCheck
                className="mb-3 form-check-radio"
                key={pms.systemName}
                type="radio"
                id={`inline-PMSradio-${pms.systemName}`}
              >
                <Form.Check.Input
                  name="group1"
                  type="radio"
                  value={pms.systemName}
                  checked={pms.isChecked}
                  onChange={() => handlePMSChange(pms)}
                />
                <Form.Check.Label className="disc-label">
                  <Row className="disc-row">
                    <Col md={12} className="disc-address-opt">
                      <div>
                        <img
                          src={pms.iconUrl}
                          className="pe-2"
                          alt={pms.displayName}
                          style={{ width: "32px" }}
                        />
                        {pms.displayName}
                      </div>
                    </Col>
                  </Row>
                </Form.Check.Label>
              </FormCheck>
            ))}
          </Form>
        )}
      </Col>
    </Row>
  );
}

export default PaymentMethodComp;

import React from 'react';
import img1 from '../../static/DeleteData/1.png';
import img2 from '../../static/DeleteData/2.png';
import img3 from '../../static/DeleteData/3.png';
import img4 from '../../static/DeleteData/4.png';
import { Five, Four, One, Three, Two } from '../../components/Icons/Numbers';
import { StepLine, StepLineRevurs } from '../../components/Icons/StepLine';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { updateLoginDrawerState } from '../../redux/webAppSlice';
import DatabaseIcon from '../../components/Icons/Database';
import { applyforDelete } from '../../redux/userSlice';

const DataDeletion = () => {

    const steps = [
        {
            id: "00001",
            icon: <One />,
            title: "Log In to your account",
            subTitle: "Click on \"Delete My Data\" and log in to verify your identity. This step ensures that only authorized users can request data deletion.",
        },
        {
            id: "00002",
            icon: <Two />,
            title: "Fill Out the Request Form",
            subTitle: "Complete the data deletion request form with the required information, including your name, email, user ID, and reason for the request. Providing accurate information helps us process your request efficiently.",
        },
        {
            id: "00003",
            icon: <Three />,
            title: "Soft deletion",
            subTitle: "Once your request is verified, your data will be soft deleted for 14 days. During this period, your data will not be accessible but will be stored securely in case you change your mind.",
        },
        {
            id: "00004",
            icon: <Four />,
            title: "Permanent Deletion",
            subTitle: "If you do not log in during the 14-day soft deletion period, your data will be permanently deleted from our database. You will receive a confirmation email once the permanent deletion is complete.",
        },
        {
            id: "00005",
            icon: <Five />,
            title: "Confirmation email",
            subTitle: "Once the data is deleted permanently, we will send an email with a confirmation.",
        },
    ]

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const apple = useSelector((state) => state.user.appleForDelete);

    const userObj = useSelector((state) => state.user);

    const handleClick = () => {
        if (!userObj.userData.id) {
            console.log(userObj.userData.id, "login")
            navigate('/login', { state: { from: location } });
        } else {
            dispatch(applyforDelete(true));
            console.log(apple, "apple")
            navigate('/data-deletion/submit-request');
        }
    };

    return (
        <div className='container requestMainBox'>
            <div className="dataDeletionMain">
                <div className="DeletionContent">
                    <div className='sec_1'>
                        <div className='info'>
                            <h1>Trusted by Patients and Healthcare Providers</h1>
                            <p>We understand that you trust us with sensitive information, and we are committed to maintaining the highest standards of security and confidentiality.</p>
                            <p>If you wish to have your personal data deleted from our platform, please follow the instructions below. However, we would like to reassure you that your data is secure and used solely to enhance your healthcare experience. </p>
                        </div>
                        <div className='image'>
                            <img src={img1} alt="" />
                        </div>
                    </div>
                    <div className='sec_2'>
                        <div className='image'>
                            <img src={img2} alt='' />
                        </div>
                        <div className='info'>
                            <h1>Contect Us</h1>
                            <p>We comply with GDPR, HIPAA, and DISHA regulations to ensure that your data is handled with the utmost care and respect. If you have any questions, concerns, or need assistance with our services, please don't hesitate to reach out. Our dedicated support team is here to help you with any inquiries you may have. We value your feedback and are committed to providing you with the best possible care.</p>

                            <div className='contectMain'>
                                <div className='contectItem'>
                                    <h3>Visit Us</h3>
                                    <div className='conInfo'>
                                        <span>2972 Westheimer Rd. Santa Ana, Illinois 85486 </span>
                                    </div>
                                </div>
                                <div className='contectItem'>
                                    <h3>Contect Us</h3>
                                    <div className='conInfo'>
                                        <span>contact@company.com</span>
                                        <span>(406) 555-0120</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sec_3'>
                        <div className='heading'>
                            <h1>How to Request Data Deletion?</h1>
                            <p>Follow the steps below if you wish to request the deletion of your personal data from our platform. We ensure that the process is secure and straightforward to protect your privacy.</p>
                        </div>
                        <div className='stepsMain'>
                            {steps.map((item, index) => (
                                <div key={index} className={`step ${index % 2 === 0 ? 'left' : ''}`}>
                                    <div className="subStep">
                                        <div className="icon">
                                            {item.icon}
                                        </div>
                                        <div className="info">
                                            <h3>{item.title}</h3>
                                            <p>{item.subTitle}</p>
                                        </div>
                                    </div>
                                    <div className='line'>
                                        {index % 2 === 0 ?
                                            <StepLine />
                                            :
                                            <StepLineRevurs />
                                        }
                                    </div>
                                </div>
                            ))}
                            <div></div>
                        </div>
                    </div>

                    <div className="requestArea">
                        <div className='info'>
                            <h1>Are you sure?</h1>
                            <p>Before you proceed, please consider that deleting your data means losing access to all your health records and personalized care. If you still wish to continue, please confirm below.</p>
                            <button className="button" onClick={handleClick}><DatabaseIcon /> Request Data Deletion</button>
                        </div>
                        <img className='image' src={img4} alt='' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataDeletion;
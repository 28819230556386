import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

//Components
import BrandWidget from "../components/Home/BrandWidget";
import CategoryWidget from "../components/Home/CategoryWidget";
import FeaturedProductWidget from "../components/Home/FeaturedProductWidget";
import PopularTodayWidget from "../components/Home/PopularTodayWidget";
import HomePageBanner from "../components/HomePageBanner";

//images
import phoneSS from "../static/logos/screenshot.png";

import Apple from "../static/appleStore.svg";
import Google from "../static/googleStore.svg";

import AppleIcon from "../components/SvgComponents/AppleIcon";
import PlayIcon from "../components/SvgComponents/PlayIcon";

import Up from "../static/up.svg";
import Up1 from "../static/prescription/ub-1.png";
import Up2 from "../static/prescription/ub-2.png";
import Up3 from "../static/prescription/upload.png";

import OfferProductWidget from "../components/Home/OfferProductWidget";
import { getLocalData } from "../utils/localStorage";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Uback1 from "../components/SvgComponents/prescription/Uback1";
import Uback2 from "../components/SvgComponents/prescription/Uback2";
import Uback3 from "../components/SvgComponents/prescription/Uback3";
import Uback4 from "../components/SvgComponents/prescription/Uback4";
import SmallBanner from "../components/SmallBanner";

function Home() {
  const { t } = useTranslation();

  const location = useLocation();

  const isPrescriptionOrderEnabled = useSelector(
    (state) =>
      state.pharmacy.pharmacyDetails.pharmacy.isPrescriptionOrderEnabled
  );

  const playStore = useSelector(
    (state) => state.pharmacy.contactInfo.data.playStore
  );
  const appStore = useSelector(
    (state) => state.pharmacy.contactInfo.data.appStore
  );

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ top: 700, left: 0, behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <div className="d-none d-md-block cust-padding-for-head"></div>
      <HomePageBanner />
      {isPrescriptionOrderEnabled && (
        <section className="upload-pres-sec">
          <div className="container upload-pres">
            <div className="upload-back-1 ">
              <img src={Up1} alt="sdsd" />
            </div>
            <div className="upload-back-2 ">
              <img src={Up2} alt="sdsd" />
            </div>
            <Row className="uppload-prec-container">
              <Col md={12}>
                <Col md={12}>
                  <h4 className="text-center"> {t("ups-t-1")} </h4>
                </Col>
                <Col md={12}>
                  <p className="text-center">{t("ups-t-2")}</p>
                </Col>
                <Row className="up-h-incs">
                  <Col md={3} className="col-6">
                    <Row className="up-single-item">
                      <Col md={4} className="up-mini-icn-parent col-3">
                        <div className="up-mini-icn">
                          <Uback1 />
                        </div>
                      </Col>
                      <Col md={8} className="up-mini-text col-9">
                        <p>{t("ups-t-4")}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={3} className="col-6">
                    <Row className="up-single-item">
                      <Col md={4} className="up-mini-icn-parent col-3">
                        <div className="up-mini-icn">
                          <Uback2 />
                        </div>
                      </Col>
                      <Col md={8} className="up-mini-text col-9">
                        <p>{t("ups-t-5")}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={3} className="col-6">
                    <Row className="up-single-item">
                      <Col md={4} className="up-mini-icn-parent col-3">
                        <div className="up-mini-icn">
                          <Uback3 />
                        </div>
                      </Col>
                      <Col md={8} className="up-mini-text col-9">
                        <p>{t("ups-t-6")}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={3} className="col-6">
                    <Row className="up-single-item">
                      <Col md={4} className="up-mini-icn-parent col-3">
                        <div className="up-mini-icn">
                          <Uback4 />
                        </div>
                      </Col>
                      <Col md={8} className="up-mini-text col-9">
                        <p>{t("ups-t-7")}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Col md={12} className="uploadpres-home-btn">
                  <Link
                    className="btn btn-info address-form-button "
                    to={
                      getLocalData("user-info")?.userToken?.length > 0
                        ? "/uploadprescription"
                        : "/login"
                    }
                  >
                    <img src={Up3} alt="dfs" />
                    {t("ups-t-3")}
                  </Link>
                </Col>
              </Col>
            </Row>
            <div className="upload-back text-center d-md-none d-block img-mobile-image">
              <img src={Up} alt="sdsd" />
            </div>
          </div>
        </section>
      )}

      <CategoryWidget />
      <FeaturedProductWidget />
      <SmallBanner />
      <BrandWidget />
      {(appStore || playStore) && (
        <section
          id="download-apps"
          className="download-showcase d-lg-block d-none"
        >
          <div className="container">
            <Row className="row-app-section"></Row>
            <Row className="row-app-section2">
              <img
                className="screenshot-home-app"
                alt="sceenshot"
                src={phoneSS}
              />

              <Col md={9} className="text-center">
                <span className="header-app-section">{t("gas-t-1")}</span>
              </Col>
              <Col md={9} className="text-center">
                <span className="text-app-section">{t("gas-t-2")} </span>
                <br />
              </Col>
              <Col md={9} className="app-button-center">
                {appStore && (
                  <a
                    href={`${appStore}`}
                    target="_blank"
                    className="app-btton align-items-center d-flex"
                    rel="noreferrer"
                  >
                    <AppleIcon />
                    <div className="app-buttons-inner">
                      <span className="top-text">{t("fc-t-1")}</span>
                      <span className="bottom-text">{t("fc-t-2")}</span>
                    </div>
                  </a>
                )}
                {playStore && (
                  <a
                    href={`${playStore}`}
                    target="_blank"
                    className="app-btton align-items-center d-flex"
                    rel="noreferrer"
                  >
                    <PlayIcon />
                    <div className="app-buttons-inner">
                      <span className="top-text">{t("fc-t-3")}</span>
                      <span className="bottom-text">{t("fc-t-4")}</span>
                    </div>
                  </a>
                )}
              </Col>
              {appStore && playStore && (
                <Col md={9} className="text-center">
                  <span className="text-app-section-bottom">
                    {t("gas-t-3")}{" "}
                  </span>
                </Col>
              )}
            </Row>
          </div>
        </section>
      )}

      {/* <section id="download-apps" className='download-showcase d-lg-block d-none'>

        <div className='container'>
          <Row className='row-app-section'>
          </Row>
          <Row className='row-app-section2'>
            <img className='screenshot-home-app' alt="sceenshot" src={phoneSS} />

            <Col md={9} className='text-center'>
              <span className='header-app-section'>{t("gas-t-1")}</span>
            </Col>
            <Col md={9} className='text-center'>
              <span className='text-app-section'>{t("gas-t-2")} </span><br />

            </Col>
            <Col md={9} className='app-button-center'>
              {
                appStore && (
                  <a href={`${appStore}`} target="_blank" className='app-btton align-items-center d-flex'>

                    <AppleIcon />
                    <div className='app-buttons-inner'>
                      <span className='top-text'>{t("fc-t-1")}</span>
                      <span className='bottom-text'>{t("fc-t-2")}</span>
                    </div>

                  </a>
                )
              }{
                playStore !== '#' && (
                  <a href={`${playStore}`} target="_blank" className='app-btton align-items-center d-flex'>

                    <PlayIcon />
                    <div className='app-buttons-inner'>
                      <span className='top-text'>{t("fc-t-3")}</span>
                      <span className='bottom-text'>{t("fc-t-4")}</span>
                    </div>
                  </a>
                )
              }


            </Col>
            {
              (appStore || playStore) && (
                <Col md={9} className='text-center'>
                  <span className='text-app-section-bottom'>{t("gas-t-3")} </span>
                </Col>
              )
            }





          </Row>
        </div>
      </section> */}

      <OfferProductWidget />
      <PopularTodayWidget />
      <HomePageBanner />
    </>
  );
}

export default Home;

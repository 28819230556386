import React from 'react'

function Pp() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="44" viewBox="0 0 36 44" fill="none">
    <path d="M2.38 6.94C0.94 7.58 0 9.02 0 10.6V20C0 31.1 7.68 41.48 18 44C28.32 41.48 36 31.1 36 20V10.6C36 9.02 35.06 7.58 33.62 6.94L19.62 0.72C18.58 0.26 17.4 0.26 16.38 0.72L2.38 6.94ZM18 12C19.1 12 20 12.9 20 14C20 15.1 19.1 16 18 16C16.9 16 16 15.1 16 14C16 12.9 16.9 12 18 12ZM18 20C19.1 20 20 20.9 20 22V30C20 31.1 19.1 32 18 32C16.9 32 16 31.1 16 30V22C16 20.9 16.9 20 18 20Z" fill="url(#svg-grad)"/>
    <defs>
        <linearGradient
          id="svg-grad"
          x1="0.584961"
          y1="15.5625"
          x2="15.885"
          y2="1.56197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0854" className="start-color" />
          <stop offset="0.922" className="end-color" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Pp
import React from "react";
import Col from "react-bootstrap/Col";
import NoImage from "../static/noimage.svg";
import { Link } from "react-router-dom";

function BrandsInner(props) {
  const { item, dragging } = props;
  return (
    <Link to={`/products/subcategory/${item.id}`} className="gci gci-sub"  onClick={(e) => dragging && e.preventDefault()}>
      <div className="upperContent">
        <div>
          <img
            className="img-fluid-2"
            alt={item.name}
            src={item.imageURL === "" ? NoImage : item.imageURL}
          />
        </div>
      </div>
      <div className="lowerContent">
        <span className="item-name">{item.name}</span>
      </div>
    </Link>
  );
}

export default BrandsInner;

import axios from "axios";

const pharmacyId = process.env.REACT_APP_PHARMACY_ID;

export const getTopic = async (systemName) => {
  

  const promise = axios.post(`${process.env.REACT_APP_API}api/v1/Topic/GetDetails`, {
    pharmacyId,
    systemName
  });

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function FunctionalityEnableDisable({ children }) {
  const isPrescriptionOrderEnabled = useSelector(
    (state) => state.pharmacy.pharmacyDetails.pharmacy.isPrescriptionOrderEnabled
  );

  return isPrescriptionOrderEnabled  ? children : <Navigate to="/" />;
}
export default FunctionalityEnableDisable;
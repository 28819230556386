import React from 'react'

function Cart() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="42" viewBox="0 0 40 42" fill="none">
<path d="M38.6234 12.8812C38.3454 12.6012 38.0145 12.3791 37.65 12.2278C37.2855 12.0766 36.8946 11.9991 36.5 12H30.5V10.5C30.5 7.71523 29.3938 5.04451 27.4246 3.07538C25.4555 1.10625 22.7848 0 20 0C17.2152 0 14.5445 1.10625 12.5754 3.07538C10.6062 5.04451 9.5 7.71523 9.5 10.5V12H3.5C2.70435 12 1.94129 12.3161 1.37868 12.8787C0.81607 13.4413 0.5 14.2044 0.5 15V35.25C0.5 38.9062 3.59375 42 7.25 42H32.75C34.5186 42.0006 36.2172 41.3091 37.4825 40.0734C38.1193 39.466 38.6263 38.7357 38.9731 37.9269C39.3198 37.118 39.4991 36.2472 39.5 35.3672V15C39.5012 14.6064 39.4244 14.2165 39.2739 13.8528C39.1235 13.4892 38.9024 13.1589 38.6234 12.8812ZM27.1709 22.6875L18.7709 33.1875C18.633 33.3599 18.4587 33.4996 18.2605 33.5969C18.0623 33.6941 17.8451 33.7464 17.6244 33.75H17.6C17.3833 33.75 17.1692 33.7031 16.9725 33.6125C16.7757 33.5218 16.6008 33.3896 16.46 33.225L12.86 29.0175C12.732 28.8678 12.6347 28.6943 12.5737 28.507C12.5127 28.3197 12.4891 28.1222 12.5045 27.9258C12.5198 27.7294 12.5736 27.538 12.663 27.3624C12.7523 27.1868 12.8753 27.0305 13.025 26.9025C13.1747 26.7745 13.3482 26.6772 13.5355 26.6162C13.7228 26.5552 13.9203 26.5316 14.1167 26.547C14.3131 26.5623 14.5045 26.6161 14.6801 26.7055C14.8557 26.7948 15.012 26.9178 15.14 27.0675L17.5625 29.8978L24.8291 20.8125C25.0777 20.5017 25.4396 20.3024 25.8352 20.2584C26.2308 20.2145 26.6276 20.3295 26.9384 20.5781C27.2492 20.8268 27.4485 21.1887 27.4925 21.5843C27.5364 21.9799 27.4215 22.3767 27.1728 22.6875H27.1709ZM27.5 12H12.5V10.5C12.5 8.51088 13.2902 6.60322 14.6967 5.1967C16.1032 3.79018 18.0109 3 20 3C21.9891 3 23.8968 3.79018 25.3033 5.1967C26.7098 6.60322 27.5 8.51088 27.5 10.5V12Z" fill="url(#svg-grad)"/>
<defs>
        <linearGradient
          id="svg-grad"
          x1="0.584961"
          y1="15.5625"
          x2="15.885"
          y2="1.56197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0854" className="start-color" />
          <stop offset="0.922" className="end-color" />
        </linearGradient>
      </defs>
</svg>
  )
}

export default Cart
import React from "react";
import Minus from "../../static/Product/minus.svg";
import Plus from "../../static/Product/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useState, useRef } from "react";
import { getLocalData } from "../../utils/localStorage";
import { Link } from "react-router-dom";
import { updatePrescriptionOrders } from "../../services/OrderService";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/numberFormater";
function ProductListItem(props) {
  const { product, quantity, setOrder, order, orderProductID } = props;
  const { t } = useTranslation();
  const [pQuantity, setPQuantity] = useState(quantity);
  const [previousPQuantity, setPreviousPQuantity] = useState(quantity);
  var throttle = require("lodash.throttle");
  const throttled = useRef(
    throttle((pQuantity, id) => updateCart(pQuantity, id), 1000)
  );



  //currency
  const currency = useSelector((state) => state.pharmacy.currencySymbol);

  // useEffect(() => throttled.current(pQuantity), [pQuantity])

  const updateCart = (tempQtyy, tempID) => {
 
    let tempArr = [];

    order.orderItems.forEach((product)=>{

      let tempObj = {  }

      if( product.id === tempID){
        tempObj = {
          id: product.id,
          quantity: tempQtyy
        }
      }else{
        tempObj = {
          id: product.id,
          quantity: product.quantity
        }
      }
      

      tempArr.push(tempObj)
    })



    updatePrescriptionOrders(
     
      order.id,
      tempArr,
      getLocalData("user-info")?.userToken,

    ).then((response) => {
      setOrder({...response.orderResponse, updatedPresItems:true})
     
    
    })
    .catch((err) => {
      console.log(err);
    });

    
    
  };

  const UpdatePQuantity = (qty, id) => {
   
    if(qty>0){

      setPreviousPQuantity(pQuantity);
      setPQuantity(qty);
      throttled.current(qty, id);
    }
    
  };


  

  const removeFromCart = (oPd) => {
   
    let tempArr = [];

    order.orderItems.forEach((product)=>{

      

      if( product.id !== oPd){
        let tempObj = {  }
        tempObj = {
          id: product.id,
          quantity: product.quantity
        }

        tempArr.push(tempObj)
      }
      

      
    })

   

    updatePrescriptionOrders(
     
      order.id,
      tempArr,
      getLocalData("user-info")?.userToken,

    ).then((response) => {
      
      setOrder(response.orderResponse);
      //calculateOrderTotal()
    
    })
    .catch((err) => {
      console.log(err);
    });
    
  };
  return (
    <Row className="cart-details-item">
      <Col xs={3} md={3}>
        <Link to={`/productDetails/${product?.id}`}>
          <img
            className="cart-details-img"
            alt={product.name}
            src={product.pictureUrl}
          />
        </Link>
      </Col>
      <Col xs={9} md={9} className="pl-pd-mobile">
        <Row>
          <Col md={12}>
            <Link
              to={`/productDetails/${product.id}`}
              className="cart-details-name"
            >
              {product.name}
            </Link>
          </Col>
          <Col md={12} className="cart-details-by">
            {
              product.manufacturerName !== "" && (
                <>{t("odcpl-t-1")} <span className="cart-details-manu">{product.manufacturerName}</span></>
                
              )
            }
            
          </Col>
          <Col md={12} className="cart-details-qty">
           
            {product.drugTypeValue === 'OverTheCounter' ? '': (<span className="otc-product">Prescription Required</span>)}
          </Col>
          <Col md={12} className="cart-details-qty">
          {t("odcpl-t-2") }
          </Col>
          <Col md={9} xs={9} className="cart-details-qty">
            <div className="pd-cart-button-grp">
              <button
                className="btn btn-secondary"
                disabled={quantity === 1 ? "disabled" : ""}
                onClick={() => {
                  UpdatePQuantity(pQuantity - 1,  orderProductID);
                }}
              >
                <img src={Minus} alt="minus" />
              </button>
              <span>{pQuantity}</span>
              <button
                className="btn btn-secondary"
                disabled={quantity === 100 ? "disabled" : ""}
                onClick={() => {
                  UpdatePQuantity(pQuantity + 1, orderProductID);
                }}
              >
                <img src={Plus} alt="plus" />
              </button>
            </div>
            <div className="pl-sp-qty">
              {currency}
              {
                formatNumber(product.price)
                 
              }
            </div>
          </Col>
          <Col md={3} xs={3} className="cart-details-rfc">
            <button
              className="btn btn-danger"
              onClick={() => {
                removeFromCart(orderProductID);
              }}
            >
              <img src={Plus} alt="plus" />
            </button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ProductListItem;

import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedOutlet } from "../../redux/outletSlice";
import { hideModal } from "../../redux/searchModalSlice";
import { updateUser } from "../../redux/userSlice";
import { getAllOutlets, getNearByOutlets } from "../../services/OutletService";
import { UpdatePatientProfile } from "../../services/UserService";
import { getLocalData, setLocalData } from "../../utils/localStorage";
import CaretDown from "../SvgComponents/CaretDown";
import OutletHeader from "../SvgComponents/OutletHeader";
import InfoBoxOutlet from "./InfoBoxOutlet";

function FocusedOutlet() {
  let [showInfo1, setShowInfo1] = useState(true);

  let [isDetectLocEnabled, setIsDetectLocEnabled] = useState(true);

  let [outlets, setOutlets] = useState([
    
  ])

  const initArr = useSelector((state) => state.outlet.outArray);
  const userData = useSelector((state) => state.user.userData);


  //get all outlets
  useEffect(() => {
    setOutlets(initArr)
    
  }, [])
  

  const dispatch = useDispatch();

  useEffect(() => {
    if (!showInfo1) {
      dispatch(hideModal(1));
    }
  }, [showInfo1]);

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowInfo1(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);



  //get geolocation on click

  const getLatLng = () => { 
    navigator.geolocation.getCurrentPosition(function(position) {
       
        
        getNearByOutlets(0,position.coords.latitude , position.coords.longitude)
        .then((data) => {
          
          setOutlets(data)
          })
          .catch((err) => console.log(err));

      });
   }


   //select outlet

   const selectOutlet = (outletId, outName) => { 
    

    setLocalData("outlet-id", outletId);

      let selectOutObj = {
        outletName: outName,
        outletID: outletId,
        autoLoad: false
      }

      dispatch(updateSelectedOutlet(selectOutObj))

      setShowInfo1(false);

    if (JSON.stringify(userData) !== "{}") {
      if (outletId !== userData.outletId) {
        var tempUser = {
          ...userData,
          outletId: outletId,
        };
        var tempTOken = getLocalData("user-info")?.userToken;
        UpdatePatientProfile(tempUser, tempTOken)
          .then((data) => {
            if (data.status === "Success") {
              let tempObj = {
                userData: data.response.patientData,
                userToken: tempTOken,
              };
              setLocalData("user-info", tempObj);
              dispatch(updateUser(data.response.patientData));
             
            }
          })
          .catch((err) => console.log(err));
      }
    }

   
  }



  return (
    <div ref={ref} className="foc-wrapper">
      <div className="focused-input">
        <div className="INoutlet-selector">
          <div className="out-btn">
            <OutletHeader />
            <span>Select store</span>
          </div>
          <CaretDown />
        </div>
      </div>
      <InfoBoxOutlet show={showInfo1} selectOutlet={selectOutlet} outlets={outlets} getLatLng={getLatLng} isDetectLocEnabled={isDetectLocEnabled} message="Click outside to close this" />
    </div>
  );
}

export default FocusedOutlet;

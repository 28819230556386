import React from "react";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { authentication } from "../../utils/firebase";
import Google from "../../static/google.svg";
import { userLogin } from "../../services/UserService";
import { setLocalData } from "../../utils/localStorage";
import { updateUser } from "../../redux/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

function GoogleLogin() {


    const dispatch = useDispatch();

  const navigate = useNavigate();

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then((res) => {
        let tempUserObj = {
          name: res._tokenResponse.fullName,
          email: res._tokenResponse.email,
          oAuthAccessToken: res._tokenResponse.oauthIdToken,
          loginType: "Google",
          language: "en"
        };
     

        userLogin(tempUserObj)
          .then((res) => {
          

            if (res.status === "Success") {
              let userInfo = {
                userToken: res.response.token,
                userData: res.response.patientData,
              };
              navigate("/user");
              setLocalData("user-info", userInfo);
              dispatch(updateUser(userInfo.userData));
            } else if (res.status === "Fail") {
              swal({
                icon: "error",
                title: "Invalid Email or password",
              });
            }
          })
          .catch((err) => {
          
            swal({
              icon: "error",
              text: "there are some errors",
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <span className="login-btn" onClick={signInWithGoogle}>
      <img alt="google" src={Google} />
    </span>
  );
}

export default GoogleLogin;

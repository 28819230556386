import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import {
  forgotPassSendEmail,
  updateClientPassword,
  userLogin,
  validateEmail,
  verifyforgotPassSendEmail,
} from "../../services/UserService";
import { setLocalData } from "../../utils/localStorage";
import { updateUser } from "../../redux/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import OtpInput from "react-otp-input";




import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



function EmailLogin(props) {


  const {setNewEmail, setIsRegistration} = props

  const [email, setEmail] = useState("");
  const [isEmailVerifing, setIsEmailVerifing] = useState(false);
  const [isEmailValidated, setIsEmailValidated] = useState(false);

  //for step 2 after validating
  const [isStepTwoActivated, setIsStepTwoActivated] = useState(false);

  //storing Password
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const verifyEmail = () => {
    setIsEmailVerifing(true);
    validateEmail(email)
      .then((res) => {
        if (res.data.status === "Fail") {
          setIsEmailValidated(true);
        } else {
          setIsEmailValidated(false);
          setIsRegistration(true);
          setNewEmail(email)
        }

        setIsStepTwoActivated(true);

        setIsEmailVerifing(false);
      })
      .catch((err) => {
        console.log(err);
        setIsEmailVerifing(false);
        swal({
          icon: "error",
          text: "there are some errors",
        });
      });
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const login = () => {
    setIsLoggingIn(true);

    let tempUserObj = {
      email: email,
      password: password,
      loginType: "email",
    };
    userLogin(tempUserObj)
      .then((res) => {
        setIsLoggingIn(false);

        if (res.status === "Success") {
          let userInfo = {
            userToken: res.response.token,
            userData: res.response.patientData,
          };
          navigate("/user");
          setLocalData("user-info", userInfo);
          dispatch(updateUser(res.patientData));
        } else if (res.status === "Fail") {
          swal({
            icon: "error",
            title: "Invalid Email or password",
          });
        }
      })
      .catch((err) => {
        setIsLoggingIn(false);
        swal({
          icon: "error",
          text: "there are some errors",
        });
      });
  };

  const [isResetPassword, setIsResetPassword] = useState(false);
  const [otp, setOtp] = useState();
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);

  const resetPassword = () => {
    forgotPassSendEmail(email)
      .then((res) => {
        if (res.status === "Success") {
          setIsResetPassword(true);
        } else {
          swal({
            icon: "error",
            text: "there are some errors",
          });
        }
      })
      .catch((err) => {
        swal({
          icon: "error",
          text: "there are some errors",
        });
      });
  };

  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const verifyOtp = () => {
    setIsVerifyingOtp(true);
    verifyforgotPassSendEmail(otp, email)
      .then((res) => {
        
        if (res.data.response.isValid === true) {
          //setIsResetPassword(true)

          setIsOtpVerified(true);
        } else {
          swal({
            icon: "error",
            text: "there are some errors",
          });
        }

        setIsVerifyingOtp(false);
      })
      .catch((err) => {
        setIsVerifyingOtp(false);
        swal({
          icon: "error",
          text: "there are some errors",
        });
      });
  };

  const [changePassword, setChangePassword] = useState({
    newPassword: "",
    showNewPassword: false
  });
  const [isChangingPassword, setIsChangingPassword] = useState("");
  
  const [newPasswordValidator, setNewPasswordValidator] = useState({
    eightChars : false,
    specialChar : false,
    numChars : false,
    upperCaseChars : false,

  });


  const changePasswordVAlidator  = (e) =>{
    setChangePassword({...changePassword, newPassword: e.target.value})
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    var hasNumber = /\d/;  
    var hasUpperCase =  /[A-Z]/;  

let pass = e.target.value
    setNewPasswordValidator({
      eightChars : pass.length >= 7 ? true: false,
    specialChar : specialChars.test(pass),
    numChars : hasNumber.test(pass),
    upperCaseChars : hasUpperCase.test(pass),
    })
  }

  const createNewPassword = () => {
    setIsChangingPassword(true);
    if(newPasswordValidator.eightChars && newPasswordValidator.specialChar && newPasswordValidator.numChars && newPasswordValidator.upperCaseChars){
      updateClientPassword(changePassword.newPassword, email, otp)
      .then((res) => {
        if (res.data.status === "Success") {
          swal({
            icon: "success",
            text: "Password Updated successfully",
          });
          setIsStepTwoActivated(false);
          setIsEmailValidated(false);
          setIsResetPassword(false);
          setIsOtpVerified(false);
        } else {
          swal({
            icon: "error",
            text: "there are some errors",
          });
        }

        setIsChangingPassword(false);
      })
      .catch((err) => {
        setIsChangingPassword(false);
        swal({
          icon: "error",
          text: "there are some errors",
        });
      });
    }
   else{
    setIsChangingPassword(false);
        swal({
          icon: "error",
          text: "there are validation errors",
        });
   }
  };

  return (
    <div>
      {!isStepTwoActivated && (
        <div>
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="standard"
          />
          <Button
            disabled={isEmailVerifing ? "disabled" : ""}
            className="btn pd-btn pd-atc mt-3"
            onClick={() => verifyEmail()}
          >
            {isEmailVerifing && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            <span className="visually-hidden">Loading...</span>
            CONTINUE
          </Button>
        </div>
      )}

      {isStepTwoActivated && isEmailValidated && !isResetPassword && (
        <div>
          <TextField
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="standard"
          />
          <Button
            disabled={isLoggingIn ? "disabled" : ""}
            className="btn pd-btn pd-atc mt-3"
            onClick={() => login()}
          >
            {isLoggingIn && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            <span className="visually-hidden">Loading...</span>
            LOGIN
          </Button>
          <div>
            <span className="gethelp">
              Forgot your password?{" "}
              <span onClick={() => resetPassword()}>RESET HERE</span>{" "}
            </span>
          </div>
        </div>
      )}

      {isStepTwoActivated &&
        isEmailValidated &&
        isResetPassword &&
        !isOtpVerified && (
          <div className="otp-boxes">
            <OtpInput
              value={otp}
              onChange={(otp) => setOtp(otp)}
              numInputs={6}
            />
            <Button
              disabled={isVerifyingOtp ? "disabled" : ""}
              className="btn pd-btn pd-atc mt-3"
              onClick={() => verifyOtp()}
            >
              {isVerifyingOtp && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              <span className="visually-hidden">Loading...</span>
              VERIFY OTP
            </Button>
          </div>
        )}

      {isStepTwoActivated &&
        isEmailValidated &&
        isResetPassword &&
        isOtpVerified && (
          <div>
            
            <FormControl className="password-block" variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
              Create Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={changePassword.showNewPassword ? "text" : "password"}
                value={changePassword.newPassword}
                onChange={(e) => changePasswordVAlidator(e)  }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={()=>(setChangePassword({...changePassword, showNewPassword: !changePassword.showNewPassword}))}
                      onMouseDown={(e)=>(e.preventDefault())}
                    >
                      {changePassword.showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <div className="validator-grp">
                <span className={`from-cust-blocks ${newPasswordValidator.eightChars ? "active": ''}`}>8 Chanracters</span>
                <span className={`from-cust-blocks ${newPasswordValidator.specialChar ? "active": ''}`}>1 Special</span>
                <span className={`from-cust-blocks ${newPasswordValidator.numChars ? "active": ''}`}>1 Numeric</span>
                <span className={`from-cust-blocks ${newPasswordValidator.upperCaseChars ? "active": ''}`}>1 Uppercase</span>
            </div>
            <Button
              disabled={isChangingPassword ? "disabled" : ""}
              className="btn pd-btn pd-atc mt-3"
              onClick={() => createNewPassword()}
            >
              {isChangingPassword && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              <span className="visually-hidden">Loading...</span>
              CHANGE PASSWORD
            </Button>
          </div>
        )}
    </div>
  );
}

export default EmailLogin;

import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { getTopic } from "../services/Store";
import parse from "html-react-parser";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
function TermsConditions() {
  const [para, setPara] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getTopic("TermsAndConditions")
      .then((res) => {
        
        if(res.content){
          setPara(res.content);
        }
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  const { t } = useTranslation();

  return (
    <section className="space-at-top all-back">
      <div className="container">
        <Row className="pb-2">
          <Col md={12} className="d-flex flex-column">
            <span className="header-1">{t("fc-t-7")}</span>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="para-topic">
            {isLoading ? (
              <>
                <Skeleton variant="rectangular" width={400} height={40} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <br />
                <br />
                <Skeleton variant="rectangular" width={400} height={40} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <br />
                <br />
                <Skeleton variant="rectangular" width={400} height={40} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <br />
                <br />
                <Skeleton variant="rectangular" width={400} height={40} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <br />
                <br />
              </>
            ) : (
              <>{parse(para)}</>
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default TermsConditions;

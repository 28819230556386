import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getLocalData } from "../utils/localStorage";

function PrivateRoute({ children }) {
  const location = useLocation();
  const logSuccess = getLocalData("user-info")?.userToken?.length;
  console.log(logSuccess)
  return logSuccess > 0 ? children : <Navigate to="/login" state={{ from: location }}/>;
}
export default PrivateRoute;